import * as React from "react";
import {CSSTransition} from "react-transition-group";

export class SheetTransitionGroup extends React.Component<any, any> {
    render() {
        return (
            <CSSTransition
                classNames="sheetTransition"
                timeout={{enter: 300, exit: 400}}
                style={{"display": "flex", "flexDirection": "column", "flex": "1 1 0%", "minHeight": "0px"}}
            >
                <div>
                    {this.props.children}
                </div>
            </CSSTransition>
        );
    }
}
