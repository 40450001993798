import * as moment from "moment";
import * as React from "react";
import {
    Button,
    Form,
    Header,
    Icon,
    Message,
    Modal,
    Popup,
    Segment,
} from "semantic-ui-react";
import {
    ApiResult,
    JoinRequest,
    JoinRequestResponse,
    JoinRequestResponseStatusEnum,
    ResponseOption,
    ResponseOptionTypeEnum,
} from "@bryxinc/lunch/models";
import {nullIfBlank} from "@bryxinc/lunch/utils/functions";
import {SelectPermissions} from "./selectPermissions";
import {SelectResponseOptions} from "./selectResponseOptions";

import {RouteComponentProps} from "react-router";
import BryxApi from "@bryxinc/lunch/utils/ManagementApi";
import {
    withContext,
    WithTranslation,
    WithLocal,
    WithApi,
} from "@bryxinc/lunch/context";

export type RespondToJoinRequestViewStatus =
    | { key: "hidden" }
    | { key: "shown"; type: "approve" | "deny"; joinRequests: JoinRequest[] };

interface RespondToJoinRequestModalProps
    extends RouteComponentProps<any>,
        WithTranslation,
        WithLocal,
        WithApi<BryxApi> {
    agencyId: string;

    onCancel(): void;

    onComplete(): void;

    viewStatus: RespondToJoinRequestViewStatus;
}

export interface RespondOptionSelectItem {
    responseOption: ResponseOption;
    selected: boolean;
}

interface JoinRequestRespondItem {
    request: JoinRequest;
    status: JoinRequestResponse | null;
}

interface RespondToJoinRequestModalState {
    status:
        | { key: "ready" }
        | { key: "loading" }
        | { key: "error"; message: string };
    joinRequests: JoinRequestRespondItem[];
    denyReason: string | null;
    approveReason: string | null;
    canSend: boolean;
    canRespond: boolean;
    responseOptions: RespondOptionSelectItem[];
}

export class RespondToJoinRequestModal extends React.Component<RespondToJoinRequestModalProps,
    RespondToJoinRequestModalState> {
    constructor(props: RespondToJoinRequestModalProps, context: any) {
        super(props, context);

        this.state = RespondToJoinRequestModal.getInitial(props);
    }

    static getInitial(
        props: RespondToJoinRequestModalProps,
    ): RespondToJoinRequestModalState {
        return {
            status: {key: "ready"},
            joinRequests:
                props.viewStatus.key == "shown"
                    ? props.viewStatus.joinRequests
                        .map((r) => ({request: r, status: null}))
                        .sort((r1, r2) => JoinRequest.compare(r1.request, r2.request))
                    : [],
            denyReason: null,
            approveReason: null,
            canSend: false,
            canRespond: false,
            responseOptions: [],
        };
    }

    private loadResponseOptions() {
        this.setState({
            status: {key: "loading"},
        });

        this.props.api.getAgencyResponseOptions(
            this.props.agencyId,
            null,
            [ResponseOptionTypeEnum.positive, ResponseOptionTypeEnum.negative],
            (result) => {
                if (result.success == true) {
                    this.setState({
                        status: {key: "ready"},
                        responseOptions: SelectResponseOptions.getWrappedResponseOptions(
                            result.value,
                        ),
                    });
                } else {
                    this.props.local.logWarn(
                        `Failed to load agency's response options: ${result.debugMessage}`,
                    );
                    this.setState({
                        status: {key: "error", message: result.message},
                    });
                }
            },
        );
    }

    private selectResponseOption(option: RespondOptionSelectItem) {
        this.setState((prevState) => {
            const selectedOption = prevState.responseOptions.filter(
                (o) => o.responseOption.id == option.responseOption.id,
            )[0];

            if (selectedOption != undefined) {
                selectedOption.selected = !selectedOption.selected;
            }

            return prevState;
        });
    }

    private reset(props: RespondToJoinRequestModalProps) {
        this.setState(RespondToJoinRequestModal.getInitial(props));

        if (props.viewStatus.key == "shown" && props.viewStatus.type == "approve") {
            this.loadResponseOptions();
        }
    }

    componentWillReceiveProps(nextProps: RespondToJoinRequestModalProps) {
        if (
            this.props.viewStatus.key == "hidden" &&
            nextProps.viewStatus.key == "shown"
        ) {
            this.reset(nextProps);
        }
    }

    componentDidMount() {
        this.reset(this.props);
    }

    private onRespondToJoinRequest(result: ApiResult<JoinRequestResponse[]>) {
        if (result.success == true) {
            const requestErrors = result.value.filter(
                (r) => r.status == JoinRequestResponseStatusEnum.error,
            );
            if (requestErrors.length == 0) {
                this.props.onComplete();
            } else {
                // One or more of the requests failed. Don't close and show these errors in your list
                this.setState((prevState: RespondToJoinRequestModalState) => {
                    prevState.joinRequests.forEach((r) => {
                        const requestStatusList = result.value.filter(
                            (req) => req.id == r.request.id,
                        );

                        if (requestStatusList.length > 0) {
                            r.status = requestStatusList[0];
                        }
                    });

                    prevState.joinRequests
                        .filter(
                            (r) =>
                                r.status == null ||
                                r.status.status == JoinRequestResponseStatusEnum.error,
                        )
                        .sort((r1, r2) => JoinRequest.compare(r1.request, r2.request));

                    prevState.status = {key: "ready"};
                    return prevState;
                });
            }
        } else {
            this.props.local.logError(
                `Responding to join requests failed: ${result.debugMessage}`,
            );
            this.setState({
                status: {key: "error", message: result.message},
            });
        }
    }

    private respondToRequests() {
        const {viewStatus} = this.props;

        if (viewStatus.key == "shown") {
            this.setState({
                status: {key: "loading"},
            });

            if (viewStatus.type == "approve") {
                const {canSend, canRespond, approveReason, joinRequests} = this.state;

                this.props.api.approveJoinRequests(
                    this.props.agencyId,
                    joinRequests.map((r) => r.request),
                    approveReason,
                    canRespond,
                    canSend,
                    this.state.responseOptions
                        .filter((r) => r.selected == true)
                        .map((r) => r.responseOption.id),
                    this.onRespondToJoinRequest.bind(this),
                );
            } else {
                if (this.state.denyReason == null) {
                    this.props.local.logInfo(
                        "Aborting deny because there's no deny reason",
                    );
                    return;
                }

                const requestIds = this.state.joinRequests.map((r) => r.request.id);

                this.props.api.denyJoinRequests(
                    this.props.agencyId,
                    requestIds,
                    this.state.denyReason,
                    this.onRespondToJoinRequest.bind(this),
                );
            }
        }
    }

    render() {
        const {onCancel, viewStatus} = this.props;
        const {joinRequests} = this.state;

        return (
            <Modal open={viewStatus.key == "shown"} onClose={onCancel}>
                <Modal.Header>
                    <Header as="h1">
                        {this.props.t("members.join-requests.respondHeader")}
                    </Header>
                </Modal.Header>
                {viewStatus.key == "shown" ? (
                    <Modal.Content
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            padding: "30px",
                        }}
                    >
                        <div style={{display: "flex"}}>
                            <Form style={{flex: 2, paddingRight: "20px"}}>
                                <Form.Field style={{marginBottom: 0}}>
                                    <label>
                                        {this.props.t("members.join-requests.joinRequests")}
                                    </label>
                                </Form.Field>
                                <Segment.Group
                                    style={{
                                        marginTop: 0,
                                        overflowY: "auto",
                                        maxHeight: "410px",
                                    }}
                                >
                                    {joinRequests.map((r) => (
                                        <Segment
                                            key={r.request.id}
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "space-between",
                                            }}
                                        >
                                            <Header as="h4" style={{marginBottom: 0}}>
                                                {this.props.t(
                                                    "members.join-requests.joinRequestCards",
                                                    {
                                                        replace: {
                                                            commonName: r.request.requestingClient.commonName,
                                                            unitName: r.request.unit.name,
                                                        },
                                                    },
                                                )}
                                                <Header.Subheader>
                                                    {moment
                                                        .duration(
                                                            moment(r.request.creationTs).diff(
                                                                new Date().getTime(),
                                                            ),
                                                        )
                                                        .humanize(true)}
                                                </Header.Subheader>
                                            </Header>
                                            {r.status != null ? (
                                                <div style={{float: "right"}}>
                                                    {r.status.status ==
                                                    JoinRequestResponseStatusEnum.error ? (
                                                        <Popup
                                                            trigger={
                                                                <Icon
                                                                    name="warning sign"
                                                                    color="yellow"
                                                                    size="large"
                                                                />
                                                            }
                                                            position="right center"
                                                            content={r.status.reason}
                                                        />
                                                    ) : undefined}
                                                </div>
                                            ) : undefined}
                                        </Segment>
                                    ))}
                                </Segment.Group>
                            </Form>
                            {viewStatus.type == "approve" ? (
                                <Form style={{flex: 3, paddingLeft: "20px"}}>
                                    <SelectResponseOptions
                                        {...this.props}
                                        style={{marginBottom: 0}}
                                        responseOptions={this.state.responseOptions}
                                        onSelect={this.selectResponseOption.bind(this)}
                                    />
                                    <SelectPermissions
                                        {...this.props}
                                        style={{marginBottom: 0}}
                                        canSend={this.state.canSend}
                                        canRespond={this.state.canRespond}
                                        onPermissionClick={(t, v) => {
                                            if (t == "send") {
                                                this.setState({
                                                    canSend: v,
                                                });
                                            } else {
                                                this.setState({
                                                    canRespond: v,
                                                });
                                            }
                                        }}
                                    />
                                    <Form.TextArea
                                        label={this.props.t(
                                            "members.join-requests.approval.message",
                                        )}
                                        placeholder={this.props.t(
                                            "members.join-requests.approval.approveReasonPlaceholder",
                                        )}
                                        style={{minHeight: "100px"}}
                                        value={this.state.approveReason || ""}
                                        onChange={(e, d) =>
                                            this.setState({
                                                approveReason: nullIfBlank(d.value as string),
                                            })
                                        }
                                    />
                                </Form>
                            ) : (
                                <Form style={{flex: 3, paddingLeft: "20px"}}>
                                    <Form.TextArea
                                        label={this.props.t("members.join-requests.denial.message")}
                                        placeholder={this.props.t(
                                            "members.join-requests.denial.denyReasonPlaceholder",
                                        )}
                                        style={{minHeight: "150px"}}
                                        value={this.state.denyReason || ""}
                                        onChange={(e, d) =>
                                            this.setState({
                                                denyReason: nullIfBlank(d.value as string),
                                            })
                                        }
                                    />
                                </Form>
                            )}
                        </div>
                        {this.state.status.key == "error" ? (
                            <Message negative content={this.state.status.message}/>
                        ) : undefined}
                    </Modal.Content>
                ) : undefined}
                <Modal.Actions>
                    <Button
                        content={this.props.t("general.cancel")}
                        onClick={this.props.onCancel}
                    />
                    {viewStatus.key == "shown" && viewStatus.type == "approve" ? (
                        <Button
                            positive
                            disabled={
                                this.state.responseOptions.filter(
                                    (r) =>
                                        r.responseOption.type == ResponseOptionTypeEnum.positive &&
                                        r.selected == true,
                                ).length == 0 ||
                                this.state.responseOptions.filter(
                                    (r) =>
                                        r.responseOption.type == ResponseOptionTypeEnum.negative &&
                                        r.selected == true,
                                ).length == 0
                            }
                            loading={this.state.status.key == "loading"}
                            onClick={this.respondToRequests.bind(this)}
                            content={this.props.t("members.join-requests.approve")}
                        />
                    ) : (
                        <Button
                            negative
                            disabled={this.state.denyReason == null}
                            loading={this.state.status.key == "loading"}
                            onClick={this.respondToRequests.bind(this)}
                            content={this.props.t("members.join-requests.deny")}
                        />
                    )}
                </Modal.Actions>
            </Modal>
        );
    }
}

export default withContext(RespondToJoinRequestModal, "api", "local", "i18n");
