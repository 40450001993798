import * as React from "react";
import {Button, Message, Modal} from "semantic-ui-react";
import {Client, ClientGroup} from "@bryxinc/lunch/models";

import {RouteComponentProps} from "react-router";
import BryxApi from "@bryxinc/lunch/utils/ManagementApi";
import {
    withContext,
    WithTranslation,
    WithLocal,
    WithApi,
} from "@bryxinc/lunch/context";

export type ConfirmRemoveGroupModalViewStatus =
    | { key: "hidden" }
    | { key: "shown"; agencyId: string; client: Client; group: ClientGroup };

interface ConfirmRemoveGroupModalProps
    extends RouteComponentProps<any>,
        WithTranslation,
        WithLocal,
        WithApi<BryxApi> {
    viewStatus: ConfirmRemoveGroupModalViewStatus;
    onConfirmRemove: () => void;
    onClose: () => void;
}

interface ConfirmRemoveGroupModalState {
    status:
        | { key: "ready" }
        | { key: "loading" }
        | { key: "success" }
        | { key: "error"; message: string };
}

export class ConfirmRemoveGroupModal extends React.Component<ConfirmRemoveGroupModalProps,
    ConfirmRemoveGroupModalState> {
    constructor(props: ConfirmRemoveGroupModalProps, context: any) {
        super(props, context);
        this.state = ConfirmRemoveGroupModal.getInitialState();
    }

    private static getInitialState(): ConfirmRemoveGroupModalState {
        return {
            status: {key: "ready"},
        };
    }

    componentWillReceiveProps(nextProps: ConfirmRemoveGroupModalProps) {
        if (
            this.props.viewStatus.key == "hidden" &&
            nextProps.viewStatus.key == "shown"
        ) {
            this.setState(ConfirmRemoveGroupModal.getInitialState());
        }
    }

    private onSubmit() {
        const {status} = this.state;
        const {viewStatus} = this.props;
        if (status.key == "loading" || viewStatus.key != "shown") {
            return;
        }
        this.setState({status: {key: "loading"}});
        this.props.api.removeClientFromGroup(
            viewStatus.agencyId,
            viewStatus.group.id,
            viewStatus.client.id,
            (result) => {
                if (result.success == true) {
                    this.props.onConfirmRemove();
                } else {
                    this.props.local.logWarn(
                        `Failed to remove client from group: ${
                            result.debugMessage || result.message
                        }`,
                    );
                    this.setState({status: {key: "error", message: result.message}});
                }
            },
        );
    }

    private renderModalContent(): JSX.Element | null {
        const {viewStatus} = this.props;
        const {status} = this.state;
        if (viewStatus.key == "hidden") {
            return <Modal.Content/>;
        }

        return (
            <Modal.Content>
                {this.props.t("members.users.confirmRemoveGroupModal.body", {
                    replace: {
                        client: viewStatus.client.commonName,
                        group: viewStatus.group.name,
                    },
                })}
                {status.key == "error" ? (
                    <Message error content={status.message}/>
                ) : null}
            </Modal.Content>
        );
    }

    render() {
        const {viewStatus} = this.props;
        const {status} = this.state;
        return (
            <Modal open={viewStatus.key == "shown"} onClose={this.props.onClose}>
                <Modal.Header>
                    {this.props.t("members.users.confirmRemoveGroupModal.title")}
                </Modal.Header>
                {this.renderModalContent()}
                <Modal.Actions>
                    <Button
                        content={this.props.t("general.cancel")}
                        disabled={status.key == "loading"}
                        onClick={this.props.onClose}
                    />
                    <Button
                        negative
                        loading={status.key == "loading"}
                        content={this.props.t("general.remove")}
                        onClick={this.onSubmit.bind(this)}
                    />
                </Modal.Actions>
            </Modal>
        );
    }
}

export default withContext(ConfirmRemoveGroupModal, "api", "local", "i18n");
