import * as i18n from "i18next";
import * as React from "react";
import {Button, Message, Modal} from "semantic-ui-react";
import {AgencyGroup} from "@bryxinc/lunch/models";

import BryxApi from "@bryxinc/lunch/utils/ManagementApi";
import {RouteComponentProps} from "react-router";
import {
    withContext,
    WithTranslation,
    WithLocal,
    WithApi,
} from "@bryxinc/lunch/context";

export type DeleteGroupModalViewStatus =
    | { key: "hidden" }
    | { key: "shown"; group: AgencyGroup };

interface DeleteGroupModalProps
    extends RouteComponentProps<any>,
        WithTranslation,
        WithLocal,
        WithApi<BryxApi> {
    agencyId: string;

    onDismiss(reload: boolean): void;

    viewStatus: DeleteGroupModalViewStatus;
}

interface DeleteGroupModalState {
    actionStatus:
        | { key: "loading" }
        | { key: "ready" }
        | { key: "error"; message: string };
}

export class DeleteGroupModal extends React.Component<DeleteGroupModalProps,
    DeleteGroupModalState> {
    constructor(props: DeleteGroupModalProps, context: any) {
        super(props, context);

        this.state = DeleteGroupModal.getInitialState();
    }

    static getInitialState(): DeleteGroupModalState {
        return {
            actionStatus: {key: "ready"},
        };
    }

    componentWillReceiveProps(newProps: DeleteGroupModalProps) {
        if (
            this.props.viewStatus.key == "hidden" &&
            newProps.viewStatus.key == "shown"
        ) {
            // Opening modal
            this.setState(DeleteGroupModal.getInitialState());
        }
    }

    private deleteGroup() {
        const {viewStatus} = this.props;

        if (viewStatus.key == "shown") {
            this.setState({
                actionStatus: {key: "loading"},
            });

            this.props.api.deleteMessagingGroup(
                this.props.agencyId,
                viewStatus.group.id,
                (result) => {
                    if (result.success == true) {
                        this.setState(
                            {
                                actionStatus: {key: "ready"},
                            },
                            () => this.props.onDismiss(true),
                        );
                    } else {
                        this.props.local.logWarn(
                            `Failed to delete messaging group: ${result.message}`,
                        );
                        this.setState({
                            actionStatus: {key: "error", message: result.message},
                        });
                    }
                },
            );
        }
    }

    render() {
        const {onDismiss, viewStatus} = this.props;
        const {actionStatus} = this.state;

        return (
            <Modal open={viewStatus.key == "shown"} onClose={() => onDismiss(false)}>
                <Modal.Header>
                    {viewStatus.key == "shown"
                        ? this.props.t("groups.deleteGroup.header", {
                            replace: {groupName: viewStatus.group.name},
                        })
                        : null}
                </Modal.Header>
                <Modal.Content style={{backgroundColor: "#fbfbfb"}}>
                    <Message
                        warning
                        content={this.props.t("groups.deleteGroup.explain")}
                    />
                    {actionStatus.key == "error" ? (
                        <Message negative content={actionStatus.message}/>
                    ) : undefined}
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        content={this.props.t("general.cancel")}
                        disabled={actionStatus.key == "loading"}
                        onClick={() => onDismiss(false)}
                    />
                    <Button
                        negative
                        content={this.props.t("groups.deleteGroup.deleteGroup")}
                        loading={actionStatus.key == "loading"}
                        onClick={() => this.deleteGroup()}
                    />
                </Modal.Actions>
            </Modal>
        );
    }
}

export default withContext(DeleteGroupModal, "api", "local", "i18n");
