import * as React from "react";
import {
    Button,
    Dropdown,
    Header,
    Icon,
    Label,
    List,
    Loader,
    Table,
} from "semantic-ui-react";
import {CopyTimed} from "../../components/copyTimed";
import {Sheet} from "../../components/sheet";
import {ZeroStateView} from "../../components/zeroStateView";
import {
    AuthAgency,
    FullStationBoard,
    StationBoardSession,
} from "@bryxinc/lunch/models";
import {ConfirmDeleteBoardSessionModal} from "./confirmDeleteBoardSessionModal";
import {ConfirmDeleteStationBoardModal} from "./confirmDeleteStationBoardModal";
import {CreateEditStationBoardModal} from "./createEditStationBoardModal";

import {RouteComponentProps} from "react-router";
import BryxApi from "@bryxinc/lunch/utils/ManagementApi";
import {
    withContext,
    WithTranslation,
    WithLocal,
    WithApi,
} from "@bryxinc/lunch/context";

export type ViewStationBoardSheetViewStatus =
    | { key: "hidden" }
    | { key: "shown"; stationBoardId: string };

export interface ViewStationBoardSheetProps
    extends RouteComponentProps<any>,
        WithTranslation,
        WithLocal,
        WithApi<BryxApi> {
    selectedAgency: AuthAgency;
    viewStatus: ViewStationBoardSheetViewStatus;
    onUpdate: () => void;
    onDismiss: (requiresReload: boolean) => void;
}

export interface ViewStationBoardSheetState {
    status:
        | { key: "loading" }
        | { key: "ready"; board: FullStationBoard }
        | { key: "error"; message: string };
    overlay:
        | { key: "edit" }
        | { key: "delete" }
        | { key: "deleteSession"; session: StationBoardSession }
        | { key: "none" };
}

export class ViewStationBoardSheet extends React.Component<ViewStationBoardSheetProps,
    ViewStationBoardSheetState> {
    constructor(props: ViewStationBoardSheetProps, context: any) {
        super(props, context);

        this.state = ViewStationBoardSheet.getInitialState();
    }

    private static getInitialState(): ViewStationBoardSheetState {
        return {
            status: {key: "loading"},
            overlay: {key: "none"},
        };
    }

    componentDidMount() {
        const viewStatus = this.props.viewStatus;
        if (viewStatus.key == "shown") {
            this.loadStationBoard(viewStatus.stationBoardId);
        }
    }

    componentWillReceiveProps(newProps: ViewStationBoardSheetProps) {
        const {viewStatus} = this.props;
        const newViewStatus = newProps.viewStatus;

        const switching =
            viewStatus.key == "shown" &&
            newViewStatus.key == "shown" &&
            viewStatus.stationBoardId != newViewStatus.stationBoardId;
        const opening = viewStatus.key == "hidden" && newViewStatus.key == "shown";
        if (newViewStatus.key == "shown" && (switching || opening)) {
            this.setState(ViewStationBoardSheet.getInitialState(), () => {
                this.loadStationBoard(newViewStatus.stationBoardId);
            });
        }
    }

    private closeOverlay() {
        this.setState({overlay: {key: "none"}});
    }

    private loadStationBoard(boardId: string) {
        this.setState({status: {key: "loading"}});
        this.props.api.getStationBoard(
            this.props.selectedAgency.id,
            boardId,
            (result) => {
                if (result.success == true) {
                    this.setState({
                        status: {
                            key: "ready",
                            board: result.value,
                        },
                    });
                } else {
                    this.props.local.logWarn(
                        `Failed to get station board: ${
                            result.debugMessage || result.message
                        }`,
                    );
                    this.setState({
                        status: {
                            key: "error",
                            message: result.message,
                        },
                    });
                }
            },
        );
    }

    render() {
        let content;
        if (this.state.status.key == "loading") {
            content = <Loader active/>;
        } else if (this.state.status.key == "error") {
            content = (
                <div
                    className="flexCenteredContainer"
                    style={{width: "100%", height: "100%"}}
                >
                    <ZeroStateView
                        header={this.props.t("stationBoards.view.couldNotLoad")}
                        subheader={this.state.status.message}
                        icon="warning"
                    />
                </div>
            );
        } else {
            const board = this.state.status.board;
            const boardUrl = `${this.props.api.stationBoardUrl}/login?t=${board.id}`;

            content = (
                <div
                    style={{
                        padding: "30px 30px 15px 30px",
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "flex-start",
                        }}
                    >
                        <Header as="h2" style={{flex: 1, marginBottom: "5px"}}>
                            {board.name}
                        </Header>
                        <Dropdown
                            pointing="right"
                            icon={{name: "ellipsis vertical", size: "large"}}
                        >
                            <Dropdown.Menu>
                                <Dropdown.Item
                                    icon="pencil"
                                    text={this.props.t("stationBoards.view.editBoard")}
                                    onClick={() => this.setState({overlay: {key: "edit"}})}
                                />
                                <Dropdown.Divider/>
                                <Dropdown.Item
                                    icon="x"
                                    text={this.props.t("stationBoards.view.removeBoard")}
                                    onClick={() => this.setState({overlay: {key: "delete"}})}
                                />
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                    <div style={{marginBottom: "15px"}}>
                        <Label
                            icon="location arrow"
                            content={board.station.name}
                            style={{cursor: "pointer"}}
                            onClick={() => this.setState({overlay: {key: "edit"}})}
                        />
                        <Label
                            icon="map pin"
                            content={this.props.t("stationBoards.view.unitsCount", {
                                count: board.units.length,
                            })}
                            style={{cursor: "pointer"}}
                            onClick={() => this.setState({overlay: {key: "edit"}})}
                        />
                        {board.messagingGroup != null ? (
                            <Label
                                icon="mail"
                                content={board.messagingGroup.name}
                                style={{cursor: "pointer"}}
                                onClick={() => this.setState({overlay: {key: "edit"}})}
                            />
                        ) : null}
                    </div>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                        }}
                    >
                        <Button
                            positive
                            icon="external"
                            content={this.props.t("stationBoards.view.openInNewTab")}
                            target="_blank"
                            href={boardUrl}
                            style={{flex: 1}}
                        />
                        <CopyTimed
                            copyText={boardUrl}
                            renderContent={(showCopied) => (
                                <Button
                                    primary
                                    icon={!showCopied ? "linkify" : "check"}
                                    content={this.props.t("stationBoards.view.copyLink")}
                                    style={{flex: 1}}
                                />
                            )}
                        />
                    </div>
                    {board.sessions.length > 0 ? (
                        <Table striped>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell width={8}>
                                        {this.props.t("stationBoards.view.sessionName")}
                                    </Table.HeaderCell>
                                    <Table.HeaderCell width={7}>
                                        {this.props.t("stationBoards.view.sessionLastConnected")}
                                    </Table.HeaderCell>
                                    <Table.HeaderCell width={1}/>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {board.sessions.map((s) => (
                                    <Table.Row key={s.id}>
                                        <Table.Cell>{s.name}</Table.Cell>
                                        <Table.Cell>
                                            {s.lastConnected != null
                                                ? s.lastConnected.toLocaleString()
                                                : this.props.t(
                                                    "stationBoards.view.sessionNeverConnected",
                                                )}
                                        </Table.Cell>
                                        <Table.Cell>
                                            <Icon
                                                link
                                                name="trash"
                                                color="red"
                                                size="large"
                                                onClick={() =>
                                                    this.setState({
                                                        overlay: {
                                                            key: "deleteSession",
                                                            session: s,
                                                        },
                                                    })
                                                }
                                            />
                                        </Table.Cell>
                                    </Table.Row>
                                ))}
                            </Table.Body>
                        </Table>
                    ) : (
                        <div
                            style={{
                                flex: 1,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                        >
                            <ZeroStateView
                                icon="computer"
                                header={this.props.t("stationBoards.view.noSessionsHeader")}
                                subheader={
                                    <List>
                                        <List.Item>
                                            {this.props.t("stationBoards.view.noSessionsBody")}
                                        </List.Item>
                                        <List.Item>
                                            <a
                                                style={{cursor: "pointer"}}
                                                onClick={() => this.loadStationBoard(board.id)}
                                            >
                                                {this.props.t("stationBoards.view.noSessionsRefresh")}
                                            </a>
                                        </List.Item>
                                    </List>
                                }
                            />
                        </div>
                    )}
                    <CreateEditStationBoardModal
                        {...this.props}
                        viewStatus={
                            this.state.overlay.key == "edit"
                                ? {key: "edit", board: board}
                                : {key: "hidden"}
                        }
                        selectedAgency={this.props.selectedAgency}
                        onClose={() => this.closeOverlay()}
                        onComplete={() => {
                            this.closeOverlay();
                            this.loadStationBoard(board.id);
                            this.props.onUpdate();
                        }}
                    />
                    <ConfirmDeleteStationBoardModal
                        {...this.props}
                        viewStatus={
                            this.state.overlay.key == "delete"
                                ? {
                                    key: "shown",
                                    agencyId: this.props.selectedAgency.id,
                                    board: board,
                                }
                                : {key: "hidden"}
                        }
                        onConfirmRemove={() => this.props.onDismiss(true)}
                        onClose={() => this.closeOverlay()}
                    />
                    <ConfirmDeleteBoardSessionModal
                        {...this.props}
                        viewStatus={
                            this.state.overlay.key == "deleteSession"
                                ? {
                                    key: "shown",
                                    agencyId: this.props.selectedAgency.id,
                                    boardId: board.id,
                                    session: this.state.overlay.session,
                                }
                                : {key: "hidden"}
                        }
                        onConfirmRemove={() => {
                            this.closeOverlay();
                            this.loadStationBoard(board.id);
                            this.props.onUpdate();
                        }}
                        onClose={() => this.closeOverlay()}
                    />
                </div>
            );
        }

        return (
            <Sheet
                open={this.props.viewStatus.key == "shown"}
                onDismiss={() => this.props.onDismiss(false)}
            >
                {content}
            </Sheet>
        );
    }
}

export default withContext(ViewStationBoardSheet, "api", "local", "i18n");
