import * as React from "react";
import {RouteComponentProps} from "react-router";
import {Button, Table} from "semantic-ui-react";
import {
    HeaderDataItem,
    PaginatedTable,
} from "../../components/paginatedTable";
import {
    Apparatus,
    ApparatusSortingOptions,
    ApparatusTypeEnum,
    AuthAgency,
    PageResult,
    SortingConfig,
    ApiResult,
} from "@bryxinc/lunch/models";

import {CreateEditApparatusModal} from "./createEditApparatusModal";
import {
    ViewApparatusSheet,
    ViewApparatusSheetViewStatus,
} from "./viewApparatusSheet";

import BryxApi from "@bryxinc/lunch/utils/ManagementApi";
import {
    withContext,
    WithTranslation,
    WithLocal,
    WithApi,
} from "@bryxinc/lunch/context";

interface ApparatusTabProps
    extends RouteComponentProps<{ apparatusId: string }>,
        WithTranslation,
        WithLocal,
        WithApi<BryxApi> {
    selectedAgency: AuthAgency;
}

export class ApparatusTab extends React.Component<ApparatusTabProps, {}> {
    private paginatedTable: PaginatedTable | null = null;

    constructor(props: ApparatusTabProps, context: any) {
        super(props, context);
    }

    private loadItems(
        limit: number,
        activePage: number,
        searchString: string | null,
        sortConfig: SortingConfig<ApparatusSortingOptions>,
        callback: (result: ApiResult<PageResult<Apparatus>>) => void,
    ) {
        this.props.api.getApparatus(
            this.props.selectedAgency.id,
            limit,
            activePage,
            searchString,
            sortConfig,
            callback,
        );
    }

    private onClickAdd() {
        this.props.history.push("/members/apparatus/create");
    }

    private onClickApparatus(apparatusId: string) {
        this.props.history.push(`/members/apparatus/${apparatusId}`);
    }

    render() {
        let viewApparatusViewStatus: ViewApparatusSheetViewStatus;
        let createApparatusOpen = false;
        if (this.props.match.params.apparatusId == "create") {
            viewApparatusViewStatus = {key: "hidden"};
            createApparatusOpen = true;
        } else if (this.props.match.params.apparatusId != null) {
            viewApparatusViewStatus = {
                key: "shown",
                apparatusId: this.props.match.params.apparatusId,
            };
        } else {
            viewApparatusViewStatus = {key: "hidden"};
        }
        return (
            <div className="underHorizNavContent" style={{display: "flex"}}>
                <PaginatedTable
                    {...this.props}
                    ref={(r) => (this.paginatedTable = r)}
                    className="underHorizNavContent tableUnderNavContent"
                    uniqueKey="members.apparatus"
                    rightItem={
                        <Button
                            color="green"
                            type="button"
                            onClick={this.onClickAdd.bind(this)}
                            content={this.props.t("members.apparatus.add")}
                        />
                    }
                    loader={{
                        loadId: this.props.selectedAgency.id,
                        loadItems: this.loadItems.bind(this),
                    }}
                    headerDataItems={[
                        "name",
                        "email",
                        "apparatusId",
                        "apparatusType",
                    ].map((s) => {
                        return {
                            i18nKey: `members.apparatus.${s}`,
                            headerKey: s,
                            width: 4,
                        } as HeaderDataItem;
                    })}
                    renderItem={(item: any) => {
                        const apparatus = item as Apparatus;
                        return (
                            <Table.Row
                                key={apparatus.id}
                                style={{cursor: "pointer"}}
                                onClick={() => this.onClickApparatus(apparatus.id)}
                            >
                                <Table.Cell>{apparatus.name}</Table.Cell>
                                <Table.Cell>{apparatus.email}</Table.Cell>
                                <Table.Cell>{apparatus.apparatusId}</Table.Cell>
                                <Table.Cell>
                                    {this.props.t(
                                        `members.apparatus.typeNames.${
                                            ApparatusTypeEnum[apparatus.apparatusType]
                                        }`,
                                    )}
                                </Table.Cell>
                            </Table.Row>
                        );
                    }}
                    defaultSorting={{
                        column: "name",
                        direction: "desc",
                    }}
                />
                <ViewApparatusSheet
                    {...this.props}
                    selectedAgency={this.props.selectedAgency}
                    viewStatus={viewApparatusViewStatus}
                    onUpdateApparatus={() => {
                        if (this.paginatedTable != null) {
                            this.paginatedTable.reload();
                        }
                    }}
                    onDismiss={(requiresReload) => {
                        this.props.history.replace("/members/apparatus");
                        if (requiresReload && this.paginatedTable != null) {
                            this.paginatedTable.reload();
                        }
                    }}
                />
                <CreateEditApparatusModal
                    {...this.props}
                    viewStatus={
                        createApparatusOpen ? {key: "create"} : {key: "hidden"}
                    }
                    selectedAgency={this.props.selectedAgency}
                    onClose={() => this.props.history.replace("/members/apparatus")}
                    onComplete={(apparatus) => {
                        if (this.paginatedTable != null) {
                            this.paginatedTable.reload();
                        }
                        this.onClickApparatus(apparatus.id);
                    }}
                />
            </div>
        );
    }
}

export default withContext(ApparatusTab, "api", "local", "i18n");
