/**
 * @module i18n
 * Creates, configures, and exports i18next instance.
 */

import i18n from "i18next";
import {InitOptions as Options} from "i18next";
import XHR from "i18next-xhr-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import {initReactI18next} from "react-i18next";
import * as moment from "moment";

i18n
    .use(XHR)
    .use(LanguageDetector)
    .use(initReactI18next) // bind react-i18next to the instance
    .use({
        name: "locale",
        type: "postProcessor",
        process: (value: any, key: any, options: any) => {
            if (!value.includes("$l")) {
                return value;
            }
            const values = value
                .match(/\$l\(([^\)]*)\)/g)
                .map((v: string) => v.match(/\$l\(([^\)]*)\)/));
            for (const v of values) {
                if (v && v.length > 0) {
                    const match = v[0];
                    let val = v[1]
                        .split(",")
                        .find(
                            (e: string, i: number) => i != 0 && e && !e.includes(".name"),
                        );
                    if (!val) {
                        val = v[1];
                    }
                    val = val.trim();
                    if (val.length < 4) {
                        val = val.toUpperCase();
                    } else {
                        val = val
                            .toLowerCase()
                            .split(" ")
                            .map((s: string) => s[0].toUpperCase() + s.slice(1))
                            .join(" ");
                    }
                    value = value.replace(match, val);
                }
            }
            return value;
        },
        overloadTranslationOptionHandler: (args: any) => {
            console.log("overloadTranslationOptionHandler", args);
            return {defaultValue: args[1]};
        },
    })
    .init({
        lng: "en", // 'en-US', // TODO: Check user for language
        ns: ["default", "country"],
        defaultNS: "default",
        backend: {
            loadPath: "/resources/locales/{{lng}}/{{ns}}.json",
        },
        fallbackLng: "en",
        postProcess: ["locale"],
        interpolation: {
            escapeValue: false,
            format: (value: any, format: string, lng: string) => {
                if (!value) {
                    return value;
                }
                if (value instanceof Date) {
                    return moment(value).format(format);
                }
                if (Array.isArray(value)) {
                    return value.join(", ");
                }
                if (typeof value === "object") {
                    if (value.name) {
                        value = value.name;
                    } else {
                        value = JSON.stringify(value);
                    }
                }
                if (format === "lowercase") {
                    return value.toLowerCase();
                }
                if (format === "uppercase") {
                    return value.toUpperCase();
                }
                if (format === "titlecase") {
                    return value
                        .toLowerCase()
                        .split(" ")
                        .map((s: string) => s[0].toUpperCase() + s.slice(1))
                        .join(" ");
                }
                return value;
            },
        },
        debug: true,
        react: {
            useSuspense: false,
        },
        returnEmptyString: false,
    } as Options);

export default i18n;
