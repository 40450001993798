import * as React from 'react';
import {Button, Message, Search} from "semantic-ui-react";
import {AuthAgency} from '@bryxinc/lunch/models';
import {FindUser} from '@bryxinc/lunch/models';
import BryxApi from '@bryxinc/lunch/utils/ManagementApi';
import {Debouncer, nullIfBlank} from '@bryxinc/lunch/utils/functions';

import {withContext, WithTranslation, WithLocal, WithApi} from '@bryxinc/lunch/context';
import {RouteComponentProps} from "react-router";

interface FindMemberProps extends RouteComponentProps, WithTranslation, WithLocal, WithApi<BryxApi> {
    selectedAgency: AuthAgency;
    onSelect: (user: FindUser | null) => void;
}

interface FindMemberState {
    searchQuery: string | null;
    searchStatus: { key: "empty" } | { key: "loading" } | { key: "ready", results: FindUser[] } | { key: "error", message: string };
    selectedUser: FindUser | null;
}

export class FindMemberInput extends React.Component<FindMemberProps, FindMemberState> {
    private searchDebouncer = new Debouncer(this.loadUsers.bind(this));
    private static readonly searchMinCharacters = 3;

    constructor(props: FindMemberProps, context: any) {
        super(props, context);
        this.state = this.getDefaultState();
    }

    private getDefaultState(): FindMemberState {
        return {
            searchQuery: null,
            searchStatus: {key: "empty"},
            selectedUser: null,
        };
    }

    private loadUsers() {
        const {searchQuery} = this.state;
        if (searchQuery == null || searchQuery.length < FindMemberInput.searchMinCharacters) {
            this.setState({searchStatus: {key: "empty"}});
            return;
        }
        this.props.api.getAgencyUsers(this.props.selectedAgency.id, 10, 0, searchQuery, {
            column: "commonName",
            direction: "asc"
        }, result => {
            if (result.success == true) {
                this.setState({
                    searchStatus: {
                        key: "ready",
                        results: result.value.items,
                    },
                });
            } else {
                this.props.local.logWarn(`Failed to search for users: ${result.debugMessage || result.message}`);
                this.setState({
                    searchStatus: {key: "error", message: result.message},
                });
            }
        });
    }

    private searchUpdate(value: string | null) {
        this.setState({
            searchQuery: value,
            searchStatus: {key: "loading"},
            selectedUser: null,
        }, () => {
            this.props.onSelect(null);
            this.searchDebouncer.postUpdate();
        });
    }

    private onSearchResultSelect(userId: string) {
        if (this.state.searchStatus.key == "ready") {
            this.selectUser(this.state.searchStatus.results.filter(r => r.id == userId)[0]);
        }
    }

    private selectUser(user: FindUser | null) {
        if (user != null) {
            this.setState({
                selectedUser: user,
                searchQuery: user.commonName,
            });
        }
        this.props.onSelect(user);
    }

    render() {
        const {searchQuery, searchStatus} = this.state;
        const searchResults = searchStatus.key == "ready" ? (
            searchStatus.results.map(r => ({
                key: r.id,
                title: r.commonName,
                description: r.email,
            }))
        ) : [];
        return (
            <div>
                <Search
                    autoFocus
                    placeholder={this.props.t("findMember.searchPlaceholder")}
                    showNoResults={searchStatus.key == "ready"}
                    loading={searchStatus.key == "loading"}
                    results={searchResults}
                    value={searchQuery || ""}
                    onSearchChange={(e, d) => this.searchUpdate(nullIfBlank(d.value))}
                    onResultSelect={(e, d) => this.onSearchResultSelect(d.result.key)}
                    style={{display: "inline", marginRight: "10px"}}/>
                <Button
                    primary
                    content={this.props.t("findMember.selectMe")}
                    onClick={() => {
                        const selfUser = this.props.local.selfUser;
                        if (selfUser != null) {
                            this.selectUser(selfUser);
                        }
                    }}/>
                {searchStatus.key == "error" ? <Message negative content={searchStatus.message}/> : null}
            </div>
        );
    }
}

export default withContext(FindMemberInput, 'api', 'local', 'i18n');
