import {Point} from "geojson";
import * as React from "react";
import * as L from "leaflet";
import {Button, Dimmer, Form, Grid, Loader, Message} from "semantic-ui-react";
import {BryxGeoJSONLayer} from "../../components/bryxGeoJSONLayer";
import {BryxMap} from "../../components/bryxMap";
import {
    AgencyRegion,
    Geocode,
    BasicAgencyGroup,
    AgencyGroupTypeEnum,
    JobTypeEnum,
} from "@bryxinc/lunch/models";

import {
    enumNames,
    nullIfBlank,
    NumberStatus,
    parseNumberStatus,
    Debouncer,
} from "@bryxinc/lunch/utils/functions";
import {JobsProps} from "./jobsPage";
import {withContext} from "@bryxinc/lunch/context";

interface CreateJobState {
    synopsis: string | null;
    jobType: JobTypeEnum;
    address: string | null;
    city: string | null;
    state: string | null;
    latitudeString: string | null;
    longitudeString: string | null;
    dispatchGroupsStatus:
        | { key: "loading" }
        | { key: "ready"; groups: BasicAgencyGroup[] }
        | { key: "error"; message: string };
    selectedDispatchGroups: BasicAgencyGroup[];
    typeDesc: string | null;
    incidentId: string | null;
    actionStatus:
        | { key: "ready" }
        | { key: "loading" }
        | { key: "success" }
        | { key: "error"; message: string };

    // The user is allowed to cancel an active geocode request.
    // Rather than abort the network call, we assign the loading state a `loadId` which uniquely identifies the pending request.
    // When a network call completes, it is only allowed to update state if the status is still loading *and* the current `loadId`
    // matches the `loadId` used when the call was placed originally. If the `loadId`s do not match, that means that the
    // user placed the first request, cancelled it, then placed a second request before the first network call completed.
    // A new `loadId` is generated for each call to `onGeocode`, so if the user changes any fields while a network request is running,
    // it will effective "cancel" any other running requests.
    // If the lat/long has already been filled (either by another geocode request or by the user themselves), no geocode request will be placed.
    geocodeStatus:
        | { key: "ready" }
        | { key: "loading"; loadId: string }
        | { key: "success"; geocodeLocation: Geocode }
        | { key: "error"; message: string };
    agencyRegionStatus:
        | { key: "loading" }
        | { key: "success"; region: AgencyRegion }
        | { key: "error"; message: string };
}

type LocationStatus =
    | { key: "incomplete" }
    | { key: "ok"; latitude: number; longitude: number };

type SubmissionStatus =
    | { key: "incomplete" }
    | {
    key: "ok";
    synopsis: string;
    jobType: JobTypeEnum;
    address: string;
    city: string;
    state: string;
    latitude: number | null;
    longitude: number | null;
    dispatchGroups: BasicAgencyGroup[];
    typeDesc: string | null;
    incidentId: string | null;
};

export class CreateJobTab extends React.Component<JobsProps, CreateJobState> {
    private static readonly geocodeDebouceTime = 3 * 1000;
    private geocodeDebouncer = new Debouncer(
        () => this.onGeocode(),
        CreateJobTab.geocodeDebouceTime,
    );

    constructor(props: JobsProps, context: any) {
        super(props, context);

        this.state = this.getInitialState();
    }

    private getDefaultType(): JobTypeEnum {
        const savedJobType = this.props.local.createJobType;
        return savedJobType != null ? savedJobType : JobTypeEnum.fire;
    }

    private getInitialState(): CreateJobState {
        return {
            synopsis: null,
            jobType: this.getDefaultType(),
            address: null,
            city: null,
            state: null,
            latitudeString: null,
            longitudeString: null,
            typeDesc: null,
            incidentId: null,
            dispatchGroupsStatus: {key: "loading"},
            selectedDispatchGroups: [],
            actionStatus: {key: "ready"},
            geocodeStatus: {key: "ready"},
            agencyRegionStatus: {key: "loading"},
        };
    }

    componentDidMount() {
        this.loadDispatchGroups();
        this.loadAgencyRegion();
    }

    componentWillReceiveProps(nextProps: JobsProps) {
        if (this.props.selectedAgency.id != nextProps.selectedAgency.id) {
            this.setState(this.getInitialState(), () => {
                this.loadDispatchGroups();
                this.loadAgencyRegion();
            });
        }
    }

    private static getDefaultDispatchGroups(
        allGroups: BasicAgencyGroup[],
    ): BasicAgencyGroup[] {
        return allGroups.length == 1 ? allGroups : [];
    }

    private loadDispatchGroups() {
        this.setState({dispatchGroupsStatus: {key: "loading"}});
        this.props.api.getAllGroupsByType(
            this.props.selectedAgency.id,
            AgencyGroupTypeEnum.dispatch,
            (result) => {
                if (result.success == true) {
                    this.setState({
                        dispatchGroupsStatus: {key: "ready", groups: result.value},
                        selectedDispatchGroups: CreateJobTab.getDefaultDispatchGroups(
                            result.value,
                        ),
                    });
                } else {
                    this.props.local.logWarn(
                        `Error getting all dispatch groups for agency: ${this.props.selectedAgency.id}: ${result.debugMessage}`,
                    );
                    this.setState({
                        dispatchGroupsStatus: {
                            key: "error",
                            message: result.message,
                        },
                    });
                }
            },
        );
    }

    private loadAgencyRegion() {
        this.setState({agencyRegionStatus: {key: "loading"}});
        this.props.api.getAgencyRegion(this.props.selectedAgency.id, (result) => {
            if (result.success == true) {
                this.setState((prevState: CreateJobState) => {
                    prevState.agencyRegionStatus = {
                        key: "success",
                        region: result.value,
                    };
                    prevState.city = prevState.city || result.value.city;
                    prevState.state = prevState.state || result.value.state;
                    return prevState;
                });
            } else {
                // State is set but unused, user will have to enter the city and state manually regardless.
                this.props.local.logWarn(
                    `Error getting agency region: ${this.props.selectedAgency.id}: ${result.debugMessage}`,
                );
                this.setState({
                    agencyRegionStatus: {key: "error", message: result.message},
                });
            }
        });
    }

    private getLatitudeStatus(): NumberStatus {
        return parseNumberStatus(this.state.latitudeString);
    }

    private getLongitudeStatus(): NumberStatus {
        return parseNumberStatus(this.state.longitudeString);
    }

    private getLocationStatus(): LocationStatus {
        const latitudeStatus = this.getLatitudeStatus();
        const longitudeStatus = this.getLongitudeStatus();
        return latitudeStatus.key == "ok" && longitudeStatus.key == "ok"
            ? {
                key: "ok",
                latitude: latitudeStatus.value,
                longitude: longitudeStatus.value,
            }
            : {key: "incomplete"};
    }

    private getSubmissionStatus(): SubmissionStatus {
        const {
            synopsis,
            jobType,
            address,
            city,
            state,
            geocodeStatus,
            selectedDispatchGroups,
            typeDesc,
            incidentId,
        } = this.state;
        const latitudeStatus = this.getLatitudeStatus();
        const longitudeStatus = this.getLongitudeStatus();
        if (
            synopsis == null ||
            address == null ||
            city == null ||
            state == null ||
            selectedDispatchGroups.length == 0 ||
            latitudeStatus.key == "error" ||
            longitudeStatus.key == "error" ||
            geocodeStatus.key == "loading"
        ) {
            return {key: "incomplete"};
        }
        return {
            key: "ok",
            synopsis: synopsis,
            jobType: jobType,
            address: address,
            city: city,
            state: state,
            latitude: latitudeStatus.key == "ok" ? latitudeStatus.value : null,
            longitude: longitudeStatus.key == "ok" ? longitudeStatus.value : null,
            dispatchGroups: selectedDispatchGroups,
            typeDesc: typeDesc,
            incidentId: incidentId,
        };
    }

    private onClickCreate() {
        const submissionStatus = this.getSubmissionStatus();
        if (submissionStatus.key != "ok") {
            return;
        }
        this.setState({actionStatus: {key: "loading"}});
        this.props.api.createJob(
            submissionStatus.synopsis,
            submissionStatus.jobType,
            submissionStatus.address,
            submissionStatus.city,
            submissionStatus.state,
            submissionStatus.latitude,
            submissionStatus.longitude,
            submissionStatus.dispatchGroups.map((g) => g.id),
            submissionStatus.typeDesc,
            submissionStatus.incidentId,
            (result) => {
                if (result.success == true) {
                    this.setState(
                        (prevState) => ({
                            actionStatus: {key: "success"},
                            synopsis: null,
                            jobType: this.getDefaultType(),
                            address: null,
                            city: null,
                            state: null,
                            latitudeString: null,
                            longitudeString: null,
                            selectedDispatchGroups:
                                prevState.dispatchGroupsStatus.key == "ready"
                                    ? CreateJobTab.getDefaultDispatchGroups(
                                    prevState.dispatchGroupsStatus.groups,
                                    )
                                    : [],
                        }),
                        () => this.loadAgencyRegion(),
                    );
                } else {
                    this.props.local.logWarn(
                        `Error creating job: ${this.props.selectedAgency.id}: ${result.debugMessage}`,
                    );
                    this.setState({
                        actionStatus: {
                            key: "error",
                            message: result.message,
                        },
                    });
                }
            },
        );
    }

    private postGeocodeUpdate() {
        if (this.getLocationStatus().key == "ok") {
            return;
        }
        this.setState(
            {
                geocodeStatus: {
                    key: "loading",
                    loadId: new Date().getTime().toString(),
                },
            },
            () => this.geocodeDebouncer.postUpdate(),
        );
    }

    private onGeocode() {
        const {selectedAgency} = this.props;
        const {address, city, state, geocodeStatus} = this.state;
        if (
            address == null ||
            city == null ||
            state == null ||
            geocodeStatus.key != "loading"
        ) {
            return;
        }
        const loadId = geocodeStatus.loadId;
        this.props.api.geocode(
            selectedAgency.id,
            address.replace(/^\s+|\s+$/g, ""),
            city.replace(/^\s+|\s+$/g, ""),
            state.replace(/^\s+|\s+$/g, ""),
            (result) => {
                this.setState((prevState: CreateJobState) => {
                    if (
                        prevState.geocodeStatus.key != "loading" ||
                        prevState.geocodeStatus.loadId != loadId
                    ) {
                        // Don't set any values if the user aborted the request
                        return prevState;
                    }
                    if (result.success == true) {
                        prevState.geocodeStatus = {
                            key: "success",
                            geocodeLocation: result.value,
                        };
                        prevState.latitudeString =
                            result.value.centroid.coordinates[1].toString();
                        prevState.longitudeString =
                            result.value.centroid.coordinates[0].toString();
                    } else {
                        // State is set but unused, user can choose to enter a different address, type or select the coordinates, or submit without a location
                        this.props.local.logWarn(
                            `Failed to geocode location: ${
                                result.debugMessage || result.message
                            }`,
                        );
                        prevState.geocodeStatus = {key: "error", message: result.message};
                    }
                    return prevState;
                });
            },
        );
    }

    render() {
        const {
            agencyRegionStatus,
            dispatchGroupsStatus,
            geocodeStatus,
            synopsis,
            jobType,
            typeDesc,
            incidentId,
            address,
            city,
            state,
            latitudeString,
            longitudeString,
            selectedDispatchGroups,
            actionStatus,
        } = this.state;
        const dispatchGroupOptions =
            this.state.dispatchGroupsStatus.key == "ready"
                ? this.state.dispatchGroupsStatus.groups.map((g) => ({
                    text: g.name,
                    value: g.id,
                }))
                : [];

        let actionPanel = null;
        if (actionStatus.key == "error") {
            actionPanel = (
                <Message
                    negative
                    header={this.props.t("jobs.createJob.failedToCreateJobHeader")}
                    content={actionStatus.message}
                />
            );
        } else if (actionStatus.key == "success") {
            actionPanel = (
                <Message
                    positive
                    header={this.props.t("jobs.createJob.successfullyCreatedJobHeader")}
                    content={this.props.t("jobs.createJob.successfullyCreatedJobBody")}
                />
            );
        }

        const locationStatus = this.getLocationStatus();

        let dimmerContent = null;
        if (geocodeStatus.key == "loading") {
            dimmerContent = (
                <Loader active>
                    <Button
                        size="tiny"
                        color="grey"
                        compact
                        content={this.props.t("jobs.createJob.cancelGeocode")}
                        onClick={() => this.setState({geocodeStatus: {key: "ready"}})}
                    />
                </Loader>
            );
        } else if (agencyRegionStatus.key == "loading") {
            dimmerContent = <Loader active/>;
        }

        let bounds;
        if (locationStatus.key == "ok") {
            bounds = L.latLng([
                locationStatus.latitude,
                locationStatus.longitude,
            ]).toBounds(300);
        } else if (agencyRegionStatus.key == "success") {
            bounds = L.geoJSON(
                agencyRegionStatus.region.bufferedBoundary,
            ).getBounds();
        }

        return (
            <div
                className="underHorizNavContent"
                style={{padding: "40px 60px", overflowY: "auto"}}
            >
                <Message
                    info
                    icon="info circle"
                    content={this.props.t("jobs.createJob.createJobInfoBody")}
                />
                <Grid>
                    <Grid.Row>
                        <Grid.Column width={8} style={{display: "flex"}}>
                            <Dimmer.Dimmable
                                blurring
                                dimmed={dimmerContent != null}
                                style={{display: "flex", flex: 1}}
                            >
                                <Dimmer active={dimmerContent != null} inverted>
                                    {dimmerContent}
                                </Dimmer>
                                <BryxMap
                                    {...this.props}
                                    bounds={bounds}
                                    onclick={(e) =>
                                        this.setState({
                                            latitudeString: e.latlng.lat.toString(),
                                            longitudeString: e.latlng.lng.toString(),
                                        })
                                    }
                                >
                                    {locationStatus.key == "ok" ? (
                                        <BryxGeoJSONLayer
                                            {...this.props}
                                            geojson={
                                                {
                                                    type: "Point",
                                                    coordinates: [
                                                        locationStatus.longitude,
                                                        locationStatus.latitude,
                                                    ],
                                                } as Point
                                            }
                                        />
                                    ) : null}
                                </BryxMap>
                            </Dimmer.Dimmable>
                        </Grid.Column>
                        <Grid.Column width={8}>
                            <Form style={{marginBottom: "20px"}}>
                                <Form.Group>
                                    <Form.Input
                                        type="text"
                                        autoFocus
                                        width={10}
                                        required
                                        label={this.props.t("jobs.createJob.synopsis")}
                                        placeholder={this.props.t("jobs.createJob.synopsis")}
                                        value={synopsis || ""}
                                        onChange={(e, d) =>
                                            this.setState({synopsis: nullIfBlank(d.value)})
                                        }
                                    />
                                    <Form.Input
                                        type="text"
                                        width={6}
                                        label={this.props.t("jobs.createJob.incidentId")}
                                        placeholder={this.props.t("jobs.createJob.incidentId")}
                                        value={incidentId || ""}
                                        onChange={(e, d) =>
                                            this.setState({incidentId: nullIfBlank(d.value)})
                                        }
                                    />
                                </Form.Group>
                                <Form.Group>
                                    <Form.Dropdown
                                        required
                                        width={6}
                                        label={this.props.t("jobs.createJob.jobType")}
                                        placeholder={this.props.t("jobs.createJob.jobType")}
                                        selection
                                        value={JobTypeEnum[jobType]}
                                        options={enumNames(JobTypeEnum).map((typeName) => ({
                                            key: typeName,
                                            value: typeName,
                                            text: this.props.t(`jobs.jobTypes.${typeName}`),
                                        }))}
                                        onChange={(e, d) => {
                                            const newType: JobTypeEnum = (JobTypeEnum as any)[
                                                d.value as string
                                                ];
                                            this.props.local.createJobType = newType;
                                            this.setState({
                                                jobType: newType,
                                            });
                                        }}
                                    />
                                    <Form.Input
                                        type="text"
                                        width={10}
                                        label={this.props.t("jobs.createJob.typeDesc")}
                                        placeholder={this.props.t("jobs.createJob.typeDesc")}
                                        value={typeDesc || ""}
                                        onChange={(e, d) =>
                                            this.setState({typeDesc: nullIfBlank(d.value)})
                                        }
                                    />
                                </Form.Group>
                                <Form.Group widths="equal">
                                    <Form.Input
                                        type="text"
                                        required
                                        label={this.props.t("jobs.createJob.address")}
                                        placeholder={this.props.t("jobs.createJob.address")}
                                        value={address || ""}
                                        onChange={(e, d) =>
                                            this.setState(
                                                {
                                                    address: nullIfBlank(d.value),
                                                },
                                                () => this.postGeocodeUpdate(),
                                            )
                                        }
                                    />
                                </Form.Group>
                                <Form.Group>
                                    <Form.Input
                                        type="text"
                                        width={11}
                                        required
                                        label={this.props.t("jobs.createJob.city")}
                                        placeholder={this.props.t("jobs.createJob.city")}
                                        value={city || ""}
                                        onChange={(e, d) =>
                                            this.setState(
                                                {
                                                    city: nullIfBlank(d.value),
                                                },
                                                () => this.postGeocodeUpdate(),
                                            )
                                        }
                                    />
                                    <Form.Input
                                        type="text"
                                        width={5}
                                        required
                                        label={this.props.t("jobs.createJob.state")}
                                        placeholder={this.props.t("jobs.createJob.state")}
                                        value={state || ""}
                                        onChange={(e, d) =>
                                            this.setState(
                                                {
                                                    state: nullIfBlank(d.value),
                                                },
                                                () => this.postGeocodeUpdate(),
                                            )
                                        }
                                    />
                                </Form.Group>
                                <Form.Group widths="equal">
                                    <Form.Input
                                        type="text"
                                        label={this.props.t("jobs.createJob.latitude")}
                                        placeholder={this.props.t("jobs.createJob.latitude")}
                                        value={latitudeString || ""}
                                        error={this.getLatitudeStatus().key == "error"}
                                        disabled={geocodeStatus.key == "loading"}
                                        onChange={(e, d) =>
                                            this.setState({latitudeString: nullIfBlank(d.value)})
                                        }
                                    />
                                    <Form.Input
                                        type="text"
                                        label={this.props.t("jobs.createJob.longitude")}
                                        placeholder={this.props.t("jobs.createJob.longitude")}
                                        value={longitudeString || ""}
                                        error={this.getLongitudeStatus().key == "error"}
                                        disabled={geocodeStatus.key == "loading"}
                                        onChange={(e, d) =>
                                            this.setState({longitudeString: nullIfBlank(d.value)})
                                        }
                                    />
                                </Form.Group>
                                <Form.Dropdown
                                    required
                                    search
                                    multiple
                                    selection
                                    noResultsMessage={this.props.t(
                                        "jobs.createJob.noOtherDispatchGroups",
                                    )}
                                    label={this.props.t("jobs.createJob.dispatchGroups")}
                                    placeholder={
                                        dispatchGroupsStatus.key == "loading"
                                            ? this.props.t("general.loading")
                                            : this.props.t("jobs.createJob.dispatchGroups")
                                    }
                                    options={dispatchGroupOptions}
                                    value={selectedDispatchGroups.map((g) => g.id)}
                                    loading={dispatchGroupsStatus.key == "loading"}
                                    onChange={(e, d) => {
                                        const newDispatchGroupIds = d.value as string[];
                                        this.setState((prevState: CreateJobState) => {
                                            if (prevState.dispatchGroupsStatus.key == "ready") {
                                                prevState.selectedDispatchGroups =
                                                    prevState.dispatchGroupsStatus.groups.filter((g) =>
                                                        newDispatchGroupIds.some((ng) => ng == g.id),
                                                    );
                                            }
                                            return prevState;
                                        });
                                    }}
                                />
                                <Form.Group>
                                    <Form.Button type="button"
                                                 primary
                                                 disabled={this.getSubmissionStatus().key != "ok"}
                                                 loading={actionStatus.key == "loading"}
                                                 content={this.props.t("general.create") as string}
                                                 onClick={() => this.onClickCreate()}/>
                                    <Form.Button type="button"
                                                 disabled={geocodeStatus.key != "success"}
                                                 content={this.props.t("jobs.createJob.clearGeocode") as string}
                                                 onClick={() => this.setState({ latitudeString: null, longitudeString: null, geocodeStatus: { key: "ready" } })}
                                    />
                                </Form.Group>
                            </Form>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                {dispatchGroupsStatus.key == "error" ? (
                    <Message
                        negative
                        header={this.props.t(
                            "jobs.createJob.failedToLoadDispatchGroupsHeader",
                        )}
                        content={dispatchGroupsStatus.message}
                    />
                ) : null}
                {actionPanel}
            </div>
        );
    }
}

export default withContext(CreateJobTab, "api", "local", "i18n");
