import * as moment from "moment";
import * as React from "react";
import {RouteComponentProps} from "react-router";
import {Redirect, Route, Switch} from "react-router-dom";
import {Button, Header, Icon, List, Menu} from "semantic-ui-react";

import RouterMenuItem from "../../components/routerMenuItem";
import {AuthAgency, FeatureTrialStatus} from "@bryxinc/lunch/models";

import {StationBoardsTab} from "./stationBoardsTab";
import {StationBoardTrialRequestModal} from "./stationBoardTrialRequestModal";

import {
    SupportModalSkin,
    SharedSupportUtils,
} from "@bryxinc/lunch/utils/functions";

import BryxApi from "@bryxinc/lunch/utils/ManagementApi";
import {
    withContext,
    WithTranslation,
    WithLocal,
    WithApi,
} from "@bryxinc/lunch/context";

export interface StationBoardsProps
    extends RouteComponentProps<{ boardId: string }>,
        WithTranslation,
        WithLocal,
        WithApi<BryxApi> {
    selectedAgency: AuthAgency;
}

export interface StationBoardsState {
    overlay: "none" | "trialRequest";
}

export class StationBoardsPage extends React.Component<StationBoardsProps,
    StationBoardsState> {
    constructor(props: StationBoardsProps, context: any) {
        super(props, context);
        this.state = {
            overlay: "none",
        };
    }

    private static formatDate(d: Date): string {
        return moment(d).format("M/D/YYYY");
    }

    render() {
        const stationBoardFeature = this.props.selectedAgency.stationBoard;
        if (stationBoardFeature.enabled) {
            return (
                <div
                    id="stationBoardsContent"
                    className="pageContent horizontalNavAndContent"
                >
                    <div id="horizontalNav" className="Component_HorizontalNav">
                        <Menu attached="top" tabular>
                            <RouterMenuItem to="/station-boards">
                                {this.props.t("stationBoards.tabs.boards")}
                            </RouterMenuItem>
                            <Menu.Menu position="right">
                                {stationBoardFeature.trialStatus ==
                                FeatureTrialStatus.active ? (
                                    <Menu.Item>
                                        <Icon name="circle" color="orange"/>
                                        {stationBoardFeature.expiration != null
                                            ? this.props.t("stationBoards.trialActiveUntil", {
                                                replace: {
                                                    until: StationBoardsPage.formatDate(
                                                        stationBoardFeature.expiration,
                                                    ),
                                                },
                                            })
                                            : this.props.t("stationBoards.trialActiveIndefinitely")}
                                        &nbsp;
                                        <a
                                            style={{cursor: "pointer"}}
                                            onClick={() =>
                                                SharedSupportUtils.onOpenSupport({
                                                    skin: SupportModalSkin.sales,
                                                    subject: this.props.t(
                                                        "stationBoards.upgradeNowSubject",
                                                    ),
                                                    body: this.props.t("stationBoards.upgradeNowBody", {
                                                        replace: {
                                                            agencyName: this.props.selectedAgency.name,
                                                        },
                                                    }),
                                                })
                                            }
                                        >
                                            {this.props.t("stationBoards.upgradeNow")}
                                        </a>
                                    </Menu.Item>
                                ) : (
                                    <Menu.Item>
                                        <Icon name="circle" color="green"/>
                                        {stationBoardFeature.expiration != null
                                            ? this.props.t("stationBoards.activeUntil", {
                                                replace: {
                                                    until: StationBoardsPage.formatDate(
                                                        stationBoardFeature.expiration,
                                                    ),
                                                },
                                            })
                                            : this.props.t("stationBoards.activeIndefinitely")}
                                    </Menu.Item>
                                )}
                            </Menu.Menu>
                        </Menu>
                    </div>

                    <Switch>
                        <Route
                            path="/station-boards/:boardId?"
                            render={(props) => (
                                <StationBoardsTab
                                    {...this.props}
                                    selectedAgency={this.props.selectedAgency}
                                    {...props}
                                />
                            )}
                        />
                        <Redirect to="/station-boards"/>
                    </Switch>
                </div>
            );
        } else {
            return (
                <div
                    id="stationBoardsContent"
                    className="pageContent horizontalNavAndContent"
                >
                    <div
                        className="underHorizNavContent"
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                            }}
                        >
                            <Header as="h2" icon>
                                <Icon name="tv"/>
                                {this.props.t("stationBoards.productHeader")}
                                <Header.Subheader>
                                    {this.props.t("stationBoards.productSubheader")}
                                </Header.Subheader>
                            </Header>
                            <List bulleted>
                                <List.Item>
                                    {this.props.t("stationBoards.productBullets.1")}
                                </List.Item>
                                <List.Item>
                                    {this.props.t("stationBoards.productBullets.2")}
                                </List.Item>
                                <List.Item>
                                    {this.props.t("stationBoards.productBullets.3")}
                                </List.Item>
                            </List>
                            <Button
                                positive
                                fluid
                                content={this.props.t("stationBoards.learnMore")}
                                target="_blank"
                                href={this.props.t("stationBoards.learnMoreUrl")}
                            />
                            {stationBoardFeature.trialStatus == FeatureTrialStatus.ready ? (
                                <Button
                                    primary
                                    fluid
                                    content={this.props.t("stationBoards.startFreeTrial")}
                                    style={{marginTop: "5px"}}
                                    onClick={() => this.setState({overlay: "trialRequest"})}
                                />
                            ) : null}
                        </div>
                    </div>
                    <StationBoardTrialRequestModal
                        {...this.props}
                        agency={this.props.selectedAgency}
                        open={this.state.overlay == "trialRequest"}
                        onSubmit={(newAgency) =>
                            this.setState(
                                {
                                    overlay: "none",
                                },
                                () => AuthAgency.notifyReplace(newAgency),
                            )
                        }
                        onDismiss={() =>
                            this.setState({
                                overlay: "none",
                            })
                        }
                    />
                </div>
            );
        }
    }
}
