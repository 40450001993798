import * as React from "react";
import {RouteComponentProps} from "react-router";
import {Redirect, Route, Switch} from "react-router-dom";
import {Label, Menu} from "semantic-ui-react";
import {SheetTransitionGroup} from "../../components/customTransitionGroups";
import RouterMenuItem from "../../components/routerMenuItem";
import {AuthAgency} from "@bryxinc/lunch/models";
import {BadgeStatus} from "../../utils/badgeManager";
import {ApparatusTab} from "./apparatusTab";
import {JoinRequestsTab} from "./joinRequestsTab";
import {UsersTab} from "./usersTab";

import BryxApi from "@bryxinc/lunch/utils/ManagementApi";
import {
    withContext,
    WithTranslation,
    WithLocal,
    WithApi,
} from "@bryxinc/lunch/context";

export interface MembersProps
    extends RouteComponentProps<any>,
        WithTranslation,
        WithLocal,
        WithApi<BryxApi> {
    selectedAgency: AuthAgency;
    pendingJoinRequestsBadge: BadgeStatus;
}

export class MembersPage extends React.Component<MembersProps, {}> {
    render() {
        return (
            <div id="MembersContent" className="pageContent horizontalNavAndContent">
                <SheetTransitionGroup>
                    <div id="MembersHorizontalNav" className="Component_HorizontalNav">
                        <Menu attached="top" tabular>
                            <RouterMenuItem to="/members/users">
                                {this.props.t("members.tabs.users")}
                            </RouterMenuItem>
                            <RouterMenuItem to="/members/apparatus">
                                {this.props.t("members.tabs.apparatus")}
                            </RouterMenuItem>
                            <RouterMenuItem to="/members/join-requests">
                                {this.props.t("members.tabs.join-requests")}
                                {this.props.pendingJoinRequestsBadge.key == "ready" &&
                                this.props.pendingJoinRequestsBadge.count > 0 ? (
                                    <Label
                                        color="red"
                                        size="small"
                                        style={{fontSize: "10px", textAlign: "center"}}
                                    >
                                        {this.props.pendingJoinRequestsBadge.count}
                                    </Label>
                                ) : null}
                            </RouterMenuItem>
                            {/* TODO: [Future] Add this back: <RouterMenuItem to="/members/invites">{i18n.t("members.tabs.invites")}</RouterMenuItem>*/}
                        </Menu>
                    </div>

                    <Switch>
                        <Route
                            path="/members/users/:userId?"
                            render={(props) => (
                                <UsersTab
                                    {...this.props}
                                    selectedAgency={this.props.selectedAgency}
                                    {...props}
                                />
                            )}
                        />
                        ;
                        <Route
                            path="/members/apparatus/:apparatusId?"
                            render={(props) => (
                                <ApparatusTab
                                    {...this.props}
                                    selectedAgency={this.props.selectedAgency}
                                    {...props}
                                />
                            )}
                        />
                        <Route
                            path="/members/join-requests"
                            render={(props) => (
                                <JoinRequestsTab
                                    {...this.props}
                                    selectedAgency={this.props.selectedAgency}
                                    {...props}
                                />
                            )}
                        />
                        {/* TODO: [Future] Add this back: <Route path="/members/invites" render={(props) => (<InvitesTab activateSheet={this.activateSheet.bind(this)} {...this.props}/>)}/>*/}
                        <Redirect to="/members/users"/>
                    </Switch>
                </SheetTransitionGroup>
            </div>
        );
    }
}

export default withContext(MembersPage, "api", "local", "i18n");
