import * as React from "react";
import {RouteProps, Switch, Route} from "react-router";
import {BrowserRouter} from "react-router-dom";
import Main from "./Main";
import Login from "./login";
import EulaPage from "../pages/eulaPage";

export default class MainRouter extends React.Component<RouteProps, any> {
    render() {
        return (
            <BrowserRouter>
                <Switch>
                    <Route path="/login" component={Login}/>
                    <Route path="/eula" component={EulaPage}/>
                    <Route component={Main}/>
                </Switch>
            </BrowserRouter>
        );
    }
}
