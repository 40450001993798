import * as React from "react";
import { Dropdown } from "semantic-ui-react";
import { AuthAgency } from "@bryxinc/lunch/models/auth";
import { RouteComponentProps } from "react-router";
import { ApiResult } from "@bryxinc/lunch/models";
import BryxApi from "@bryxinc/lunch/utils/ManagementApi";
import {
  withContext,
  WithTranslation,
  WithLocal,
  WithApi,
} from "@bryxinc/lunch/context";

interface TopBarProps
  extends RouteComponentProps,
    WithTranslation,
    WithLocal,
    WithApi<BryxApi> {
  signoutComplete: (result: ApiResult<null>) => void;
  onSwitchAgency: () => void;
  onContactSupport: () => void;
  currentAgency: AuthAgency | null;
}

export class TopBar extends React.Component<TopBarProps, any> {
  private signout() {
    this.props.api.signOut(this.props.signoutComplete);
  }

  render() {
    const trigger = (
      <img
        id="settingsIcon"
        src="/resources/assets/settings_cog.svg"
        style={{ width: "24px", height: "24px" }}
      />
    );
    const currentAgency = this.props.currentAgency;

    // See https://github.com/Semantic-Org/Semantic-UI/issues/3645
    return (
      <div
        id="TopBar_SignedIn"
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div id="TopBar_AgencyName">{this.props.currentAgency!.name}</div>
        <div id="TopBar_RightSection">
          {currentAgency?.supportPin && (
            <div>Support Pin: {currentAgency.supportPin}</div>
          )}
          <div id="TopBar_Name">{this.props.local.fullName}</div>
          <Dropdown trigger={trigger} icon={null}>
            <Dropdown.Menu style={{ right: 0, left: "auto" }}>
              <Dropdown.Item onClick={this.props.onSwitchAgency}>
                {this.props.t("topBar.switchAgency")}
              </Dropdown.Item>
              <Dropdown.Divider />
              <Dropdown.Item onClick={this.props.onContactSupport}>
                {this.props.t("topBar.contactSupport")}
              </Dropdown.Item>
              <Dropdown.Item onClick={this.signout.bind(this)}>
                {this.props.t("topBar.signOut")}
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
    );
  }
}

export default withContext(TopBar, "api", "local", "i18n");
