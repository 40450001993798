import * as React from "react";
import {Button, Message, Modal} from "semantic-ui-react";
import {AgencyGroup} from "@bryxinc/lunch/models";

import {RouteComponentProps} from "react-router";
import BryxApi from "@bryxinc/lunch/utils/ManagementApi";
import {
    withContext,
    WithTranslation,
    WithLocal,
    WithApi,
} from "@bryxinc/lunch/context";

export type RemoveAllMembersModalViewStatus =
    | { key: "hidden" }
    | { key: "shown"; group: AgencyGroup };

interface RemoveAllMembersModalProps
    extends RouteComponentProps<any>,
        WithTranslation,
        WithLocal,
        WithApi<BryxApi> {
    agencyId: string;

    onDismiss(reload: boolean): void;

    viewStatus: RemoveAllMembersModalViewStatus;
}

interface RemoveAllMembersModalState {
    actionStatus:
        | { key: "loading" }
        | { key: "ready" }
        | { key: "error"; message: string };
}

export class RemoveAllMembersModal extends React.Component<RemoveAllMembersModalProps,
    RemoveAllMembersModalState> {
    constructor(props: RemoveAllMembersModalProps, context: any) {
        super(props, context);

        this.state = RemoveAllMembersModal.getInitialState();
    }

    static getInitialState(): RemoveAllMembersModalState {
        return {
            actionStatus: {key: "ready"},
        };
    }

    componentWillReceiveProps(newProps: RemoveAllMembersModalProps) {
        if (
            this.props.viewStatus.key == "hidden" &&
            newProps.viewStatus.key == "shown"
        ) {
            // Opening modal
            this.setState(RemoveAllMembersModal.getInitialState());
        }
    }

    private removeAllMembers() {
        const {viewStatus} = this.props;

        if (viewStatus.key == "shown") {
            this.setState({
                actionStatus: {key: "loading"},
            });

            this.props.api.removeAllClientsFromGroup(
                this.props.agencyId,
                viewStatus.group.id,
                (result) => {
                    if (result.success == true) {
                        this.setState(
                            {
                                actionStatus: {key: "ready"},
                            },
                            () => this.props.onDismiss(true),
                        );
                    } else {
                        this.props.local.logWarn(
                            `Failed to remove all members: ${result.debugMessage}`,
                        );
                        this.setState({
                            actionStatus: {key: "error", message: result.message},
                        });
                    }
                },
            );
        }
    }

    render() {
        const {onDismiss, viewStatus} = this.props;
        const {actionStatus} = this.state;

        let header;
        if (viewStatus.key == "shown") {
            const memberCount = viewStatus.group.membersCount;
            header = this.props.t(
                memberCount != 1
                    ? "groups.removeAllMembers.explain_plural"
                    : "groups.removeAllMembers.explain",
                {
                    replace: {
                        memberCount: memberCount,
                        groupName: viewStatus.group.name,
                    },
                },
            );
        } else {
            header = undefined;
        }

        return (
            <Modal open={viewStatus.key == "shown"} onClose={() => onDismiss(false)}>
                <Modal.Header>
                    {viewStatus.key == "shown"
                        ? this.props.t("groups.removeAllMembers.header", {
                            replace: {groupName: viewStatus.group.name},
                        })
                        : null}
                </Modal.Header>
                <Modal.Content style={{backgroundColor: "#fbfbfb"}}>
                    {header}
                    {actionStatus.key == "error" ? (
                        <Message negative content={actionStatus.message}/>
                    ) : undefined}
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        content={this.props.t("general.cancel")}
                        disabled={actionStatus.key == "loading"}
                        onClick={() => onDismiss(false)}
                    />
                    <Button
                        negative
                        content={this.props.t("groups.removeAllMembers.removeAll")}
                        loading={actionStatus.key == "loading"}
                        onClick={() => this.removeAllMembers()}
                    />
                </Modal.Actions>
            </Modal>
        );
    }
}

export default withContext(RemoveAllMembersModal, "api", "local", "i18n");
