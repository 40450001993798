import * as React from "react";
import {RouteComponentProps} from "react-router";
import {Redirect, Route, Switch} from "react-router-dom";
import {Label, Loader, Menu, Message} from "semantic-ui-react";
import RouterMenuItem from "../../components/routerMenuItem";
import {AuthAgency, SCU} from "@bryxinc/lunch/models";

import {StationMonitoringTab} from "./stationMonitoringTab";

import BryxApi from "@bryxinc/lunch/utils/ManagementApi";
import {
    withContext,
    WithTranslation,
    WithLocal,
    WithApi,
} from "@bryxinc/lunch/context";
import {StationManagerTab} from "./stationManagerTab";
import {StationAlertingZeroState} from "../../components/stationAlertingZeroState";

export interface DispatchProps
    extends RouteComponentProps<{}>,
        WithTranslation,
        WithLocal,
        WithApi<BryxApi> {
    selectedAgency: AuthAgency;
}

export interface DispatchState {
    loadStatus:
        | { key: "loading" }
        | { key: "success"; scus: SCU[] }
        | { key: "error"; message: string };
    overlay: "none";
}

export class DispatchPage extends React.Component<DispatchProps,
    DispatchState> {
    constructor(props: DispatchProps, context: any) {
        super(props, context);
        this.state = DispatchPage.getInitialState();
    }

    private static getInitialState(): DispatchState {
        return {
            loadStatus: {key: "loading"},
            overlay: "none",
        };
    }

    componentDidMount() {
        this.loadAlerts(this.props);
    }

    componentWillReceiveProps(nextProps: DispatchProps) {
        if (nextProps.selectedAgency.id != this.props.selectedAgency.id) {
            this.setState(DispatchPage.getInitialState(), () =>
                this.loadAlerts(nextProps),
            );
        }
    }

    private loadAlerts(props: DispatchProps) {
        this.setState({loadStatus: {key: "loading"}});
        this.props.api.getAgencySCUs(props.selectedAgency.id, (result) => {
            if (result.success) {
                this.setState({loadStatus: {key: "success", scus: result.value}});
            } else {
                this.props.local.logWarn(
                    `Failed to load agency SCU alerts: ${result.message}`,
                );
                this.setState({
                    loadStatus: {key: "error", message: result.message},
                });
            }
        });
    }

    render() {
        const {loadStatus} = this.state;
        if (loadStatus.key == "loading") {
            return (
                <div
                    id="stationAlertingContent"
                    className="pageContent horizontalNavAndContent"
                >
                    <div className="underHorizNavContent tableUnderNavContent">
                        <Loader active/>
                    </div>
                </div>
            );
        } else if (loadStatus.key == "error") {
            return (
                <div
                    id="stationAlertingContent"
                    className="pageContent horizontalNavAndContent"
                >
                    <Message
                        negative
                        content={loadStatus.message}
                        style={{margin: "30px"}}
                    />
                </div>
            );
        }
        return (
            <div
                id="stationAlertingContent"
                className="pageContent horizontalNavAndContent"
            >
                <div id="horizontalNav" className="Component_HorizontalNav">
                    <Menu attached="top" tabular>
                        <React.Fragment>
                            <RouterMenuItem to="/dispatch/monitoring">
                                {this.props.t("dispatch.tabs.monitoring")}
                            </RouterMenuItem>
                            <RouterMenuItem to="/dispatch/scu-management">
                                {this.props.t("dispatch.tabs.management")}
                            </RouterMenuItem>
                        </React.Fragment>
                    </Menu>
                </div>

                <Switch>
                    {loadStatus.scus.length == 0 && (
                        <div className="underHorizNavContent tableUnderNavContent">
                            <StationAlertingZeroState {...this.props} />
                        </div>
                    )}
                    {loadStatus.scus.length > 0 ? (
                        <Route
                            path="/dispatch/monitoring"
                            render={(props) => (
                                <StationMonitoringTab
                                    {...this.props}
                                    selectedAgency={this.props.selectedAgency}
                                    {...props}
                                />
                            )}
                        />
                    ) : null}
                    {loadStatus.scus.length > 0 ? (
                        <Route
                            path="/dispatch/scu-management"
                            render={(props) => (
                                <StationManagerTab
                                    {...this.props}
                                    selectedAgency={this.props.selectedAgency}
                                    {...props}
                                />
                            )}
                        />
                    ) : null}
                    <Redirect to="/dispatch/monitoring"/>
                </Switch>
            </div>
        );
    }
}

export default withContext(DispatchPage, "api", "local", "i18n");
