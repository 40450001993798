import { Geometry, Polygon, Point, FeatureCollection } from "geojson";
import { BryxMap } from "@bryxinc/ui/dist/components/BryxMap";
import { useConstCallback } from "powerhooks";
import * as React from "react";
import { useEffect, useState } from "react";
import { Agency } from "@bryxinc/lunch/models";
import { Message } from "semantic-ui-react";
import { useTranslation } from "@bryxinc/lunch/context";

export function SurveyMap({
  point,
  setPoint,
  agency,
  mapRef,
  onMapMove,
  center,
  showHint,
  children,
}: {
  agency: Agency;
  children?: React.ReactNode;
  point?: Point | Polygon;
  setPoint: (point: Point | Polygon | undefined) => unknown;
  mapRef?: React.MutableRefObject<ReturnType<typeof BryxMap.useMap>>;
  onMapMove?: (center: Polygon) => unknown;
  center?: Geometry;
  features?: FeatureCollection;
  showHint?: boolean;
}) {
  const { t } = useTranslation();
  const [zoom, setZoom] = useState(10);
  const [lastPoint, setLastPoint] = useState(point);
  useEffect(() => {
    if (point) {
      setLastPoint(point);
    }
  }, [point]);
  return (
    <>
      <div style={{ display: "flex", height: "100%", flexDirection: "column" }}>
        <div style={{ flex: "1 0 auto" }}>
          <BryxMap
            center={
              center ||
              point ||
              lastPoint ||
              (agency.bufferedBoundary as Geometry)
            }
            onCenterChange={useConstCallback((center: Polygon) => {
              if (onMapMove) onMapMove(center);
            })}
            zoom={zoom}
            onZoomChange={useConstCallback(() => { })}
            userSelection={point}
            onUserSelectionChange={setPoint}
          >
            <SurveyMapBehaviour mapRef={mapRef} />
            {children}
          </BryxMap>
        </div>
        {showHint && (
          <Message style={{ flex: "0 0 auto" }}>
            {t("siteSurvey.siteSurveyMap.selectionHint")}
          </Message>
        )}
      </div>
    </>
  );
}

function SurveyMapBehaviour({
  mapRef,
}: {
  mapRef?: React.MutableRefObject<ReturnType<typeof BryxMap.useMap>>;
}) {
  const map = BryxMap.useMap();
  if (mapRef) {
    mapRef.current = map;
  }
  return null;
}
