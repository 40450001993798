import * as React from "react";
import {
    Button,
    Checkbox,
    Divider,
    Header,
    Icon,
    Label,
    List,
    Message,
    Modal,
    Popup,
    Radio,
    Table,
} from "semantic-ui-react";
import {
    HeaderDataItem,
    PaginatedTable,
} from "../../components/paginatedTable";
import {ZeroStateView} from "../../components/zeroStateView";
import {
    ApiResult,
    PageResult,
    SortingConfig,
    BasicAgencyGroup,
    ResponseOption,
    ResponseOptionClientMembership,
    ResponseOptionClientMembershipSortingOptions,
    EditClientResponseOptionResult,
} from "@bryxinc/lunch/models";

import BryxApi from "@bryxinc/lunch/utils/ManagementApi";
import * as colors from "@bryxinc/style/color";
import {RouteComponentProps} from "react-router";
import {
    withContext,
    WithTranslation,
    WithLocal,
    WithApi,
} from "@bryxinc/lunch/context";

interface ResponseOptionClientMembershipsModalProps
    extends RouteComponentProps<any>,
        WithTranslation,
        WithLocal,
        WithApi<BryxApi> {
    agencyId: string;

    onDismiss(): void;

    viewStatus:
        | { key: "hidden" }
        | {
        key: "shown";
        responseOption: ResponseOption;
        dispatchGroups: BasicAgencyGroup[];
    };
}

type DispatchGroupSelection = { group: BasicAgencyGroup; selected: boolean };

interface ResponseOptionClientMembershipsModalState {
    status:
        | { key: "ready" }
        | { key: "loading"; actionType: "addAll" | "removeAll" | "toggle" }
        | { key: "error"; message: string }
        | {
        key: "bulkResult";
        actionType: "addAll" | "removeAll";
        result: EditClientResponseOptionResult;
    };
    activeSearch: boolean;
    dispatchGroupSelections: DispatchGroupSelection[];
    clientMemberships: ResponseOptionClientMembership[];
}

export class ResponseOptionUsersModal extends React.Component<ResponseOptionClientMembershipsModalProps,
    ResponseOptionClientMembershipsModalState> {
    private paginatedTable: PaginatedTable | null = null;

    constructor(props: ResponseOptionClientMembershipsModalProps, context: any) {
        super(props, context);

        this.state = ResponseOptionUsersModal.getInitialState(props);
    }

    static getInitialState(
        props: ResponseOptionClientMembershipsModalProps,
    ): ResponseOptionClientMembershipsModalState {
        let dispatchGroupSelections: DispatchGroupSelection[] = [];

        if (props.viewStatus.key == "shown") {
            const isSingleGroup = props.viewStatus.dispatchGroups.length == 1;
            dispatchGroupSelections = props.viewStatus.dispatchGroups
                .map((g) => ({group: g, selected: isSingleGroup}))
                .sort(ResponseOptionUsersModal.compareGroupSelectors);
        }

        return {
            status: {key: "ready"},
            activeSearch: false,
            dispatchGroupSelections: dispatchGroupSelections,
            clientMemberships: [],
        };
    }

    componentWillReceiveProps(
        nextProps: ResponseOptionClientMembershipsModalProps,
    ) {
        if (
            this.props.viewStatus.key == "hidden" &&
            nextProps.viewStatus.key == "shown"
        ) {
            this.setState(ResponseOptionUsersModal.getInitialState(nextProps));
        }
    }

    private static compareGroupSelectors(
        a: DispatchGroupSelection,
        b: DispatchGroupSelection,
    ): number {
        const aSelectedPoint = a.selected ? 1 : 0;
        const bSelectedPoint = b.selected ? 1 : 0;

        return (
            bSelectedPoint - aSelectedPoint ||
            BasicAgencyGroup.compare(a.group, b.group)
        );
    }

    private loadItems(
        limit: number,
        activePage: number,
        searchString: string | null,
        sortConfig: SortingConfig<ResponseOptionClientMembershipSortingOptions>,
        callback: (
            result: ApiResult<PageResult<ResponseOptionClientMembership>>,
        ) => void,
    ) {
        const {viewStatus} = this.props;

        if (viewStatus.key == "shown") {
            this.setState({activeSearch: searchString != null});

            const wrappedCallback = (
                result: ApiResult<PageResult<ResponseOptionClientMembership>>,
            ) => {
                if (result.success == true) {
                    this.setState({
                        clientMemberships: result.value.items,
                    });
                }
                callback(result);
            };

            const selectedGroups = this.state.dispatchGroupSelections.filter(
                (g) => g.selected,
            );

            if (selectedGroups.length == 0) {
                wrappedCallback({
                    success: true,
                    value: {
                        count: 0,
                        items: [],
                    },
                });
            } else {
                this.props.api.getAgencyResponseOptionClientMemberships(
                    this.props.agencyId,
                    viewStatus.responseOption.id,
                    selectedGroups.map((g) => g.group.id),
                    limit,
                    activePage,
                    searchString,
                    sortConfig,
                    wrappedCallback,
                );
            }
        }
    }

    private changeAllGroups(value: boolean) {
        this.setState((prevState: ResponseOptionClientMembershipsModalState) => {
            prevState.dispatchGroupSelections.forEach((g) => (g.selected = value));
            prevState.status = {key: "ready"};
            return prevState;
        });

        if (this.paginatedTable != null) {
            this.paginatedTable.resetTable();
        }
    }

    private onResponseOptionToggle(
        groupId: string,
        clientId: string,
        value: boolean,
    ) {
        if (this.props.viewStatus.key == "shown") {
            this.setState({status: {key: "loading", actionType: "toggle"}});
            const responseOptionId = this.props.viewStatus.responseOption.id;
            this.props.api.editClientsResponseOptionForDispatchGroups(
                this.props.agencyId,
                responseOptionId,
                [groupId],
                clientId,
                value,
                (result) => {
                    if (result.success == true) {
                        if (result.value.errorCount == 0) {
                            this.setState(
                                (prevState: ResponseOptionClientMembershipsModalState) => {
                                    prevState.status = {key: "ready"};
                                    return prevState;
                                },
                                () => {
                                    if (this.paginatedTable != null) {
                                        this.paginatedTable.resetTable();
                                    }
                                },
                            );
                        } else {
                            this.setState({
                                status: {
                                    key: "error",
                                    message: this.props.t(
                                        "agency.responseOptions.membersTable.cannotToggle",
                                    ),
                                },
                            });
                        }
                    } else {
                        this.props.local.logWarn(
                            `Failed to set response option for client ${clientId} in group ${groupId} to ${value}`,
                        );
                        this.setState({
                            status: {key: "error", message: result.message},
                        });
                    }
                },
            );
        }
    }

    private onClickEditAll(value: boolean) {
        if (this.props.viewStatus.key != "shown") {
            return;
        }
        const actionType = value ? "addAll" : "removeAll";
        this.setState({status: {key: "loading", actionType: actionType}});
        const responseOptionId = this.props.viewStatus.responseOption.id;
        const groupIds = this.state.dispatchGroupSelections
            .filter((s) => s.selected)
            .map((s) => s.group.id);
        this.props.api.editClientsResponseOptionForDispatchGroups(
            this.props.agencyId,
            responseOptionId,
            groupIds,
            "all",
            value,
            (result) => {
                if (result.success == true) {
                    this.setState(
                        {
                            status: {
                                key: "bulkResult",
                                actionType: actionType,
                                result: result.value,
                            },
                        },
                        () => {
                            if (this.paginatedTable != null) {
                                this.paginatedTable.reload();
                            }
                        },
                    );
                } else {
                    this.props.local.logWarn(
                        `Failed to set response option for all clients in groups ${groupIds} to ${value}`,
                    );
                    this.setState({
                        status: {key: "error", message: result.message},
                    });
                }
            },
        );
    }

    private onDispatchGroupChecked(groupId: string, value: boolean) {
        if (this.props.viewStatus.key == "shown") {
            this.setState(
                (prevState: ResponseOptionClientMembershipsModalState) => {
                    prevState.dispatchGroupSelections.forEach((dg) => {
                        if (dg.group.id == groupId) {
                            dg.selected = value;
                        }
                    });
                    prevState.status = {key: "ready"};
                    return prevState;
                },
                () => {
                    if (this.paginatedTable != null) {
                        this.paginatedTable.resetTable();
                    }
                },
            );
        }
    }

    private closeGroupSelectorControl() {
        this.setState((prevState: ResponseOptionClientMembershipsModalState) => {
            prevState.dispatchGroupSelections =
                prevState.dispatchGroupSelections.sort(
                    ResponseOptionUsersModal.compareGroupSelectors,
                );
            return prevState;
        });
    }

    private static groupToLabel(
        groupName: string,
        groupColor: string,
    ): JSX.Element {
        return (
            <Label
                content={groupName}
                style={{
                    backgroundColor: groupColor,
                    color: colors.getOverlayFontColor(groupColor),
                }}
            />
        );
    }

    render() {
        const {onDismiss, viewStatus} = this.props;
        const {status} = this.state;

        const header =
            viewStatus.key == "shown" ? (
                <Header style={{margin: 0}}>
                    {this.props.t("agency.responseOptions.membersHeader", {
                        replace: {optionName: viewStatus.responseOption.text},
                    })}
                </Header>
            ) : undefined;

        let includeGroupColorColumn = false;

        const clientMembershipsTableHeaderItems: HeaderDataItem[] = [
            {
                i18nKey: "agency.responseOptions.membersTable.clientName",
                width: "7",
                headerKey: "clientName",
            },
            {
                width: "2",
                headerKey: "hasResponseOption",
            },
        ];

        if (viewStatus.key == "shown" && viewStatus.dispatchGroups.length > 1) {
            // Splice inserts into the array. The second 0 is saying delete 0 items before inserting
            clientMembershipsTableHeaderItems.splice(0, 0, {
                i18nKey: "agency.responseOptions.membersTable.groupName",
                width: "6",
                headerKey: "groupName",
            });

            includeGroupColorColumn = true;
        }

        const selectedDispatchGroups = this.state.dispatchGroupSelections.filter(
            (g) => g.selected,
        );
        const dispatchGroupCount =
            viewStatus.key == "shown"
                ? this.props.t(
                "agency.responseOptions.membersTable.xGroupSelectorLabel",
                {count: selectedDispatchGroups.length},
                )
                : this.props.t(
                "agency.responseOptions.membersTable.groupSelectorPlaceholder",
                );

        const clientMembershipsZeroState =
            selectedDispatchGroups.length == 0 ? (
                <div
                    className="flexCenteredContainer"
                    style={{width: "100%", height: "100%"}}
                >
                    <ZeroStateView
                        header={this.props.t(
                            "agency.responseOptions.membersTable.zeroStateHeader",
                        )}
                    />
                </div>
            ) : undefined;

        const groupItems = this.state.dispatchGroupSelections.map((dg) => (
            <List.Item
                key={dg.group.id}
                onClick={() => this.onDispatchGroupChecked(dg.group.id, !dg.selected)}
                style={{cursor: "pointer", padding: 0}}
            >
                <List.Content
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        padding: "10px 25px",
                        borderLeftColor: dg.group.color,
                        borderLeftWidth: "7px",
                        borderLeftStyle: "solid",
                    }}
                >
                    <span className="truncate">{dg.group.name}</span>
                    <Checkbox
                        checked={dg.selected}
                        onChange={(e, d) =>
                            this.onDispatchGroupChecked(dg.group.id, d.checked || false)
                        }
                    />
                </List.Content>
            </List.Item>
        ));

        const groupSelector =
            this.state.dispatchGroupSelections.length > 1 ? (
                <Popup
                    position="bottom right"
                    onClose={() => this.closeGroupSelectorControl()}
                    content={
                        <div style={{width: "400px"}}>
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    margin: "1rem",
                                }}
                            >
                                <Button
                                    compact
                                    primary
                                    onClick={() => this.changeAllGroups(true)}
                                    style={{marginRight: "10px", width: "105px"}}
                                    content={this.props.t("general.selectAll")}
                                />
                                <Button
                                    compact
                                    negative
                                    onClick={() => this.changeAllGroups(false)}
                                    style={{marginLeft: "10px", width: "105px"}}
                                    content={this.props.t("general.removeAll")}
                                />
                            </div>
                            <Divider style={{marginBottom: 0}}/>
                            <List
                                divided
                                style={{maxHeight: "350px", overflowY: "auto", marginTop: 0}}
                            >
                                {groupItems}
                            </List>
                        </div>
                    }
                    style={{padding: 0}}
                    on="click"
                    trigger={
                        <Button icon style={{width: "220px"}} labelPosition="right">
                            <Icon name="chevron down"/>
                            {dispatchGroupCount}
                        </Button>
                    }
                />
            ) : undefined;

        const clientMembershipsTable = (
            <PaginatedTable
                {...this.props}
                ref={(r) => (this.paginatedTable = r)}
                uniqueKey="agency.responseOptions.membersTable"
                rightItem={null}
                fixed
                zeroStateView={clientMembershipsZeroState}
                style={{
                    padding: "30px 20px 0",
                    display: "flex",
                    height: "100%",
                    flexDirection: "column",
                    flex: 1,
                    minHeight: 0,
                }}
                loader={{
                    loadId: this.props.agencyId,
                    loadItems: this.loadItems.bind(this),
                }}
                headerDataItems={clientMembershipsTableHeaderItems}
                renderItem={(item: any) => {
                    const clientMembership = item as ResponseOptionClientMembership;
                    const groupCell = includeGroupColorColumn ? (
                        <Table.Cell>
                            {ResponseOptionUsersModal.groupToLabel(
                                clientMembership.groupName,
                                clientMembership.groupColor,
                            )}
                        </Table.Cell>
                    ) : undefined;

                    const disableToggle =
                        clientMembership.canRemove != null &&
                        clientMembership.canRemove == false;
                    const toggle =
                        disableToggle == true ? (
                            <Popup
                                position="right center"
                                content={this.props.t(
                                    "agency.responseOptions.membersTable.cannotToggle",
                                )}
                                trigger={
                                    <Radio
                                        toggle
                                        disabled
                                        className="bryxToggle"
                                        checked={clientMembership.hasResponseOption}
                                    />
                                }
                            />
                        ) : (
                            <Radio
                                toggle
                                className="bryxToggle"
                                onChange={(e, d) =>
                                    this.onResponseOptionToggle(
                                        clientMembership.groupId,
                                        clientMembership.clientId,
                                        d.checked || false,
                                    )
                                }
                                checked={clientMembership.hasResponseOption}
                            />
                        );

                    return (
                        <Table.Row key={clientMembership.id}>
                            {groupCell}
                            <Table.Cell>{clientMembership.clientName}</Table.Cell>
                            <Table.Cell textAlign="center">{toggle}</Table.Cell>
                        </Table.Row>
                    );
                }}
                defaultSorting={{
                    column: "clientName",
                    direction: "desc",
                }}
            />
        );

        let panel = null;
        if (status.key == "error") {
            panel = (
                <Message error style={{marginTop: "10px"}}>
                    {status.message}
                </Message>
            );
        } else if (status.key == "bulkResult") {
            const result = status.result;
            if (result.errorCount == 0) {
                panel = (
                    <Message
                        success
                        content={this.props.t(
                            `agency.responseOptions.membersTable.bulkResult.${status.actionType}.success`,
                            {count: result.successCount},
                        )}
                    />
                );
            } else {
                panel = (
                    <Message
                        warning
                        list={[
                            this.props.t(
                                `agency.responseOptions.membersTable.bulkResult.${status.actionType}.success`,
                                {count: result.successCount},
                            ),
                            this.props.t(
                                `agency.responseOptions.membersTable.bulkResult.${status.actionType}.failed`,
                                {count: result.errorCount},
                            ),
                        ]}
                    />
                );
            }
        }

        return (
            <Modal size="large" open={viewStatus.key == "shown"} onClose={onDismiss}>
                <Modal.Header>{header}</Modal.Header>
                <Modal.Content
                    style={{
                        display: "flex",
                        flex: 1,
                        height: "720px",
                        flexDirection: "column",
                        backgroundColor: "#FBFBFB",
                        minHeight: 0,
                    }}
                >
                    {groupSelector}
                    {clientMembershipsTable}
                    {panel}
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        positive
                        content={this.props.t("agency.responseOptions.membersTable.addAll")}
                        disabled={
                            this.state.activeSearch || selectedDispatchGroups.length == 0
                        }
                        loading={status.key == "loading" && status.actionType == "addAll"}
                        onClick={() => this.onClickEditAll(true)}
                    />
                    <Button
                        negative
                        content={this.props.t(
                            "agency.responseOptions.membersTable.removeAll",
                        )}
                        disabled={
                            this.state.activeSearch || selectedDispatchGroups.length == 0
                        }
                        loading={
                            status.key == "loading" && status.actionType == "removeAll"
                        }
                        onClick={() => this.onClickEditAll(false)}
                    />
                    <Button content={this.props.t("general.done")} onClick={onDismiss}/>
                </Modal.Actions>
            </Modal>
        );
    }
}

export default withContext(ResponseOptionUsersModal, "api", "local", "i18n");
