import * as React from "react";
import {RouteComponentProps} from "react-router";
import {Redirect, Route, Switch} from "react-router-dom";
import {Menu} from "semantic-ui-react";
import RouterMenuItem from "../../components/routerMenuItem";
import {AuthAgency} from "@bryxinc/lunch/models";
import {CreateJobTab} from "./createJobTab";
import BryxApi from "@bryxinc/lunch/utils/ManagementApi";

import {
    withContext,
    WithTranslation,
    WithLocal,
    WithApi,
} from "@bryxinc/lunch/context";

export interface JobsProps
    extends RouteComponentProps<any>,
        WithTranslation,
        WithLocal,
        WithApi<BryxApi> {
    selectedAgency: AuthAgency;
}

export class JobsPage extends React.Component<JobsProps, any> {
    render() {
        return (
            <div className="pageContent horizontalNavAndContent">
                <div id="horizontalNav" className="Component_HorizontalNav">
                    <Menu attached="top" tabular>
                        <RouterMenuItem exact to="/jobs/create">
                            {this.props.t("jobs.tabs.createJob")}
                        </RouterMenuItem>
                    </Menu>
                </div>

                <Switch>
                    <Route
                        exact
                        path="/jobs/create"
                        render={(props) => <CreateJobTab {...this.props} />}
                    />

                    <Redirect to="/jobs/create"/>
                </Switch>
            </div>
        );
    }
}

export default withContext(JobsPage, "api", "local", "i18n");
