import * as React from "react";
import {Message, Modal, Form, Button} from "semantic-ui-react";
import {BasicUserInfo, FullUser, AuthAgency} from "@bryxinc/lunch/models";

import {nullIfBlank} from "@bryxinc/lunch/utils/functions";

import {RouteComponentProps} from "react-router";
import BryxApi from "@bryxinc/lunch/utils/ManagementApi";
import {
    withContext,
    WithTranslation,
    WithLocal,
    WithApi,
} from "@bryxinc/lunch/context";

export type EditUserInfoModalViewStatus =
    | { key: "hidden" }
    | { key: "shown"; agency: AuthAgency; user: FullUser };

export interface EditUserInfoModalProps
    extends RouteComponentProps<any>,
        WithTranslation,
        WithLocal,
        WithApi<BryxApi> {
    viewStatus: EditUserInfoModalViewStatus;
    onSaveInfo: (info: BasicUserInfo) => void;
    onClose: () => void;
}

interface EditUserInfoModalState {
    status:
        | { key: "ready" }
        | { key: "loading" }
        | { key: "success" }
        | { key: "error"; message: string };
    basicInfo: BasicUserInfo;
}

type SubmissionStatus =
    | { key: "incomplete" }
    | { key: "ok"; difference: BasicUserInfo };

export class EditUserInfoModal extends React.Component<EditUserInfoModalProps,
    EditUserInfoModalState> {
    private static readonly blankBasicInfo: BasicUserInfo = {
        givenName: null,
        surname: null,
        commonName: null,
        phoneNumber: null,
    };

    constructor(props: EditUserInfoModalProps, context: any) {
        super(props, context);
        this.state = this.getInitialState(props);
    }

    private getInitialState(
        props: EditUserInfoModalProps,
    ): EditUserInfoModalState {
        return {
            status: {key: "ready"},
            basicInfo:
                props.viewStatus.key == "shown"
                    ? props.viewStatus.user.getBasicInfo()
                    : EditUserInfoModal.blankBasicInfo,
        };
    }

    componentWillReceiveProps(nextProps: EditUserInfoModalProps) {
        if (
            this.props.viewStatus.key == "hidden" &&
            nextProps.viewStatus.key == "shown"
        ) {
            this.setState(this.getInitialState(nextProps));
        }
    }

    private getSubmissionStatus(): SubmissionStatus {
        const {viewStatus} = this.props;
        const {basicInfo} = this.state;
        const infoHasEmpty =
            basicInfo.givenName == null ||
            basicInfo.surname == null ||
            basicInfo.commonName == null ||
            basicInfo.phoneNumber == null;
        if (viewStatus.key != "shown" || infoHasEmpty) {
            return {key: "incomplete"};
        }
        const basicInfoDifference =
            viewStatus.user.getBasicInfoDifference(basicInfo);
        const isDirty =
            basicInfoDifference.givenName != null ||
            basicInfoDifference.surname != null ||
            basicInfoDifference.commonName != null ||
            basicInfoDifference.phoneNumber != null;
        return isDirty
            ? {key: "ok", difference: basicInfoDifference}
            : {key: "incomplete"};
    }

    private onSave() {
        const {viewStatus} = this.props;
        const submissionStatus = this.getSubmissionStatus();
        if (submissionStatus.key != "ok" || viewStatus.key != "shown") {
            return;
        }
        this.setState({status: {key: "loading"}});
        this.props.api.updateUserInfo(
            viewStatus.user.id,
            submissionStatus.difference,
            (result) => {
                if (result.success == true) {
                    this.props.onSaveInfo(submissionStatus.difference);
                } else {
                    this.props.local.logWarn(
                        `Failed to update user info: ${
                            result.debugMessage || result.message
                        }`,
                    );
                    this.setState({status: {key: "error", message: result.message}});
                }
            },
        );
    }

    render() {
        const {viewStatus} = this.props;
        const {status, basicInfo} = this.state;
        return (
            <Modal open={viewStatus.key == "shown"} onClose={this.props.onClose}>
                <Modal.Header>
                    {this.props.t("members.users.editInfo.title")}
                </Modal.Header>
                <Modal.Content>
                    <Form>
                        <Form.Input
                            label={this.props.t("members.users.editInfo.givenName")}
                            autoComplete={false}
                            autoCorrect={false}
                            autoCapitalize={false}
                            spellCheck={false}
                            value={basicInfo.givenName}
                            onChange={(e, d) =>
                                this.setState((prevState) => {
                                    prevState.basicInfo.givenName = nullIfBlank(d.value);
                                    return prevState;
                                })
                            }
                        />
                        <Form.Input
                            label={this.props.t("members.users.editInfo.surname")}
                            autoComplete={false}
                            autoCorrect={false}
                            autoCapitalize={false}
                            spellCheck={false}
                            value={basicInfo.surname}
                            onChange={(e, d) =>
                                this.setState((prevState) => {
                                    prevState.basicInfo.surname = nullIfBlank(d.value);
                                    return prevState;
                                })
                            }
                        />
                        <Form.Input
                            label={this.props.t("members.users.editInfo.commonName")}
                            autoComplete={false}
                            autoCorrect={false}
                            autoCapitalize={false}
                            spellCheck={false}
                            value={basicInfo.commonName}
                            onChange={(e, d) =>
                                this.setState((prevState) => {
                                    prevState.basicInfo.commonName = nullIfBlank(d.value);
                                    return prevState;
                                })
                            }
                        />
                        <Form.Input
                            label={this.props.t("members.users.editInfo.phone")}
                            autoComplete={false}
                            autoCorrect={false}
                            autoCapitalize={false}
                            spellCheck={false}
                            value={basicInfo.phoneNumber || ""}
                            onChange={(e, d) =>
                                this.setState((prevState) => {
                                    prevState.basicInfo.phoneNumber = nullIfBlank(d.value);
                                    return prevState;
                                })
                            }
                        />
                    </Form>
                    {status.key == "error" ? (
                        <Message error content={status.message}/>
                    ) : null}
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        content={this.props.t("general.cancel")}
                        disabled={status.key == "loading"}
                        onClick={this.props.onClose}
                    />
                    <Button
                        primary
                        content={this.props.t("general.save")}
                        loading={status.key == "loading"}
                        disabled={this.getSubmissionStatus().key != "ok"}
                        onClick={this.onSave.bind(this)}
                    />
                </Modal.Actions>
            </Modal>
        );
    }
}

export default withContext(EditUserInfoModal, "api", "local", "i18n");
