import * as moment from "moment";
import * as React from "react";
import {RouteComponentProps} from "react-router";
import {Button, Dropdown, Icon, Popup, Table} from "semantic-ui-react";
import {PaginatedTable} from "../../components/paginatedTable";
import {
    ApiResult,
    AuthAgency,
    PageResult,
    SortingConfig,
    User,
    UserSortingOptions,
} from "@bryxinc/lunch/models";

import {AddUserModal} from "./addUserModal";
import {ViewUserSheet, ViewUserSheetViewStatus} from "./viewUserSheet";
import {ExportUsersModal} from "./exportUsersModal";

import BryxApi from "@bryxinc/lunch/utils/ManagementApi";
import {
    withContext,
    WithTranslation,
    WithLocal,
    WithApi,
} from "@bryxinc/lunch/context";

interface UsersTabProps
    extends RouteComponentProps<{ userId: string }>,
        WithTranslation,
        WithLocal,
        WithApi<BryxApi> {
    selectedAgency: AuthAgency;
}

interface UsersTabState {
    mode: { key: "view" } | { key: "select"; selectedUsers: User[] };
    overlay: { key: "none" } | { key: "exportUsers"; users: User[] };
    currentPage: User[];
}

export class UsersTab extends React.Component<UsersTabProps, UsersTabState> {
    private static readonly justNowDuration = 60;

    private paginatedTable: PaginatedTable | null = null;

    constructor(props: UsersTabProps, context: any) {
        super(props, context);
        this.state = {
            mode: {key: "view"},
            overlay: {key: "none"},
            currentPage: [],
        };
    }

    private loadItems(
        limit: number,
        activePage: number,
        searchString: string | null,
        sortConfig: SortingConfig<UserSortingOptions>,
        callback: (result: ApiResult<PageResult<User>>) => void,
    ) {
        this.props.api.getAgencyUsers(
            this.props.selectedAgency.id,
            limit,
            activePage,
            searchString,
            sortConfig,
            (result) => {
                if (result.success == true) {
                    this.setState({currentPage: result.value.items});
                }
                callback(result);
            },
        );
    }

    private openUser(userId: string) {
        this.props.history.push(`/members/users/${userId}`);
    }

    private onClickUser(user: User) {
        if (this.state.mode.key == "view") {
            this.props.history.push(`/members/users/${user.id}`);
        } else {
            this.setState((prevState) => {
                if (prevState.mode.key == "select") {
                    if (prevState.mode.selectedUsers.some((u) => u.id == user.id)) {
                        prevState.mode.selectedUsers = prevState.mode.selectedUsers.filter(
                            (u) => u.id != user.id,
                        );
                    } else {
                        prevState.mode.selectedUsers.push(user);
                    }
                }
                return prevState;
            });
        }
    }

    private onClickAdd() {
        this.props.history.push("/members/users/add");
    }

    private renderRightItem(): JSX.Element | null {
        const {currentPage, mode} = this.state;
        if (mode.key == "view") {
            return (
                <span>
          <Button
              color="green"
              type="button"
              onClick={this.onClickAdd.bind(this)}
              content={this.props.t("members.users.add")}
          />
          <Button
              color="blue"
              type="button"
              onClick={() =>
                  this.setState({
                      mode: {
                          key: "select",
                          selectedUsers: [],
                      },
                  })
              }
              content={this.props.t("general.select")}
          />
        </span>
            );
        } else {
            const allCurrentPageSelected = currentPage.every((pageUser) =>
                mode.selectedUsers.some((u) => u.id == pageUser.id),
            );
            return (
                <span>
          <Dropdown
              icon={null}
              disabled={mode.selectedUsers.length == 0}
              trigger={
                  <Button
                      disabled={mode.selectedUsers.length == 0}
                      color="blue"
                      content={this.props.t("members.users.actions", {
                          count: mode.selectedUsers.length,
                      })}
                  />
              }
          >
            <Dropdown.Menu
                style={{right: "auto", left: "auto", marginTop: "3px"}}
            >
              <Dropdown.Item
                  icon="mail"
                  text={this.props.t("members.users.sendEmail")}
                  target="_blank"
                  href={`mailto:${mode.selectedUsers
                      .map((u) => u.email)
                      .join(",")}`}
              />
              <Dropdown.Item
                  icon="copy"
                  text={this.props.t("members.users.export")}
                  onClick={() => {
                      if (this.state.mode.key == "select") {
                          this.setState({
                              overlay: {
                                  key: "exportUsers",
                                  users: this.state.mode.selectedUsers,
                              },
                          });
                      }
                  }}
              />
            </Dropdown.Menu>
          </Dropdown>
          <Button
              content={
                  allCurrentPageSelected
                      ? this.props.t("general.deselectAll")
                      : this.props.t("general.selectAll")
              }
              onClick={() =>
                  this.setState((prevState) => {
                      if (prevState.mode.key == "select") {
                          if (allCurrentPageSelected) {
                              prevState.mode.selectedUsers =
                                  prevState.mode.selectedUsers.filter(
                                      (u) =>
                                          !currentPage.some((pageUser) => pageUser.id == u.id),
                                  );
                          } else {
                              const selectedUsers = prevState.mode.selectedUsers;
                              for (const pageUser of currentPage) {
                                  if (!selectedUsers.some((u) => u.id == pageUser.id)) {
                                      prevState.mode.selectedUsers.push(pageUser);
                                  }
                              }
                          }
                      }
                      return prevState;
                  })
              }
          />
          <Button
              onClick={() => this.setState({mode: {key: "view"}})}
              content={this.props.t("general.cancel")}
          />
        </span>
            );
        }
    }

    render() {
        let viewUserViewStatus: ViewUserSheetViewStatus;
        let addUserOpen = false;
        if (this.props.match.params.userId == "add") {
            viewUserViewStatus = {key: "hidden"};
            addUserOpen = true;
        } else if (this.props.match.params.userId != null) {
            viewUserViewStatus = {
                key: "shown",
                userId: this.props.match.params.userId,
            };
        } else {
            viewUserViewStatus = {key: "hidden"};
        }

        return (
            <div className="underHorizNavContent" style={{display: "flex"}}>
                <PaginatedTable
                    {...this.props}
                    ref={(r) => (this.paginatedTable = r)}
                    className="underHorizNavContent tableUnderNavContent"
                    uniqueKey="members.users"
                    rightItem={this.renderRightItem()}
                    loader={{
                        loadId: this.props.selectedAgency.id,
                        loadItems: this.loadItems.bind(this),
                    }}
                    headerDataItems={[
                        {
                            i18nKey: "members.users.email",
                            headerKey: "email",
                            width: 4,
                        },
                        {
                            i18nKey: "members.users.commonName",
                            headerKey: "commonName",
                            width: 3,
                        },
                        {
                            i18nKey: "members.users.givenName",
                            headerKey: "givenName",
                            width: 3,
                        },
                        {
                            i18nKey: "members.users.surname",
                            headerKey: "surname",
                            width: 3,
                        },
                        {
                            i18nKey: "members.users.lastSeen",
                            headerKey: "lastSeen",
                            width: 2,
                        },
                        {
                            i18nKey: "members.users.isManager",
                            headerKey: "isManager",
                            width: 1,
                        },
                    ]}
                    renderItem={(item: any) => {
                        const user = item as User;
                        const mode = this.state.mode;
                        let lastSeen;
                        if (user.lastSeen != null) {
                            const duration = moment.duration(
                                moment(user.lastSeen).diff(new Date().getTime()),
                            );
                            lastSeen = (
                                <Popup
                                    inverted
                                    position="top center"
                                    content={user.lastSeen.toLocaleString()}
                                    trigger={
                                        <span>
                      {Math.abs(duration.asSeconds()) > UsersTab.justNowDuration
                          ? duration.humanize(true)
                          : this.props.t("members.users.justNow")}
                    </span>
                                    }
                                />
                            );
                        } else {
                            lastSeen = this.props.t("members.users.notSignedIn");
                        }
                        return (
                            <Table.Row
                                key={user.id}
                                active={
                                    mode.key == "select" &&
                                    mode.selectedUsers.some((u) => u.id == user.id)
                                }
                                style={{cursor: "pointer", height: "45px"}}
                                onClick={() => this.onClickUser(user)}
                            >
                                <Table.Cell>{user.email}</Table.Cell>
                                <Table.Cell>{user.commonName}</Table.Cell>
                                <Table.Cell>{user.givenName}</Table.Cell>
                                <Table.Cell>{user.surname}</Table.Cell>
                                <Table.Cell>{lastSeen}</Table.Cell>
                                <Table.Cell textAlign="center">
                                    {user.isManager ? (
                                        <Icon size="large" color="green" name="check"/>
                                    ) : null}
                                </Table.Cell>
                            </Table.Row>
                        );
                    }}
                    defaultSorting={{
                        column: "commonName",
                        direction: "desc",
                    }}
                />
                <ViewUserSheet
                    {...this.props}
                    selectedAgency={this.props.selectedAgency}
                    viewStatus={viewUserViewStatus}
                    onUpdateUser={() => {
                        if (this.paginatedTable != null) {
                            this.paginatedTable.reload();
                        }
                    }}
                    onDismiss={(requiresReload) => {
                        this.props.history.replace("/members/users");
                        if (requiresReload && this.paginatedTable != null) {
                            this.paginatedTable.reload();
                        }
                    }}
                />
                <AddUserModal
                    {...this.props}
                    open={addUserOpen}
                    onClose={() => this.props.history.replace("/members/users")}
                    selectedAgency={this.props.selectedAgency}
                    onSelectUser={(userId) => {
                        if (this.paginatedTable != null) {
                            this.paginatedTable.reload();
                        }
                        this.openUser(userId);
                    }}
                />
                <ExportUsersModal
                    {...this.props}
                    viewStatus={
                        this.state.overlay.key == "exportUsers"
                            ? {
                                key: "shown",
                                users: this.state.overlay.users,
                            }
                            : {
                                key: "hidden",
                            }
                    }
                    onClose={() =>
                        this.setState({
                            overlay: {key: "none"},
                            mode: {key: "view"},
                        })
                    }
                />
            </div>
        );
    }
}

export default withContext(UsersTab, "api", "local", "i18n");
