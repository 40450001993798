import * as React from "react";
import {Button, Message, Modal} from "semantic-ui-react";
import {FindMemberInput} from "../../components/findMemberInput";
import {AuthAgency, FindUser} from "@bryxinc/lunch/models";

import {RouteComponentProps} from "react-router";
import BryxApi from "@bryxinc/lunch/utils/ManagementApi";
import {
    withContext,
    WithTranslation,
    WithLocal,
    WithApi,
} from "@bryxinc/lunch/context";

interface AddMemberProps
    extends RouteComponentProps<any>,
        WithTranslation,
        WithLocal,
        WithApi<BryxApi> {
    viewStatus: { key: "hidden" } | { key: "shown" };
    mode: "dispatcher" | "scuManager";
    existingMembers: FindUser[];
    selectedAgency: AuthAgency;
    onAdded: (user: FindUser) => void;
    onCancel: () => void;
}

interface AddMemberState {
    selectedUser: FindUser | null;
    status:
        | { key: "ready" }
        | { key: "loading" }
        | { key: "error"; message: string };
}

export class AddMemberModal extends React.Component<AddMemberProps,
    AddMemberState> {
    constructor(props: AddMemberProps, context: any) {
        super(props, context);
        this.state = AddMemberModal.getDefaultState();
    }

    private static getDefaultState(): AddMemberState {
        return {
            selectedUser: null,
            status: {key: "ready"},
        };
    }

    componentWillReceiveProps(nextProps: AddMemberProps) {
        if (
            this.props.viewStatus.key == "hidden" &&
            nextProps.viewStatus.key == "shown"
        ) {
            this.setState(AddMemberModal.getDefaultState());
        }
    }

    private selectedMemberExists(): boolean {
        const {existingMembers} = this.props;
        const {selectedUser} = this.state;
        return (
            selectedUser != null &&
            existingMembers.some((m) => selectedUser.id == m.id)
        );
    }

    private submit() {
        const {selectedAgency, onAdded} = this.props;
        const {selectedUser} = this.state;
        if (selectedUser == null) {
            return;
        }
        this.setState({status: {key: "loading"}});
        if (this.props.mode == "dispatcher") {
            this.props.api.grantUserDispatch(
                selectedAgency.id,
                selectedUser.id,
                (result) => {
                    if (result.success) {
                        onAdded(selectedUser);
                    } else {
                        this.props.local.logWarn(
                            `Unable to grant user dispatch permission: ${
                                result.debugMessage || result.message
                            }`,
                        );
                        this.setState({status: {key: "error", message: result.message}});
                    }
                },
            );
        } else {
            this.props.api.grantUserSCUManagement(
                selectedAgency.id,
                selectedUser.id,
                (result) => {
                    if (result.success) {
                        onAdded(selectedUser);
                    } else {
                        this.props.local.logWarn(
                            `Unable to grant user scuManager permission: ${
                                result.debugMessage || result.message
                            }`,
                        );
                        this.setState({status: {key: "error", message: result.message}});
                    }
                },
            );
        }
    }

    render() {
        const {viewStatus, selectedAgency, onCancel, mode} = this.props;
        const {selectedUser, status} = this.state;
        const i18nKey = mode == "dispatcher" ? "monitoring" : "management";
        return (
            <Modal size="small" open={viewStatus.key == "shown"} onClose={onCancel}>
                <Modal.Header>
                    {this.props.t(`dispatch.${i18nKey}.addMemberModalTitle`)}
                </Modal.Header>
                <Modal.Content>
                    <Message
                        info
                        icon="help circle"
                        content={this.props.t(
                            `dispatch.${i18nKey}.addMemberDescription`,
                        )}
                    />
                    <FindMemberInput
                        {...this.props}
                        selectedAgency={selectedAgency}
                        onSelect={(u) => this.setState({selectedUser: u})}
                    />
                    {this.selectedMemberExists() ? (
                        <Message
                            negative
                            content={this.props.t(
                                `dispatch.${i18nKey}.userAlreadyHasAccess`,
                            )}
                        />
                    ) : null}
                    {status.key == "error" ? (
                        <Message negative content={status.message}/>
                    ) : null}
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        onClick={onCancel}
                        disabled={status.key == "loading"}
                        content={this.props.t("general.cancel")}
                    />
                    <Button
                        primary
                        disabled={selectedUser == null || this.selectedMemberExists()}
                        loading={status.key == "loading"}
                        content={this.props.t("general.add")}
                        onClick={() => this.submit()}
                    />
                </Modal.Actions>
            </Modal>
        );
    }
}

export default withContext(AddMemberModal, "api", "local", "i18n");
