import * as React from "react";
import Sound from "react-sound";
import {Button, Header, Loader, Message, Table} from "semantic-ui-react";
import {ZeroStateView} from "../../components/zeroStateView";
import {Agency, Stream} from "@bryxinc/lunch/models";
import {SharedSupportUtils} from "@bryxinc/lunch/utils/functions";
import {AgencyProps} from "./agencyPage";
import {withContext} from "@bryxinc/lunch/context";

interface StreamsProps extends AgencyProps {
    activeAgency: Agency;
}

interface StreamsState {
    status:
        | { key: "loading" }
        | { key: "error"; message: string }
        | {
        key: "ready";
        streams: Stream[];
        playStatus:
            | { key: "stopped" }
            | { key: "playing"; loading: boolean; stream: Stream };
    };
}

export class StreamsTab extends React.Component<StreamsProps, StreamsState> {
    constructor(props: StreamsProps, context: any) {
        super(props, context);

        this.state = {
            status: {key: "loading"},
        };
    }

    componentDidMount() {
        this.loadAgencyStreams();
    }

    private loadAgencyStreams() {
        this.props.api.getAgencyStreams(this.props.activeAgency.id, (result) => {
            if (result.success == true) {
                this.setState({
                    status: {
                        key: "ready",
                        streams: result.value,
                        playStatus: {key: "stopped"},
                    },
                });
            } else {
                this.setState({
                    status: {
                        key: "error",
                        message: result.message,
                    },
                });
            }
        });
    }

    private toggleStream(stream: Stream) {
        if (this.state.status.key != "ready") {
            return;
        }

        if (
            this.state.status.playStatus.key == "playing" &&
            this.state.status.playStatus.stream.id == stream.id
        ) {
            this.setState((prevState: StreamsState) => {
                if (prevState.status.key != "ready") {
                    return {status: {key: "loading"}};
                }

                prevState.status.playStatus = {key: "stopped"};
                return prevState;
            });
        } else {
            this.setState((prevState: StreamsState) => {
                if (prevState.status.key != "ready") {
                    return {status: {key: "loading"}};
                }

                prevState.status.playStatus = {
                    key: "playing",
                    loading: true,
                    stream: stream,
                };
                return prevState;
            });
        }
    }

    render() {
        if (this.state.status.key == "loading") {
            return (
                <div
                    className="underHorizNavContent"
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        padding: "40px 60px 40px 60px",
                    }}
                >
                    <Loader active/>
                </div>
            );
        }

        if (this.state.status.key == "error") {
            return (
                <div
                    className="underHorizNavContent"
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        padding: "40px 60px 40px 60px",
                    }}
                >
                    <Message negative content={this.state.status.message}/>
                </div>
            );
        }

        const audioElement =
            this.state.status.playStatus.key == "playing" ? (
                <Sound
                    url={this.state.status.playStatus.stream.url}
                    onPlaying={() => {
                        this.setState((prevState: StreamsState, props: StreamsProps) => {
                            if (prevState.status.key != "ready") {
                                return {status: {key: "loading"}};
                            }

                            if (prevState.status.playStatus.key == "playing") {
                                prevState.status.playStatus.loading = false;
                            }
                            return prevState;
                        });
                    }}
                    playStatus="PLAYING"
                />
            ) : undefined;

        const streamsData = this.state.status.streams.map((stream: Stream) => {
            if (this.state.status.key != "ready") {
                return;
            }

            const streamIsPlaying =
                this.state.status.playStatus.key == "playing" &&
                this.state.status.playStatus.stream.id == stream.id;
            return (
                <Table.Row key={stream.id}>
                    <Table.Cell>{stream.name}</Table.Cell>
                    <Table.Cell textAlign="right">
                        <Button
                            primary={!streamIsPlaying}
                            negative={streamIsPlaying}
                            style={{width: "100px"}}
                            content={
                                streamIsPlaying
                                    ? this.props.t("agency.streams.actions.pause")
                                    : this.props.t("agency.streams.actions.play")
                            }
                            onClick={() => this.toggleStream(stream)}
                            loading={
                                this.state.status.playStatus.key == "playing" &&
                                this.state.status.playStatus.loading &&
                                this.state.status.playStatus.stream.id == stream.id
                            }
                            disabled={
                                this.state.status.playStatus.key == "playing" &&
                                this.state.status.playStatus.loading
                            }
                            icon={streamIsPlaying ? "pause" : "play"}
                        />
                    </Table.Cell>
                </Table.Row>
            );
        });

        const streamsTable =
            this.state.status.streams.length > 0 ? (
                <Table striped style={{marginTop: 0}}>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell width={14}>
                                {this.props.t("agency.streams.nameLabel")}
                            </Table.HeaderCell>
                            <Table.HeaderCell width={2}/>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>{streamsData}</Table.Body>
                </Table>
            ) : (
                <div
                    className="flexCenteredContainer"
                    style={{height: "100%", width: "100%"}}
                >
                    <ZeroStateView
                        header={this.props.t("agency.streams.zeroStateHeader")}
                        subheader={
                            <span>
                <div style={{marginBottom: "10px"}}>
                  {this.props.t("agency.streams.zeroStateSubheader")}
                </div>
                <a
                    style={{cursor: "pointer"}}
                    onClick={() => SharedSupportUtils.onOpenSupport()}
                >
                  {this.props.t("agency.streams.contactSupportLinkHead")}
                </a>{" "}
                                {this.props.t("agency.streams.contactSupportLinkTail")}
              </span>
                        }
                    />
                </div>
            );

        return (
            <div className="underHorizNavContent tableUnderNavContent">
                <div id="tableActionDiv" style={{height: "38px"}}>
                    <Header as="h3" style={{marginBottom: 0, marginLeft: "5px"}}>
                        {this.props.t("agency.streams.xTotalItems", {
                            count: streamsData.length,
                        })}
                    </Header>
                </div>
                <div className="tableContainer" style={{marginBottom: "50px"}}>
                    {streamsTable || undefined}
                </div>
                {audioElement}
            </div>
        );
    }
}

export default withContext(StreamsTab, "api", "local", "i18n");
