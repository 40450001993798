import * as React from "react";
import {Button, Form, Message, Modal, Segment} from "semantic-ui-react";
import {InfoLabel} from "../../components/infoLabel";
import {
    AuthAgency,
    EditableField,
    FieldDiff,
    SCUAlert,
    SCUAlertAction,
    SCUAlertActionTemplate,
    SCUAlertActionType,
} from "@bryxinc/lunch/models";
import {
    arraysEqual,
    enumNames,
    nullIfBlank,
} from "@bryxinc/lunch/utils/functions";

import {RouteComponentProps} from "react-router";
import BryxApi from "@bryxinc/lunch/utils/ManagementApi";
import {
    withContext,
    WithTranslation,
    WithLocal,
    WithApi,
} from "@bryxinc/lunch/context";

export type CreateEditStatusNotificationModalViewStatus =
    | { key: "hidden" }
    | { key: "create" }
    | { key: "edit"; alert: SCUAlert };

interface CreateEditStatusNotificationModalProps
    extends RouteComponentProps<any>,
        WithTranslation,
        WithLocal,
        WithApi<BryxApi> {
    viewStatus: CreateEditStatusNotificationModalViewStatus;
    selectedAgency: AuthAgency;
    onClose: () => void;
    onComplete: (
        result:
            | { key: "onCreate"; newAlert: SCUAlert }
            | { key: "onEdit"; updatedAlert: SCUAlert },
    ) => void;
}

interface CreateEditStatusNotificationModalState {
    actionStatus:
        | { key: "ready" }
        | { key: "loading" }
        | { key: "error"; message: string };
    name: string | null;
    actionTemplates: SCUAlertActionTemplate[];
}

type SubmissionStatus =
    | { key: "incomplete" }
    | { key: "createOk"; name: string }
    | {
    key: "editOk";
    name: EditableField<string>;
    actions: EditableField<SCUAlertAction[]>;
};

export class CreateEditStatusNotificationModal extends React.Component<CreateEditStatusNotificationModalProps,
    CreateEditStatusNotificationModalState> {
    constructor(props: CreateEditStatusNotificationModalProps, context: any) {
        super(props, context);
        this.state = CreateEditStatusNotificationModal.getDefaultState(props);
    }

    componentWillReceiveProps(nextProps: CreateEditStatusNotificationModalProps) {
        if (
            this.props.viewStatus.key == "hidden" &&
            nextProps.viewStatus.key != "hidden"
        ) {
            this.setState(
                CreateEditStatusNotificationModal.getDefaultState(nextProps),
            );
        }
    }

    private static getDefaultState(
        props: CreateEditStatusNotificationModalProps,
    ): CreateEditStatusNotificationModalState {
        if (props.viewStatus.key == "hidden" || props.viewStatus.key == "create") {
            return {
                actionStatus: {key: "ready"},
                name: null,
                actionTemplates: [SCUAlertAction.getEmptyTemplate("email")],
            };
        } else {
            const {alert} = props.viewStatus;
            return {
                actionStatus: {key: "ready"},
                name: alert.name,
                actionTemplates: alert.actions.map((a) => a.getTemplate()),
            };
        }
    }

    private getSubmissionStatus(): SubmissionStatus {
        const {name, actionTemplates} = this.state;
        const {viewStatus} = this.props;
        if (
            viewStatus.key == "hidden" ||
            name == null ||
            actionTemplates.length == 0 ||
            actionTemplates.filter((t) => SCUAlertAction.fromTemplate(t) == null)
                .length > 0
        ) {
            return {key: "incomplete"};
        } else if (viewStatus.key == "create") {
            return {key: "createOk", name: name};
        } else {
            const {alert} = viewStatus;
            const diff = new FieldDiff();
            const status: SubmissionStatus = {
                key: "editOk",
                name: diff.compareEditable(alert.name, name),
                actions: diff.compareEditable(
                    alert.actions,
                    CreateEditStatusNotificationModal.templatesToActions(actionTemplates),
                    (l1, l2) => arraysEqual(l1, l2, (e1, e2) => e1.isSame(e2)),
                ),
            };
            return diff.dirty ? status : {key: "incomplete"};
        }
    }

    private static templatesToActions(
        actionTemplates: SCUAlertActionTemplate[],
    ): SCUAlertAction[] {
        return actionTemplates.reduce<SCUAlertAction[]>((acc, elem) => {
            const action = SCUAlertAction.fromTemplate(elem);
            if (action != null) {
                acc.push(action);
            }
            return acc;
        }, []);
    }

    private onCreate() {
        const submissionStatus = this.getSubmissionStatus();
        const {viewStatus} = this.props;
        if (viewStatus.key != "create" || submissionStatus.key != "createOk") {
            return;
        }
        const {name} = submissionStatus;
        const {actionTemplates} = this.state;
        const actions =
            CreateEditStatusNotificationModal.templatesToActions(actionTemplates);
        this.setState({actionStatus: {key: "loading"}});
        this.props.api.createAgencySCUAlert(
            this.props.selectedAgency.id,
            name,
            actions,
            (result) => {
                if (result.success) {
                    this.props.onComplete({key: "onCreate", newAlert: result.value});
                } else {
                    this.props.local.logWarn(
                        `Failed to create SCU alert: ${
                            result.debugMessage || result.message
                        }`,
                    );
                    this.setState({
                        actionStatus: {key: "error", message: result.message},
                    });
                }
            },
        );
    }

    private onEdit() {
        const submissionStatus = this.getSubmissionStatus();
        const {viewStatus} = this.props;
        if (viewStatus.key != "edit" || submissionStatus.key != "editOk") {
            return;
        }
        const {name, actions} = submissionStatus;
        this.setState({actionStatus: {key: "loading"}});
        this.props.api.editAgencySCUAlert(
            this.props.selectedAgency.id,
            viewStatus.alert.id,
            name,
            actions,
            (result) => {
                if (result.success) {
                    const newAlert = viewStatus.alert.copy();
                    if (name.key == "set") {
                        newAlert.name = name.value;
                    }
                    if (actions.key == "set") {
                        newAlert.actions = actions.value;
                    }
                    this.props.onComplete({key: "onEdit", updatedAlert: newAlert});
                } else {
                    this.props.local.logWarn(
                        `Failed to edit SCU alert: ${
                            result.debugMessage || result.message
                        }`,
                    );
                    this.setState({
                        actionStatus: {key: "error", message: result.message},
                    });
                }
            },
        );
    }

    private renderForm(): JSX.Element | null {
        const {name, actionTemplates, actionStatus} = this.state;
        const {viewStatus} = this.props;
        return (
            <div>
                <Form>
                    <Form.Input
                        autoFocus={viewStatus.key == "create"}
                        autoComplete="new-password"
                        label={this.props.t("stationAlerting.statusNotifications.name")}
                        placeholder={this.props.t(
                            "stationAlerting.statusNotifications.name",
                        )}
                        value={name || ""}
                        onChange={(e, d) => this.setState({name: nullIfBlank(d.value)})}
                    />
                    <Form.Field>
                        <InfoLabel
                            title={this.props.t(
                                "stationAlerting.statusNotifications.actions",
                            )}
                            content={this.props.t(
                                "stationAlerting.statusNotifications.actionsExplain",
                            )}
                        />
                    </Form.Field>
                </Form>
                {actionTemplates.map((template) => (
                    <Segment key={template.id}>
                        <Form>
                            <Form.Group inline style={{margin: 0}}>
                                <Form.Dropdown
                                    selection
                                    width="5"
                                    value={template.content.type}
                                    options={SCUAlertAction.actionTypes.map((actionType) => ({
                                        key: actionType,
                                        value: actionType,
                                        text: this.props.t(
                                            `stationAlerting.statusNotifications.actionTypes.${actionType}`,
                                        ),
                                    }))}
                                    onChange={(e, d) =>
                                        this.setState((prevState) => {
                                            const selection = d.value as SCUAlertActionType;
                                            const activeTemplate = prevState.actionTemplates.filter(
                                                (t) => t.id == template.id,
                                            )[0];
                                            if (activeTemplate != null) {
                                                activeTemplate.content =
                                                    SCUAlertAction.getEmptyTemplate(selection).content;
                                            }
                                            return prevState;
                                        })
                                    }
                                />
                                {template.content.type == "email" ? (
                                    <Form.Input
                                        width="10"
                                        placeholder={this.props.t(
                                            "stationAlerting.statusNotifications.email",
                                        )}
                                        value={template.content.email || ""}
                                        onChange={(e, d) =>
                                            this.setState((prevState) => {
                                                const activeTemplate = prevState.actionTemplates.filter(
                                                    (t) => t.id == template.id,
                                                )[0];
                                                if (
                                                    activeTemplate != null &&
                                                    activeTemplate.content.type == "email"
                                                ) {
                                                    activeTemplate.content.email = nullIfBlank(
                                                        d.value as string,
                                                    );
                                                }
                                                return prevState;
                                            })
                                        }
                                    />
                                ) : null}
                                {template.content.type == "phone" ? (
                                    <Form.Input
                                        width="10"
                                        placeholder={this.props.t(
                                            "stationAlerting.statusNotifications.phoneNumber",
                                        )}
                                        value={template.content.number || ""}
                                        onChange={(e, d) =>
                                            this.setState((prevState) => {
                                                const activeTemplate = prevState.actionTemplates.filter(
                                                    (t) => t.id == template.id,
                                                )[0];
                                                if (
                                                    activeTemplate != null &&
                                                    activeTemplate.content.type == "phone"
                                                ) {
                                                    activeTemplate.content.number = nullIfBlank(
                                                        d.value as string,
                                                    );
                                                }
                                                return prevState;
                                            })
                                        }
                                    />
                                ) : null}
                                {template.content.type == "sms" ? (
                                    <Form.Input
                                        width="10"
                                        placeholder={this.props.t(
                                            "stationAlerting.statusNotifications.phoneNumber",
                                        )}
                                        value={template.content.number || ""}
                                        onChange={(e, d) =>
                                            this.setState((prevState) => {
                                                const activeTemplate = prevState.actionTemplates.filter(
                                                    (t) => t.id == template.id,
                                                )[0];
                                                if (
                                                    activeTemplate != null &&
                                                    activeTemplate.content.type == "sms"
                                                ) {
                                                    activeTemplate.content.number = nullIfBlank(
                                                        d.value as string,
                                                    );
                                                }
                                                return prevState;
                                            })
                                        }
                                    />
                                ) : null}
                                {template.content.type == "webhook" ? (
                                    <Form.Input
                                        width="10"
                                        placeholder={this.props.t(
                                            "stationAlerting.statusNotifications.url",
                                        )}
                                        value={template.content.url || ""}
                                        onChange={(e, d) =>
                                            this.setState((prevState) => {
                                                const activeTemplate = prevState.actionTemplates.filter(
                                                    (t) => t.id == template.id,
                                                )[0];
                                                if (
                                                    activeTemplate != null &&
                                                    activeTemplate.content.type == "webhook"
                                                ) {
                                                    activeTemplate.content.url = nullIfBlank(
                                                        d.value as string,
                                                    );
                                                }
                                                return prevState;
                                            })
                                        }
                                    />
                                ) : null}
                                <Form.Button
                                    width="1"
                                    negative
                                    type="button"
                                    icon="trash"
                                    onClick={() =>
                                        this.setState((prevState) => ({
                                            actionTemplates: prevState.actionTemplates.filter(
                                                (t) => t.id != template.id,
                                            ),
                                        }))
                                    }
                                />
                            </Form.Group>
                        </Form>
                    </Segment>
                ))}
                <div style={{display: "flex", justifyContent: "flex-end"}}>
                    <Button
                        positive
                        icon="plus"
                        onClick={() =>
                            this.setState((prevState) => ({
                                actionTemplates: prevState.actionTemplates.concat([
                                    SCUAlertAction.getEmptyTemplate("email"),
                                ]),
                            }))
                        }
                    />
                </div>
                {actionStatus.key == "error" ? (
                    <Message negative content={actionStatus.message}/>
                ) : null}
            </div>
        );
    }

    render() {
        const {actionStatus} = this.state;
        const {viewStatus, onClose} = this.props;
        let primaryButtonText = null;
        if (viewStatus.key == "create") {
            primaryButtonText = this.props.t("general.create");
        } else if (viewStatus.key == "edit") {
            primaryButtonText = this.props.t("general.update");
        }
        return (
            <Modal
                size="small"
                open={viewStatus.key != "hidden"}
                closeOnEscape={false}
                onClose={onClose}
            >
                <Modal.Header>
                    {this.props.t(
                        `stationAlerting.statusNotifications.${
                            viewStatus.key == "create" ? "createTitle" : "editTitle"
                        }`,
                    )}
                </Modal.Header>
                <Modal.Content>{this.renderForm()}</Modal.Content>
                <Modal.Actions>
                    <Button
                        content={this.props.t("general.cancel")}
                        disabled={actionStatus.key == "loading"}
                        onClick={onClose}
                    />
                    <Button
                        primary
                        content={primaryButtonText}
                        loading={actionStatus.key == "loading"}
                        disabled={this.getSubmissionStatus().key == "incomplete"}
                        onClick={() => {
                            if (viewStatus.key == "create") {
                                this.onCreate();
                            } else if (viewStatus.key == "edit") {
                                this.onEdit();
                            }
                        }}
                    />
                </Modal.Actions>
            </Modal>
        );
    }
}

export default withContext(
    CreateEditStatusNotificationModal,
    "api",
    "local",
    "i18n",
);
