import * as React from 'react';
import {Icon} from 'semantic-ui-react';
import CopyToClipboard from "react-copy-to-clipboard";

interface CopyButtonProps extends React.HTMLProps<CopyButton> {
    copyText: string;
    style?: React.CSSProperties;
}

interface CopyButtonState {
    showCopied: boolean;
}

export class CopyButton extends React.Component<CopyButtonProps, CopyButtonState> {
    private static readonly showCopiedTimeout = 2.0 * 1000;

    constructor(props: any, context: any) {
        super(props, context);
        this.state = {
            showCopied: false,
        };
    }

    onCopy() {
        this.setState({showCopied: true});
        setTimeout(() => {
            this.setState({showCopied: false});
        }, CopyButton.showCopiedTimeout);
    }

    render() {
        return (
            <CopyToClipboard text={this.props.copyText} onCopy={this.onCopy.bind(this)}>
                <Icon name={this.state.showCopied ? "check" : "copy"}
                      link
                      circular
                      inverted
                      style={this.props.style}/>
            </CopyToClipboard>
        );
    }
}
