import * as moment from "moment";
import * as React from "react";
import {
    Button,
    Checkbox,
    Form,
    Icon,
    Input,
    List,
    Menu,
    Message,
    Modal,
} from "semantic-ui-react";
import {InfoLabel} from "../../components/infoLabel";
import {
    Station,
    AuthAgency,
    BasicAgencyGroup,
    BasicStationBoard,
    FullStationBoard,
    presetToInactiveZoom,
    StationBoardActiveLayer,
    StationBoardActiveZoom,
    StationBoardBaseLayer,
    StationBoardInactiveLayer,
    StationBoardInactiveZoomPreset,
    StationBoardTurnoutStartCondition,
    RequestUnit,
} from "@bryxinc/lunch/models";
import {
    arraysEqual,
    enumItems,
    formatDuration,
    nullIfBlank,
} from "@bryxinc/lunch/utils/functions";

import {RouteComponentProps} from "react-router";
import BryxApi from "@bryxinc/lunch/utils/ManagementApi";
import {
    withContext,
    WithTranslation,
    WithLocal,
    WithApi,
} from "@bryxinc/lunch/context";

export type CreateEditStationBoardModalViewStatus =
    | { key: "hidden" }
    | { key: "create" }
    | { key: "edit"; board: FullStationBoard };

interface CreateEditStationBoardModalProps
    extends RouteComponentProps<any>,
        WithTranslation,
        WithLocal,
        WithApi<BryxApi> {
    viewStatus: CreateEditStationBoardModalViewStatus;
    selectedAgency: AuthAgency;
    onAddStation?: () => void;
    onClose: () => void;
    onComplete: (updatedBoard: BasicStationBoard) => void;
}

interface CreateEditStationBoardModalState {
    actionStatus:
        | { key: "ready" }
        | { key: "loading" }
        | { key: "error"; message: string };
    unitsStatus:
        | { key: "loading" }
        | { key: "ready"; units: RequestUnit[] }
        | { key: "error"; message: string };
    groupsStatus:
        | { key: "loading" }
        | { key: "ready"; groups: BasicAgencyGroup[] }
        | { key: "error"; message: string };
    stationsStatus:
        | { key: "loading" }
        | { key: "ready"; stations: Station[] }
        | { key: "error"; message: string };

    tab: "basic" | "turnout" | "advanced";

    name: string | null;
    selectedUnitIds: string[];
    selectedStationId: string | null;
    selectedMessagingGroupId: string | null;
    selectedActiveLayers: StationBoardActiveLayer[];
    selectedInactiveLayers: StationBoardInactiveLayer[];
    selectedBaseLayer: StationBoardBaseLayer;
    activeZoom: StationBoardActiveZoom;
    inactiveZoom: StationBoardInactiveZoomPreset;
    activeTimeHours: number | null;
    activeTimeMinutes: number | null;
    activeTimeSeconds: number | null;
    messageDurationDays: number | null;
    messageDurationHours: number | null;
    messageDurationMinutes: number | null;

    userInactiveTime: number;
    userExpireTime: number;
    apparatusInactiveTime: number;
    apparatusExpireTime: number;

    dayStartMin: number;
    dayEndMin: number;

    dayTurnoutTimeMinutes: number | null;
    dayTurnoutTimeSeconds: number | null;
    nightTurnoutTimeMinutes: number | null;
    nightTurnoutTimeSeconds: number | null;

    enableRedTime: boolean;
    redTimeMinutes: number | null;
    redTimeSeconds: number | null;

    enableBlinkTime: boolean;
    blinkTimeMinutes: number | null;
    blinkTimeSeconds: number | null;

    turnoutStart: StationBoardTurnoutStartCondition;
}

type SubmissionStatus =
    | {
    key: "incomplete";
}
    | {
    key: "createOk";
    name: string;
    selectedUnitIds: string[];
    selectedStationId: string;
    selectedMessagingGroupId: string | null;
    selectedActiveLayers: StationBoardActiveLayer[];
    selectedInactiveLayers: StationBoardInactiveLayer[];
    selectedBaseLayer: StationBoardBaseLayer;
    activeZoom: StationBoardActiveZoom;
    inactiveZoom: StationBoardInactiveZoomPreset;
    activeTime: number;
    messageDuration: number;
    userInactiveTime: number;
    userExpireTime: number;
    apparatusInactiveTime: number;
    apparatusExpireTime: number;
    dayStartMin: number;
    dayEndMin: number;
    dayTurnoutTime: number;
    nightTurnoutTime: number;
    redTime: number | null;
    blinkTime: number | null;
    turnoutStart: StationBoardTurnoutStartCondition;
}
    | {
    key: "editOk";
    name: string | null;
    selectedUnitIds: string[] | null;
    selectedStationId: string | null;
    selectedMessagingGroupId: string | null;
    selectedActiveLayers: StationBoardActiveLayer[] | null;
    selectedInactiveLayers: StationBoardInactiveLayer[] | null;
    selectedBaseLayer: StationBoardBaseLayer | null;
    activeZoom: StationBoardActiveZoom | null;
    inactiveZoom: StationBoardInactiveZoomPreset | null;
    activeTime: number | null;
    messageDuration: number | null;
    userInactiveTime: number | null;
    userExpireTime: number | null;
    apparatusInactiveTime: number | null;
    apparatusExpireTime: number | null;
    dayStartMin: number | null;
    dayEndMin: number | null;
    dayTurnoutTime: number | null;
    nightTurnoutTime: number | null;
    redTime: number | null;
    blinkTime: number | null;
    turnoutStart: StationBoardTurnoutStartCondition | null;
};

export class CreateEditStationBoardModal extends React.Component<CreateEditStationBoardModalProps,
    CreateEditStationBoardModalState> {
    private static readonly sliderStepFactor = 5;

    constructor(props: CreateEditStationBoardModalProps, context: any) {
        super(props, context);
        this.state = CreateEditStationBoardModal.getDefaultState(props);
    }

    componentDidMount() {
        if (this.props.viewStatus.key != "hidden") {
            this.loadSelections();
        }
    }

    componentWillReceiveProps(nextProps: CreateEditStationBoardModalProps) {
        if (
            this.props.viewStatus.key == "hidden" &&
            nextProps.viewStatus.key != "hidden"
        ) {
            this.setState(
                CreateEditStationBoardModal.getDefaultState(nextProps),
                () => this.loadSelections(),
            );
        }
    }

    private loadSelections() {
        this.props.api.getAllUnits(this.props.selectedAgency.id, (result) => {
            if (result.success == true) {
                this.setState((prevState: CreateEditStationBoardModalState, props) => {
                    prevState.unitsStatus = {key: "ready", units: result.value};
                    if (props.viewStatus.key == "create") {
                        prevState.selectedUnitIds =
                            result.value.length == 1 ? [result.value[0].id] : [];
                    }
                    return prevState;
                });
            } else {
                this.props.local.logWarn(
                    `Error getting units for agency: ${this.props.selectedAgency.id}: ${result.debugMessage}`,
                );
                this.setState({
                    unitsStatus: {
                        key: "error",
                        message: result.message,
                    },
                });
            }
        });
        this.props.api.getAllGroupsByType(
            this.props.selectedAgency.id,
            null,
            (result) => {
                if (result.success == true) {
                    this.setState({
                        groupsStatus: {key: "ready", groups: result.value},
                    });
                } else {
                    this.props.local.logWarn(
                        `Error getting all groups for agency: ${this.props.selectedAgency.id}: ${result.debugMessage}`,
                    );
                    this.setState({
                        groupsStatus: {
                            key: "error",
                            message: result.message,
                        },
                    });
                }
            },
        );
        this.props.api.getAgency(this.props.selectedAgency.id, (result) => {
            if (result.success == true) {
                this.setState((prevState: CreateEditStationBoardModalState, props) => {
                    const stations = result.value.stations;
                    prevState.stationsStatus = {key: "ready", stations: stations};
                    if (props.viewStatus.key == "create") {
                        prevState.selectedStationId =
                            stations.length == 1 ? stations[0].id : null;
                    }
                    return prevState;
                });
                this.setState({
                    stationsStatus: {key: "ready", stations: result.value.stations},
                });
            } else {
                this.props.local.logWarn(
                    `Error getting stations for agency: ${this.props.selectedAgency.id}: ${result.debugMessage}`,
                );
                this.setState({
                    stationsStatus: {
                        key: "error",
                        message: result.message,
                    },
                });
            }
        });
    }

    private static getDefaultState(
        props: CreateEditStationBoardModalProps,
    ): CreateEditStationBoardModalState {
        if (props.viewStatus.key == "hidden" || props.viewStatus.key == "create") {
            return {
                actionStatus: {key: "ready"},
                unitsStatus: {key: "loading"},
                groupsStatus: {key: "loading"},
                stationsStatus: {key: "loading"},
                tab: "basic",
                name: null,
                selectedUnitIds: [],
                selectedStationId: null,
                selectedMessagingGroupId: null,
                selectedActiveLayers: enumItems<StationBoardActiveLayer>(
                    StationBoardActiveLayer,
                ).map((i) => i.value),
                selectedInactiveLayers: enumItems<StationBoardInactiveLayer>(
                    StationBoardInactiveLayer,
                ).map((i) => i.value),
                selectedBaseLayer: StationBoardBaseLayer.streets,
                activeZoom: StationBoardActiveZoom.jobLocation,
                inactiveZoom: StationBoardInactiveZoomPreset.boundary,
                activeTimeHours: 0,
                activeTimeMinutes: 2,
                activeTimeSeconds: 0,
                messageDurationDays: 0,
                messageDurationHours: 1,
                messageDurationMinutes: 0,
                userInactiveTime: 30 * 60,
                userExpireTime: 60 * 60,
                apparatusInactiveTime: 30 * 60,
                apparatusExpireTime: 60 * 60,
                dayStartMin: 480,
                dayEndMin: 1060,
                dayTurnoutTimeMinutes: 2,
                dayTurnoutTimeSeconds: 30,
                nightTurnoutTimeMinutes: 2,
                nightTurnoutTimeSeconds: 30,
                enableRedTime: false,
                redTimeMinutes: null,
                redTimeSeconds: null,
                enableBlinkTime: false,
                blinkTimeMinutes: null,
                blinkTimeSeconds: null,
                turnoutStart: StationBoardTurnoutStartCondition.jobTime,
            };
        } else {
            const {board} = props.viewStatus;
            const inactiveZoomPreset = board.inactiveZoomPreset();
            const activeTimeMoment = moment.duration(board.activeTime, "seconds");
            const messageDurationMoment = moment.duration(
                board.messageDuration,
                "seconds",
            );
            return {
                actionStatus: {key: "ready"},
                unitsStatus: {key: "loading"},
                groupsStatus: {key: "loading"},
                stationsStatus: {key: "loading"},
                tab: "basic",
                name: board.name,
                selectedUnitIds: board.units.map((u) => u.id),
                selectedStationId: board.station != null ? board.station.id : null,
                selectedMessagingGroupId:
                    board.messagingGroup != null ? board.messagingGroup.id : null,
                selectedActiveLayers: board.activeLayers,
                selectedInactiveLayers: board.inactiveLayers,
                selectedBaseLayer: board.baseLayer,
                activeZoom: board.activeZoom,
                inactiveZoom:
                    inactiveZoomPreset != null
                        ? inactiveZoomPreset
                        : StationBoardInactiveZoomPreset.boundary,
                activeTimeHours: activeTimeMoment.hours(),
                activeTimeMinutes: activeTimeMoment.minutes(),
                activeTimeSeconds: activeTimeMoment.seconds(),
                messageDurationDays: messageDurationMoment.days(),
                messageDurationHours: messageDurationMoment.hours(),
                messageDurationMinutes: messageDurationMoment.minutes(),
                userInactiveTime: board.userInactiveTime,
                userExpireTime: board.userExpireTime,
                apparatusInactiveTime: board.apparatusInactiveTime,
                apparatusExpireTime: board.apparatusExpireTime,
                dayStartMin: board.dayStartMin,
                dayEndMin: board.dayEndMin,
                dayTurnoutTimeMinutes: Math.floor(board.dayTurnoutTime / 60),
                dayTurnoutTimeSeconds: board.dayTurnoutTime % 60,
                nightTurnoutTimeMinutes: Math.floor(board.nightTurnoutTime / 60),
                nightTurnoutTimeSeconds: board.nightTurnoutTime % 60,
                enableRedTime: board.redTime != null,
                redTimeMinutes:
                    board.redTime != null ? Math.floor(board.redTime / 60) : null,
                redTimeSeconds: board.redTime != null ? board.redTime % 60 : null,
                enableBlinkTime: board.blinkTime != null,
                blinkTimeMinutes:
                    board.blinkTime != null ? Math.floor(board.blinkTime / 60) : null,
                blinkTimeSeconds: board.blinkTime != null ? board.blinkTime % 60 : null,
                turnoutStart: board.turnoutStart,
            };
        }
    }

    private static makeSecondsDuration(
        days: number,
        hours: number,
        minutes: number,
        seconds = 0,
    ): number {
        return (
            moment.duration(days, "days").asSeconds() +
            moment.duration(hours, "hours").asSeconds() +
            moment.duration(minutes, "minutes").asSeconds() +
            seconds
        );
    }

    private validatePinTimes(): boolean {
        const {
            userInactiveTime,
            userExpireTime,
            apparatusInactiveTime,
            apparatusExpireTime,
        } = this.state;
        return (
            userInactiveTime >= 0 &&
            userExpireTime >= 0 &&
            apparatusInactiveTime >= 0 &&
            apparatusExpireTime >= 0 &&
            userInactiveTime < userExpireTime &&
            apparatusInactiveTime < apparatusExpireTime
        );
    }

    private getSubmissionStatus(): SubmissionStatus {
        const {
            name,
            selectedUnitIds,
            selectedStationId,
            selectedMessagingGroupId,
            selectedActiveLayers,
            selectedInactiveLayers,
            selectedBaseLayer,
            activeZoom,
            inactiveZoom,
            activeTimeHours,
            activeTimeMinutes,
            activeTimeSeconds,
            messageDurationDays,
            messageDurationHours,
            messageDurationMinutes,
            userInactiveTime,
            userExpireTime,
            apparatusInactiveTime,
            apparatusExpireTime,
            dayStartMin,
            dayEndMin,
            dayTurnoutTimeMinutes,
            dayTurnoutTimeSeconds,
            nightTurnoutTimeMinutes,
            nightTurnoutTimeSeconds,
            enableRedTime,
            redTimeMinutes,
            redTimeSeconds,
            enableBlinkTime,
            blinkTimeMinutes,
            blinkTimeSeconds,
            turnoutStart,
        } = this.state;
        const {viewStatus} = this.props;
        if (
            messageDurationDays == null ||
            messageDurationHours == null ||
            messageDurationMinutes == null ||
            activeTimeHours == null ||
            activeTimeMinutes == null ||
            activeTimeSeconds == null ||
            dayTurnoutTimeMinutes == null ||
            dayTurnoutTimeSeconds == null ||
            nightTurnoutTimeMinutes == null ||
            nightTurnoutTimeSeconds == null ||
            (enableRedTime && (redTimeMinutes == null || redTimeSeconds == null)) ||
            (enableBlinkTime &&
                (blinkTimeMinutes == null || blinkTimeSeconds == null))
        ) {
            return {key: "incomplete"};
        }
        const activeDuration = CreateEditStationBoardModal.makeSecondsDuration(
            0,
            activeTimeHours,
            activeTimeMinutes,
            activeTimeSeconds,
        );
        const messageDuration = CreateEditStationBoardModal.makeSecondsDuration(
            messageDurationDays,
            messageDurationHours,
            messageDurationMinutes,
        );
        const dayTurnoutTime = CreateEditStationBoardModal.makeSecondsDuration(
            0,
            0,
            dayTurnoutTimeMinutes,
            dayTurnoutTimeSeconds,
        );
        const nightTurnoutTime = CreateEditStationBoardModal.makeSecondsDuration(
            0,
            0,
            nightTurnoutTimeMinutes,
            nightTurnoutTimeSeconds,
        );
        const redTime =
            enableRedTime && redTimeMinutes != null && redTimeSeconds != null
                ? CreateEditStationBoardModal.makeSecondsDuration(
                0,
                0,
                redTimeMinutes,
                redTimeSeconds,
                )
                : null;
        const blinkTime =
            enableBlinkTime && blinkTimeMinutes != null && blinkTimeSeconds != null
                ? CreateEditStationBoardModal.makeSecondsDuration(
                0,
                0,
                blinkTimeMinutes,
                blinkTimeSeconds,
                )
                : null;
        if (
            viewStatus.key == "hidden" ||
            name == null ||
            selectedUnitIds.length == 0 ||
            selectedStationId == null ||
            activeDuration <= 0 ||
            messageDuration <= 0 ||
            !this.validatePinTimes()
        ) {
            return {key: "incomplete"};
        } else if (viewStatus.key == "create") {
            return {
                key: "createOk",
                name: name,
                selectedUnitIds,
                selectedStationId,
                selectedMessagingGroupId,
                selectedActiveLayers,
                selectedInactiveLayers,
                selectedBaseLayer,
                activeZoom,
                inactiveZoom,
                activeTime: activeDuration,
                messageDuration,
                userInactiveTime,
                userExpireTime,
                apparatusInactiveTime,
                apparatusExpireTime,
                dayStartMin,
                dayEndMin,
                dayTurnoutTime,
                nightTurnoutTime,
                redTime,
                blinkTime,
                turnoutStart,
            };
        } else {
            const {board} = viewStatus;
            const setName = board.name != name ? name : null;
            const setUnitIds = !arraysEqual(
                board.units.map((u) => u.id),
                selectedUnitIds,
            )
                ? selectedUnitIds
                : null;
            const setStationId =
                board.station.id != selectedStationId ? selectedStationId : null;
            const existingMessagingGroupId =
                board.messagingGroup != null ? board.messagingGroup.id : null;
            const setSelectedMessagingGroupId =
                existingMessagingGroupId != selectedMessagingGroupId
                    ? selectedMessagingGroupId != null
                    ? selectedMessagingGroupId
                    : "none"
                    : null;
            const setSelectedActiveLayers = !arraysEqual(
                board.activeLayers,
                selectedActiveLayers,
            )
                ? selectedActiveLayers
                : null;
            const setSelectedInactiveLayers = !arraysEqual(
                board.inactiveLayers,
                selectedInactiveLayers,
            )
                ? selectedInactiveLayers
                : null;
            const setSelectedBaseLayer =
                board.baseLayer != selectedBaseLayer ? selectedBaseLayer : null;
            const setActiveZoom = board.activeZoom != activeZoom ? activeZoom : null;
            const setInactiveZoom =
                board.inactiveZoomPreset() != inactiveZoom ? inactiveZoom : null;
            const setActiveTime =
                board.activeTime != activeDuration ? activeDuration : null;
            const setMessageDuration =
                board.messageDuration != messageDuration ? messageDuration : null;
            const setUserInactiveTime =
                board.userInactiveTime != userInactiveTime ? userInactiveTime : null;
            const setUserExpireTime =
                board.userExpireTime != userExpireTime ? userExpireTime : null;
            const setApparatusInactiveTime =
                board.apparatusInactiveTime != apparatusInactiveTime
                    ? apparatusInactiveTime
                    : null;
            const setApparatusExpireTime =
                board.apparatusExpireTime != apparatusExpireTime
                    ? apparatusExpireTime
                    : null;
            const setDayStartTime =
                board.dayStartMin != dayStartMin ? dayStartMin : null;
            const setDayEndTime = board.dayEndMin != dayEndMin ? dayEndMin : null;
            const setDayTurnoutTime =
                board.dayTurnoutTime != dayTurnoutTime ? dayTurnoutTime : null;
            const setNightTurnoutTime =
                board.nightTurnoutTime != nightTurnoutTime ? nightTurnoutTime : null;
            const hasSetRedTime = board.redTime != redTime;
            const hasSetBlinkTime = board.blinkTime != blinkTime;
            const setTurnoutStart =
                board.turnoutStart != turnoutStart ? turnoutStart : null;
            const isDirty =
                setName != null ||
                setUnitIds != null ||
                setStationId != null ||
                setSelectedMessagingGroupId != null ||
                setSelectedActiveLayers != null ||
                setSelectedInactiveLayers != null ||
                setSelectedBaseLayer != null ||
                setActiveZoom != null ||
                setInactiveZoom != null ||
                setActiveTime != null ||
                setMessageDuration != null ||
                setUserInactiveTime != null ||
                setUserExpireTime != null ||
                setApparatusInactiveTime != null ||
                setApparatusExpireTime != null ||
                setDayStartTime != null ||
                setDayEndTime != null ||
                setDayTurnoutTime != null ||
                setNightTurnoutTime != null ||
                hasSetRedTime ||
                hasSetBlinkTime ||
                setTurnoutStart != null;
            return isDirty
                ? {
                    key: "editOk",
                    name: setName,
                    selectedUnitIds: setUnitIds,
                    selectedStationId: setStationId,
                    selectedMessagingGroupId: setSelectedMessagingGroupId,
                    selectedActiveLayers: setSelectedActiveLayers,
                    selectedInactiveLayers: setSelectedInactiveLayers,
                    selectedBaseLayer: setSelectedBaseLayer,
                    activeZoom: setActiveZoom,
                    inactiveZoom: setInactiveZoom,
                    activeTime: setActiveTime,
                    messageDuration: setMessageDuration,
                    userInactiveTime: setUserInactiveTime,
                    userExpireTime: setUserExpireTime,
                    apparatusInactiveTime: setApparatusInactiveTime,
                    apparatusExpireTime: setApparatusExpireTime,
                    dayStartMin: setDayStartTime,
                    dayEndMin: setDayEndTime,
                    dayTurnoutTime: setDayTurnoutTime,
                    nightTurnoutTime: setNightTurnoutTime,
                    redTime: enableRedTime ? redTime : -1,
                    blinkTime: enableBlinkTime ? blinkTime : -1,
                    turnoutStart: setTurnoutStart,
                }
                : {key: "incomplete"};
        }
    }

    private onCreate() {
        const submissionStatus = this.getSubmissionStatus();
        const {viewStatus} = this.props;
        if (viewStatus.key != "create" || submissionStatus.key != "createOk") {
            return;
        }
        const {
            name,
            selectedUnitIds,
            selectedStationId,
            selectedMessagingGroupId,
            selectedActiveLayers,
            selectedInactiveLayers,
            selectedBaseLayer,
            activeZoom,
            inactiveZoom,
            activeTime,
            messageDuration,
            userInactiveTime,
            userExpireTime,
            apparatusInactiveTime,
            apparatusExpireTime,
            dayStartMin,
            dayEndMin,
            dayTurnoutTime,
            nightTurnoutTime,
            redTime,
            blinkTime,
            turnoutStart,
        } = submissionStatus;
        this.setState({actionStatus: {key: "loading"}});
        this.props.api.createStationBoard(
            this.props.selectedAgency.id,
            name,
            selectedUnitIds,
            selectedStationId,
            selectedMessagingGroupId,
            selectedActiveLayers,
            selectedInactiveLayers,
            selectedBaseLayer,
            activeZoom,
            presetToInactiveZoom(inactiveZoom),
            activeTime,
            messageDuration,
            userInactiveTime,
            userExpireTime,
            apparatusInactiveTime,
            apparatusExpireTime,
            dayStartMin,
            dayEndMin,
            dayTurnoutTime,
            nightTurnoutTime,
            redTime,
            blinkTime,
            turnoutStart,
            (result) => {
                if (result.success == true) {
                    this.props.onComplete(result.value);
                } else {
                    this.props.local.logWarn(
                        `Failed to create station board in agency: ${
                            result.debugMessage || result.message
                        }`,
                    );
                    this.setState({
                        actionStatus: {key: "error", message: result.message},
                    });
                }
            },
        );
    }

    private onEdit() {
        const submissionStatus = this.getSubmissionStatus();
        const {viewStatus} = this.props;
        if (viewStatus.key != "edit" || submissionStatus.key != "editOk") {
            return;
        }
        const {
            name,
            selectedUnitIds,
            selectedStationId,
            selectedMessagingGroupId,
            selectedActiveLayers,
            selectedInactiveLayers,
            selectedBaseLayer,
            activeZoom,
            inactiveZoom,
            activeTime,
            messageDuration,
            userInactiveTime,
            userExpireTime,
            apparatusInactiveTime,
            apparatusExpireTime,
            dayStartMin,
            dayEndMin,
            dayTurnoutTime,
            nightTurnoutTime,
            redTime,
            blinkTime,
            turnoutStart,
        } = submissionStatus;
        this.setState({actionStatus: {key: "loading"}});
        this.props.api.editStationBoard(
            this.props.selectedAgency.id,
            viewStatus.board.id,
            name,
            selectedUnitIds,
            selectedStationId,
            selectedMessagingGroupId,
            selectedActiveLayers,
            selectedInactiveLayers,
            selectedBaseLayer,
            activeZoom,
            inactiveZoom != null ? presetToInactiveZoom(inactiveZoom) : null,
            activeTime,
            messageDuration,
            userInactiveTime,
            userExpireTime,
            apparatusInactiveTime,
            apparatusExpireTime,
            dayStartMin,
            dayEndMin,
            dayTurnoutTime,
            nightTurnoutTime,
            redTime,
            blinkTime,
            turnoutStart,
            (result) => {
                if (result.success == true) {
                    this.props.onComplete(result.value);
                } else {
                    this.props.local.logWarn(
                        `Failed to edit station board in agency: ${
                            result.debugMessage || result.message
                        }`,
                    );
                    this.setState({
                        actionStatus: {key: "error", message: result.message},
                    });
                }
            },
        );
    }

    private renderLocationTimeSlider<K extends keyof CreateEditStationBoardModalState,
        >(key: K): React.ReactNode {
        const state = this.state;
        const time = state[key] as number;
        return (
            <Form.Field>
                <InfoLabel
                    title={this.props.t(`stationBoards.addModal.${key}`)}
                    content={this.props.t(`stationBoards.addModal.${key}Explain`)}
                />
                <Form.Group inline>
                    <label style={{width: "85px", fontWeight: "initial"}}>
                        {formatDuration(moment.duration(time / 60, "minutes"))}
                    </label>
                    <Input
                        type="range"
                        min={1800 / 60 / CreateEditStationBoardModal.sliderStepFactor}
                        max={86400 / 60 / CreateEditStationBoardModal.sliderStepFactor}
                        value={(
                            time /
                            60 /
                            CreateEditStationBoardModal.sliderStepFactor
                        ).toString()}
                        onChange={(e, d) => {
                            this.setState((_) => {
                                return {
                                    ...state,
                                    [key]:
                                    parseInt(d.value, 10) *
                                    60 *
                                    CreateEditStationBoardModal.sliderStepFactor,
                                };
                            });
                        }}
                    />
                </Form.Group>
            </Form.Field>
        );
    }

    private renderDaySlider<K extends keyof CreateEditStationBoardModalState>(
        key: K,
        disabled: boolean,
    ): React.ReactNode {
        const state = this.state;
        const time = state[key] as number;
        const dateWrappedTime = new Date();
        dateWrappedTime.setHours(Math.floor(time / 60));
        dateWrappedTime.setMinutes(time % 60);
        return (
            <Form.Field>
                <Form.Group inline>
                    <label style={{width: "85px", fontWeight: "initial"}}>
                        {moment(dateWrappedTime).format("hh:mm a")}
                    </label>
                    <Input
                        disabled={disabled}
                        type="range"
                        min={0}
                        max={1440 / CreateEditStationBoardModal.sliderStepFactor}
                        value={(
                            time / CreateEditStationBoardModal.sliderStepFactor
                        ).toString()}
                        onChange={(e, d) => {
                            this.setState((_) => {
                                return {
                                    ...state,
                                    [key]:
                                    Math.round(
                                        (parseInt(d.value, 10) *
                                            CreateEditStationBoardModal.sliderStepFactor) /
                                        5,
                                    ) * 5,
                                };
                            });
                        }}
                    />
                </Form.Group>
            </Form.Field>
        );
    }

    private renderContent(): React.ReactNode {
        const {
            actionStatus,
            unitsStatus,
            stationsStatus,
            groupsStatus,
            tab,
            name,
            selectedUnitIds,
            selectedStationId,
            selectedMessagingGroupId,
            selectedActiveLayers,
            selectedInactiveLayers,
            selectedBaseLayer,
            activeZoom,
            inactiveZoom,
            activeTimeHours,
            activeTimeMinutes,
            activeTimeSeconds,
            messageDurationDays,
            messageDurationHours,
            messageDurationMinutes,
            dayTurnoutTimeMinutes,
            dayTurnoutTimeSeconds,
            nightTurnoutTimeMinutes,
            nightTurnoutTimeSeconds,
            enableRedTime,
            redTimeMinutes,
            redTimeSeconds,
            enableBlinkTime,
            blinkTimeMinutes,
            blinkTimeSeconds,
            turnoutStart,
        } = this.state;
        const isZonedBoard =
            this.props.viewStatus.key == "edit" &&
            this.props.viewStatus.board.isZoned;

        if (stationsStatus.key == "ready" && stationsStatus.stations.length == 0) {
            return (
                <div style={{minHeight: "200px"}}>
                    <Message
                        info
                        icon="info circle"
                        header={this.props.t("stationBoards.addModal.noStationsHeader")}
                        content={
                            <List bulleted>
                                <List.Item>
                                    {this.props.t("stationBoards.addModal.noStationsBody")}
                                </List.Item>
                                <List.Item>
                                    <a
                                        key="link"
                                        onClick={() => {
                                            if (this.props.onAddStation != null) {
                                                this.props.onAddStation();
                                            }
                                        }}
                                    >
                                        {this.props.t("stationBoards.addModal.noStationsLinkText")}
                                    </a>
                                </List.Item>
                            </List>
                        }
                    />
                </div>
            );
        }

        const unitOptions =
            this.state.unitsStatus.key == "ready"
                ? this.state.unitsStatus.units.map((u) => ({
                    text: u.name,
                    value: u.id,
                }))
                : [];
        const messagingGroupOptions =
            this.state.groupsStatus.key == "ready"
                ? [{text: this.props.t("general.none"), value: "none"}].concat(
                this.state.groupsStatus.groups.map((g) => ({
                    text: g.name,
                    value: g.id,
                })),
                )
                : [];
        const stationOptions =
            this.state.stationsStatus.key == "ready"
                ? this.state.stationsStatus.stations.map((s) => ({
                    text: s.name,
                    value: s.id,
                }))
                : [];
        return (
            <div>
                {isZonedBoard ? (
                    <Message
                        icon="warning"
                        warning
                        header={this.props.t("stationBoards.addModal.zonedBoard")}
                        content={this.props.t("stationBoards.addModal.zonedBoardExplain")}
                        style={{width: "98%", margin: "1%"}}
                    />
                ) : null}
                <Menu pointing secondary style={{marginTop: "1rem", marginBottom: 0}}>
                    <Menu.Item
                        name={this.props.t("stationBoards.addModal.basic")}
                        active={tab == "basic"}
                        onClick={() => this.setState({tab: "basic"})}
                    />
                    <Menu.Item
                        name={this.props.t("stationBoards.addModal.turnout")}
                        active={tab == "turnout"}
                        onClick={() => this.setState({tab: "turnout"})}
                    />
                    <Menu.Item
                        name={this.props.t("stationBoards.addModal.advanced")}
                        active={tab == "advanced"}
                        onClick={() => this.setState({tab: "advanced"})}
                    />
                </Menu>
                <Form style={{padding: "1.5rem"}}>
                    {tab == "basic" ? (
                        <Form.Input
                            autoFocus={this.props.viewStatus.key == "create"}
                            label={
                                <InfoLabel
                                    title={this.props.t("stationBoards.name")}
                                    content={this.props.t("stationBoards.nameExplain")}
                                />
                            }
                            placeholder={this.props.t("stationBoards.name")}
                            value={name || ""}
                            onChange={(e, d) => this.setState({name: nullIfBlank(d.value)})}
                        />
                    ) : null}
                    {tab == "basic" ? (
                        <Form.Dropdown
                            placeholder={this.props.t("stationBoards.addModal.activeLayers")}
                            label={
                                <InfoLabel
                                    title={this.props.t("stationBoards.addModal.activeLayers")}
                                    content={this.props.t(
                                        "stationBoards.addModal.activeLayersExplain",
                                    )}
                                />
                            }
                            search
                            multiple
                            selection
                            value={selectedActiveLayers.map(
                                (l) => StationBoardActiveLayer[l],
                            )}
                            noResultsMessage={this.props.t(
                                "stationBoards.addModal.noOtherLayers",
                            )}
                            options={enumItems(StationBoardActiveLayer).map((item) => ({
                                key: item.name,
                                value: item.name,
                                text: this.props.t(
                                    `stationBoards.addModal.activeLayerNames.${item.name}`,
                                ),
                            }))}
                            onChange={(e, d) => {
                                const newLayerNames = d.value as string[];
                                this.setState({
                                    selectedActiveLayers: newLayerNames.map(
                                        (n) => (StationBoardActiveLayer as any)[n],
                                    ),
                                });
                            }}
                        />
                    ) : null}
                    {tab == "basic" ? (
                        <Form.Dropdown
                            placeholder={this.props.t(
                                "stationBoards.addModal.inactiveLayers",
                            )}
                            label={
                                <InfoLabel
                                    title={this.props.t("stationBoards.addModal.inactiveLayers")}
                                    content={this.props.t(
                                        "stationBoards.addModal.inactiveLayersExplain",
                                    )}
                                />
                            }
                            search
                            multiple
                            selection
                            value={selectedInactiveLayers.map(
                                (l) => StationBoardInactiveLayer[l],
                            )}
                            noResultsMessage={this.props.t(
                                "stationBoards.addModal.noOtherLayers",
                            )}
                            options={enumItems(StationBoardInactiveLayer).map((item) => ({
                                key: item.name,
                                value: item.name,
                                text: this.props.t(
                                    `stationBoards.addModal.inactiveLayerNames.${item.name}`,
                                ),
                            }))}
                            onChange={(e, d) => {
                                const newLayerNames = d.value as string[];
                                this.setState({
                                    selectedInactiveLayers: newLayerNames.map(
                                        (n) => (StationBoardInactiveLayer as any)[n],
                                    ),
                                });
                            }}
                        />
                    ) : null}
                    {tab == "basic" ? (
                        <Form.Dropdown
                            disabled={isZonedBoard}
                            search
                            multiple
                            selection
                            label={
                                <InfoLabel
                                    title={this.props.t("stationBoards.addModal.dispatchGroups")}
                                    content={this.props.t(
                                        "stationBoards.addModal.dispatchGroupsExplain",
                                    )}
                                />
                            }
                            placeholder={
                                unitsStatus.key == "loading"
                                    ? this.props.t("general.loading")
                                    : this.props.t("stationBoards.addModal.dispatchGroups")
                            }
                            noResultsMessage={this.props.t(
                                "stationBoards.addModal.noOtherDispatchGroups",
                            )}
                            options={unitOptions}
                            value={selectedUnitIds}
                            loading={unitsStatus.key == "loading"}
                            onChange={(e, d) =>
                                this.setState({
                                    selectedUnitIds: d.value as string[],
                                })
                            }
                        />
                    ) : null}
                    {tab == "basic" ? (
                        <Form.Dropdown
                            search
                            selection
                            label={
                                <InfoLabel
                                    title={this.props.t("stationBoards.addModal.messagingGroup")}
                                    content={this.props.t(
                                        "stationBoards.addModal.messagingGroupExplain",
                                    )}
                                />
                            }
                            placeholder={
                                groupsStatus.key == "loading"
                                    ? this.props.t("general.loading")
                                    : this.props.t("stationBoards.addModal.messagingGroup")
                            }
                            options={messagingGroupOptions}
                            value={selectedMessagingGroupId || "none"}
                            loading={groupsStatus.key == "loading"}
                            onChange={(e, d) =>
                                this.setState({
                                    selectedMessagingGroupId:
                                        d.value != "none" ? (d.value as string) : null,
                                })
                            }
                        />
                    ) : null}
                    {tab == "basic" ? (
                        <Form.Dropdown
                            disabled={isZonedBoard}
                            search
                            selection
                            label={
                                <InfoLabel
                                    title={this.props.t("stationBoards.addModal.station")}
                                    content={this.props.t(
                                        "stationBoards.addModal.stationExplain",
                                    )}
                                />
                            }
                            placeholder={
                                stationsStatus.key == "loading"
                                    ? this.props.t("general.loading")
                                    : this.props.t("stationBoards.addModal.station")
                            }
                            options={stationOptions}
                            value={selectedStationId || undefined}
                            loading={stationsStatus.key == "loading"}
                            onChange={(e, d) =>
                                this.setState({
                                    selectedStationId: d.value as string,
                                })
                            }
                        />
                    ) : null}
                    {/* TURNOUT */}
                    {tab == "turnout" ? (
                        <Form.Field>
                            <InfoLabel
                                title={this.props.t("stationBoards.addModal.dayTurnoutTime")}
                                content={this.props.t(
                                    "stationBoards.addModal.dayTurnoutTimeExplain",
                                )}
                            />
                        </Form.Field>
                    ) : null}
                    {tab == "turnout" ? (
                        <Form.Group>
                            <Form.Input
                                disabled={isZonedBoard}
                                type="number"
                                label={this.props.t("general.minutes")}
                                value={
                                    dayTurnoutTimeMinutes != null ? dayTurnoutTimeMinutes : ""
                                }
                                onChange={(e, d) => {
                                    const newValue = parseInt(d.value, 10);
                                    this.setState({
                                        dayTurnoutTimeMinutes: !isNaN(newValue)
                                            ? Math.max(Math.min(newValue, 59), 0)
                                            : null,
                                    });
                                }}
                            />
                            <Form.Input
                                disabled={isZonedBoard}
                                type="number"
                                label={this.props.t("general.seconds")}
                                value={
                                    dayTurnoutTimeSeconds != null ? dayTurnoutTimeSeconds : ""
                                }
                                onChange={(e, d) => {
                                    const newValue = parseInt(d.value, 10);
                                    this.setState({
                                        dayTurnoutTimeSeconds: !isNaN(newValue)
                                            ? Math.max(Math.min(newValue, 59), 0)
                                            : null,
                                    });
                                }}
                            />
                        </Form.Group>
                    ) : null}
                    {tab == "turnout" ? (
                        <Form.Field>
                            <InfoLabel
                                title={this.props.t("stationBoards.addModal.nightTurnoutTime")}
                                content={this.props.t(
                                    "stationBoards.addModal.nightTurnoutTimeExplain",
                                )}
                            />
                        </Form.Field>
                    ) : null}
                    {tab == "turnout" ? (
                        <Form.Group>
                            <Form.Input
                                disabled={isZonedBoard}
                                type="number"
                                label={this.props.t("general.minutes")}
                                value={
                                    nightTurnoutTimeMinutes != null ? nightTurnoutTimeMinutes : ""
                                }
                                onChange={(e, d) => {
                                    const newValue = parseInt(d.value, 10);
                                    this.setState({
                                        nightTurnoutTimeMinutes: !isNaN(newValue)
                                            ? Math.max(Math.min(newValue, 59), 0)
                                            : null,
                                    });
                                }}
                            />
                            <Form.Input
                                disabled={isZonedBoard}
                                type="number"
                                label={this.props.t("general.seconds")}
                                value={
                                    nightTurnoutTimeSeconds != null ? nightTurnoutTimeSeconds : ""
                                }
                                onChange={(e, d) => {
                                    const newValue = parseInt(d.value, 10);
                                    this.setState({
                                        nightTurnoutTimeSeconds: !isNaN(newValue)
                                            ? Math.max(Math.min(newValue, 59), 0)
                                            : null,
                                    });
                                }}
                            />
                        </Form.Group>
                    ) : null}
                    {tab == "turnout" ? (
                        <Form.Field>
                            <InfoLabel
                                title={this.props.t("stationBoards.addModal.redTime")}
                                content={this.props.t("stationBoards.addModal.redTimeExplain")}
                            />
                        </Form.Field>
                    ) : null}
                    {tab == "turnout" ? (
                        <Form.Field>
                            <Checkbox
                                checked={enableRedTime}
                                onChange={(_, {checked}) => {
                                    const newEnableRedTime = checked === true;
                                    const newRedTimeMinutes = newEnableRedTime ? 0 : null;
                                    const newRedTimeSeconds = newEnableRedTime ? 30 : null;
                                    this.setState({
                                        enableRedTime: newEnableRedTime,
                                        redTimeMinutes: newRedTimeMinutes,
                                        redTimeSeconds: newRedTimeSeconds,
                                    });
                                }}
                                label={this.props.t("general.enable")}
                            />
                        </Form.Field>
                    ) : null}
                    {tab == "turnout" && enableRedTime ? (
                        <Form.Group>
                            <Form.Input
                                type="number"
                                label={this.props.t("general.minutes")}
                                value={redTimeMinutes != null ? redTimeMinutes : ""}
                                onChange={(e, d) => {
                                    const newValue = parseInt(d.value, 10);
                                    this.setState({
                                        redTimeMinutes: !isNaN(newValue)
                                            ? Math.max(Math.min(newValue, 59), 0)
                                            : null,
                                    });
                                }}
                            />
                            <Form.Input
                                type="number"
                                label={this.props.t("general.seconds")}
                                value={redTimeSeconds != null ? redTimeSeconds : ""}
                                onChange={(e, d) => {
                                    const newValue = parseInt(d.value, 10);
                                    this.setState({
                                        redTimeSeconds: !isNaN(newValue)
                                            ? Math.max(Math.min(newValue, 59), 0)
                                            : null,
                                    });
                                }}
                            />
                        </Form.Group>
                    ) : null}
                    {tab == "turnout" ? (
                        <Form.Field>
                            <InfoLabel
                                title={this.props.t("stationBoards.addModal.blinkTime")}
                                content={this.props.t(
                                    "stationBoards.addModal.blinkTimeExplain",
                                )}
                            />
                        </Form.Field>
                    ) : null}
                    {tab == "turnout" ? (
                        <Form.Field>
                            <Checkbox
                                checked={enableBlinkTime}
                                onChange={(_, {checked}) => {
                                    const newEnableBlinkTime = checked === true;
                                    const newBlinkTimeMinutes = newEnableBlinkTime ? 0 : null;
                                    const newBlinkTimeSeconds = newEnableBlinkTime ? 15 : null;
                                    this.setState({
                                        enableBlinkTime: newEnableBlinkTime,
                                        blinkTimeMinutes: newBlinkTimeMinutes,
                                        blinkTimeSeconds: newBlinkTimeSeconds,
                                    });
                                }}
                                label={this.props.t("general.enable")}
                            />
                        </Form.Field>
                    ) : null}
                    {tab == "turnout" && enableBlinkTime ? (
                        <Form.Group>
                            <Form.Input
                                type="number"
                                label={this.props.t("general.minutes")}
                                value={blinkTimeMinutes != null ? blinkTimeMinutes : ""}
                                onChange={(e, d) => {
                                    const newValue = parseInt(d.value, 10);
                                    this.setState({
                                        blinkTimeMinutes: !isNaN(newValue)
                                            ? Math.max(Math.min(newValue, 59), 0)
                                            : null,
                                    });
                                }}
                            />
                            <Form.Input
                                type="number"
                                label={this.props.t("general.seconds")}
                                value={blinkTimeSeconds != null ? blinkTimeSeconds : ""}
                                onChange={(e, d) => {
                                    const newValue = parseInt(d.value, 10);
                                    this.setState({
                                        blinkTimeSeconds: !isNaN(newValue)
                                            ? Math.max(Math.min(newValue, 59), 0)
                                            : null,
                                    });
                                }}
                            />
                        </Form.Group>
                    ) : null}
                    {tab == "turnout" ? (
                        <Form.Field>
                            <InfoLabel
                                title={this.props.t("stationBoards.addModal.dayStart")}
                                content={this.props.t("stationBoards.addModal.dayStartExplain")}
                            />
                        </Form.Field>
                    ) : null}
                    {tab == "turnout"
                        ? this.renderDaySlider("dayStartMin", isZonedBoard)
                        : null}
                    {tab == "turnout" ? (
                        <Form.Field>
                            <InfoLabel
                                title={this.props.t("stationBoards.addModal.dayEnd")}
                                content={this.props.t("stationBoards.addModal.dayEndExplain")}
                            />
                        </Form.Field>
                    ) : null}
                    {tab == "turnout"
                        ? this.renderDaySlider("dayEndMin", isZonedBoard)
                        : null}
                    {tab == "turnout" ? (
                        <Form.Dropdown
                            selection
                            label={
                                <InfoLabel
                                    title={this.props.t("stationBoards.addModal.startCondition")}
                                    content={this.props.t(
                                        "stationBoards.addModal.startConditionExplain",
                                    )}
                                />
                            }
                            placeholder={
                                stationsStatus.key == "loading"
                                    ? this.props.t("general.loading")
                                    : this.props.t("stationBoards.addModal.startCondition")
                            }
                            options={enumItems(StationBoardTurnoutStartCondition).map(
                                (item) => ({
                                    key: item.name,
                                    value: item.name,
                                    text: this.props.t(
                                        `stationBoards.addModal.startConditions.${item.name}`,
                                    ),
                                }),
                            )}
                            value={StationBoardTurnoutStartCondition[turnoutStart]}
                            onChange={(e, d) =>
                                this.setState({
                                    selectedBaseLayer: (StationBoardTurnoutStartCondition as any)[
                                        d.value as string
                                        ],
                                })
                            }
                        />
                    ) : null}
                    {/* /TURNOUT */}
                    {tab == "advanced" ? (
                        <Form.Dropdown
                            selection
                            label={
                                <InfoLabel
                                    title={this.props.t("stationBoards.addModal.baseLayer")}
                                    content={this.props.t(
                                        "stationBoards.addModal.baseLayerExplain",
                                    )}
                                />
                            }
                            placeholder={this.props.t("stationBoards.addModal.baseLayer")}
                            options={enumItems(StationBoardBaseLayer).map((item) => ({
                                key: item.name,
                                value: item.name,
                                text: this.props.t(
                                    `stationBoards.addModal.baseLayerNames.${item.name}`,
                                ),
                            }))}
                            value={StationBoardBaseLayer[selectedBaseLayer]}
                            onChange={(e, d) =>
                                this.setState({
                                    selectedBaseLayer: (StationBoardBaseLayer as any)[
                                        d.value as string
                                        ],
                                })
                            }
                        />
                    ) : null}
                    {tab == "advanced" ? (
                        <Form.Dropdown
                            selection
                            label={
                                <InfoLabel
                                    title={this.props.t("stationBoards.addModal.activeZoom")}
                                    content={this.props.t(
                                        "stationBoards.addModal.activeZoomExplain",
                                    )}
                                />
                            }
                            placeholder={this.props.t("stationBoards.addModal.activeZoom")}
                            options={enumItems(StationBoardActiveZoom).map((item) => ({
                                key: item.name,
                                value: item.name,
                                text: this.props.t(
                                    `stationBoards.addModal.activeZoomNames.${item.name}`,
                                ),
                            }))}
                            value={StationBoardActiveZoom[activeZoom]}
                            onChange={(e, d) =>
                                this.setState({
                                    activeZoom: (StationBoardActiveZoom as any)[
                                        d.value as string
                                        ],
                                })
                            }
                        />
                    ) : null}
                    {tab == "advanced" ? (
                        <Form.Dropdown
                            selection
                            label={
                                <InfoLabel
                                    title={this.props.t("stationBoards.addModal.inactiveZoom")}
                                    content={this.props.t(
                                        "stationBoards.addModal.inactiveZoomExplain",
                                    )}
                                />
                            }
                            placeholder={this.props.t("stationBoards.addModal.inactiveZoom")}
                            options={enumItems(StationBoardInactiveZoomPreset).map(
                                (item) => ({
                                    key: item.name,
                                    value: item.name,
                                    text: this.props.t(
                                        `stationBoards.addModal.inactiveZoomNames.${item.name}`,
                                    ),
                                }),
                            )}
                            value={StationBoardInactiveZoomPreset[inactiveZoom]}
                            onChange={(e, d) =>
                                this.setState({
                                    inactiveZoom: (StationBoardInactiveZoomPreset as any)[
                                        d.value as string
                                        ],
                                })
                            }
                        />
                    ) : null}
                    {tab == "advanced" ? (
                        <Form.Field>
                            <InfoLabel
                                title={this.props.t("stationBoards.addModal.activeTime")}
                                content={this.props.t(
                                    "stationBoards.addModal.activeTimeExplain",
                                )}
                            />
                        </Form.Field>
                    ) : null}
                    {tab == "advanced" ? (
                        <Form.Group>
                            <Form.Input
                                type="number"
                                label={this.props.t("general.hours")}
                                value={activeTimeHours != null ? activeTimeHours : ""}
                                onChange={(e, d) => {
                                    const newValue = parseInt(d.value, 10);
                                    this.setState({
                                        activeTimeHours: !isNaN(newValue)
                                            ? Math.max(Math.min(newValue, 23), 0)
                                            : null,
                                    });
                                }}
                            />
                            <Form.Input
                                type="number"
                                label={this.props.t("general.minutes")}
                                value={activeTimeMinutes != null ? activeTimeMinutes : ""}
                                onChange={(e, d) => {
                                    const newValue = parseInt(d.value, 10);
                                    this.setState({
                                        activeTimeMinutes: !isNaN(newValue)
                                            ? Math.max(Math.min(newValue, 59), 0)
                                            : null,
                                    });
                                }}
                            />
                            <Form.Input
                                type="number"
                                label={this.props.t("general.seconds")}
                                value={activeTimeSeconds != null ? activeTimeSeconds : ""}
                                onChange={(e, d) => {
                                    const newValue = parseInt(d.value, 10);
                                    this.setState({
                                        activeTimeSeconds: !isNaN(newValue)
                                            ? Math.max(Math.min(newValue, 59), 0)
                                            : null,
                                    });
                                }}
                            />
                        </Form.Group>
                    ) : null}
                    {tab == "advanced" ? (
                        <Form.Field>
                            <InfoLabel
                                title={this.props.t("stationBoards.addModal.messageDuration")}
                                content={this.props.t(
                                    "stationBoards.addModal.messageDurationExplain",
                                )}
                            />
                        </Form.Field>
                    ) : null}
                    {tab == "advanced" ? (
                        <Form.Group>
                            <Form.Input
                                type="number"
                                label={this.props.t("general.days")}
                                value={messageDurationDays != null ? messageDurationDays : ""}
                                onChange={(e, d) => {
                                    const newValue = parseInt(d.value, 10);
                                    this.setState({
                                        messageDurationDays: !isNaN(newValue)
                                            ? Math.max(Math.min(newValue, 30), 0)
                                            : null,
                                    });
                                }}
                            />
                            <Form.Input
                                type="number"
                                label={this.props.t("general.hours")}
                                value={messageDurationHours != null ? messageDurationHours : ""}
                                onChange={(e, d) => {
                                    const newValue = parseInt(d.value, 10);
                                    this.setState({
                                        messageDurationHours: !isNaN(newValue)
                                            ? Math.max(Math.min(newValue, 23), 0)
                                            : null,
                                    });
                                }}
                            />
                            <Form.Input
                                type="number"
                                label={this.props.t("general.minutes")}
                                value={
                                    messageDurationMinutes != null ? messageDurationMinutes : ""
                                }
                                onChange={(e, d) => {
                                    const newValue = parseInt(d.value, 10);
                                    this.setState({
                                        messageDurationMinutes: !isNaN(newValue)
                                            ? Math.max(Math.min(newValue, 59), 0)
                                            : null,
                                    });
                                }}
                            />
                        </Form.Group>
                    ) : null}
                    {tab == "advanced"
                        ? this.renderLocationTimeSlider("userInactiveTime")
                        : null}
                    {tab == "advanced"
                        ? this.renderLocationTimeSlider("userExpireTime")
                        : null}
                    {tab == "advanced"
                        ? this.renderLocationTimeSlider("apparatusInactiveTime")
                        : null}
                    {tab == "advanced"
                        ? this.renderLocationTimeSlider("apparatusExpireTime")
                        : null}

                    {actionStatus.key == "error" ? (
                        <Message negative content={actionStatus.message}/>
                    ) : null}
                    {!this.validatePinTimes() ? (
                        <Message
                            negative
                            content={this.props.t(
                                "stationBoards.addModal.locationTimesNotValid",
                            )}
                        />
                    ) : null}
                </Form>
            </div>
        );
    }

    render() {
        const {actionStatus, stationsStatus} = this.state;
        const {viewStatus, onClose} = this.props;
        let primaryButtonText = null;
        if (viewStatus.key == "create") {
            primaryButtonText = this.props.t("general.create");
        } else if (viewStatus.key == "edit") {
            primaryButtonText = this.props.t("general.update");
        }
        return (
            <Modal
                size="large"
                open={viewStatus.key != "hidden" && stationsStatus.key != "loading"}
                closeOnEscape={false}
                onClose={onClose}
            >
                <Modal.Header>
                    {this.props.t(
                        `stationBoards.addModal.${
                            viewStatus.key == "create" ? "createTitle" : "editTitle"
                        }`,
                    )}
                </Modal.Header>
                <Modal.Content style={{padding: 0}}>
                    {this.renderContent()}
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        content={this.props.t("general.cancel")}
                        disabled={actionStatus.key == "loading"}
                        onClick={onClose}
                    />
                    <Button
                        primary
                        content={primaryButtonText}
                        loading={actionStatus.key == "loading"}
                        disabled={this.getSubmissionStatus().key == "incomplete"}
                        onClick={() => {
                            if (viewStatus.key == "create") {
                                this.onCreate();
                            } else if (viewStatus.key == "edit") {
                                this.onEdit();
                            }
                        }}
                    />
                </Modal.Actions>
            </Modal>
        );
    }
}

export default withContext(CreateEditStationBoardModal, "api", "local", "i18n");
