import * as React from "react";
import {
    Button,
    Header,
    Icon,
    List,
    Table,
    Dropdown,
    Popup,
} from "semantic-ui-react";
import {AuthAgency, SCU} from "@bryxinc/lunch/models";
import {StationAlertingProps} from "./stationAlertingPage";
import {withContext} from "@bryxinc/lunch/context";
import {EditSCUConfigModal} from "./editSCUConfigModal";
import {StationAlertingZeroState} from "../../components/stationAlertingZeroState";

export interface ControlUnitsTabProps extends StationAlertingProps {
    selectedAgency: AuthAgency;
    scus: SCU[];
}

export interface ControlUnitsTabState {
    overlay: { key: "none" };
    modalStatus:
        | { key: "closed" }
        | {
        key: "open";
        type: "editSCUConfig";
        scuId: string;
        agencyId: string;
    };
}

export class ControlUnitsTab extends React.Component<ControlUnitsTabProps,
    ControlUnitsTabState> {
    constructor(props: ControlUnitsTabProps, context: any) {
        super(props, context);
        this.state = ControlUnitsTab.getInitialState();
    }

    static getInitialState(): ControlUnitsTabState {
        return {
            overlay: {key: "none"},
            modalStatus: {key: "closed"},
        };
    }

    private closeAndReload(reload: boolean) {
        this.setState({
            modalStatus: {key: "closed"},
        });
    }

    render() {
        const {scus} = this.props;
        return (
            <div className="underHorizNavContent tableUnderNavContent">
                {scus.length == 0 ? (
                    <StationAlertingZeroState {...this.props} />
                ) : (
                    <>
                        <Table striped>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell width={6}>
                                        {this.props.t("stationAlerting.controlUnits.name")}
                                    </Table.HeaderCell>
                                    <Table.HeaderCell width={6}>
                                        {this.props.t("stationAlerting.controlUnits.serial")}
                                    </Table.HeaderCell>
                                    <Table.HeaderCell width={5}>
                                        {this.props.t("stationAlerting.controlUnits.version")}
                                    </Table.HeaderCell>
                                    <Table.HeaderCell width={1}></Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {scus.map((scu) => (
                                    <Table.Row key={scu.id}>
                                        <Table.Cell width={6}>{scu.name}</Table.Cell>
                                        <Table.Cell width={6}>{scu.serial}</Table.Cell>
                                        <Table.Cell width={5}>
                                            {scu.version ||
                                            this.props.t(
                                                "stationAlerting.controlUnits.versionUnknown",
                                            )}
                                        </Table.Cell>
                                        <Table.Cell>
                                            {!scu.version || scu.version[0] !== "4" ? (
                                                <Popup
                                                    width={1}
                                                    content={this.props.t(
                                                        "stationAlerting.controlUnits.scuConfigDisabledMessage",
                                                    )}
                                                    trigger={
                                                        <Icon link size="large" name="ellipsis vertical"/>
                                                    }
                                                />
                                            ) : (
                                                <Dropdown
                                                    icon={null}
                                                    width={1}
                                                    trigger={
                                                        <Icon link size="large" name="ellipsis vertical"/>
                                                    }
                                                >
                                                    <Dropdown.Menu style={{right: 0, left: "auto"}}>
                                                        <Dropdown.Item
                                                            icon="cogs"
                                                            onClick={() =>
                                                                this.setState({
                                                                    modalStatus: {
                                                                        key: "open",
                                                                        type: "editSCUConfig",
                                                                        scuId: scu.id,
                                                                        agencyId: this.props.selectedAgency.id,
                                                                    },
                                                                })
                                                            }
                                                            text={this.props.t(
                                                                "stationAlerting.controlUnits.editScuConfigLabel",
                                                            )}
                                                        />
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            )}
                                        </Table.Cell>
                                    </Table.Row>
                                ))}
                            </Table.Body>
                        </Table>
                        <EditSCUConfigModal
                            {...this.props}
                            viewStatus={
                                this.state.modalStatus.key == "open" &&
                                this.state.modalStatus.type == "editSCUConfig"
                                    ? {
                                        key: "shown",
                                        scuId: this.state.modalStatus.scuId,
                                        agencyId: this.props.selectedAgency.id,
                                    }
                                    : {
                                        key: "hidden",
                                    }
                            }
                            onDismiss={this.closeAndReload.bind(this)}
                        />
                    </>
                )}
            </div>
        );
    }
}

export default withContext(ControlUnitsTab, "api", "local", "i18n");
