import * as React from "react";
import {Dropdown, Icon, Label, Table} from "semantic-ui-react";
import {PaginatedTable} from "../../components/paginatedTable";
import {ZeroStateView} from "../../components/zeroStateView";
import {
    ApiResult,
    PageResult,
    SortingConfig,
    AgencyGroup,
    AgencyGroupSortingOptions,
    AgencyGroupTypeEnum,
} from "@bryxinc/lunch/models";

import {SharedSupportUtils} from "@bryxinc/lunch/utils/functions";
import {withContext} from "@bryxinc/lunch/context";
import {AddAllMembersModal} from "./addAllMembersModal";
import {GroupsProps} from "./groupsPage";
import {RemoveAllMembersModal} from "./removeAllMembersModal";
import {ViewMembersModal} from "./viewMembersModal";
import {GetJoinLinkModal} from "./getJoinLinkModal";
import {EditOverridesModal} from "./editOverridesModal";

interface DispatchGroupsTabState {
    modalStatus:
        | { key: "closed" }
        | {
        key: "open";
        type:
            | "viewMembers"
            | "addAllMembers"
            | "removeAllMembers"
            | "getJoinLink";
        group: AgencyGroup;
    };
}

export class DispatchGroupsTab extends React.Component<GroupsProps,
    DispatchGroupsTabState> {
    private paginatedTable: PaginatedTable | null = null;

    constructor(props: GroupsProps, context: any) {
        super(props, context);

        this.state = {
            modalStatus: {key: "closed"},
        };
    }

    private loadItems(
        limit: number,
        activePage: number,
        searchString: string | null,
        sortConfig: SortingConfig<AgencyGroupSortingOptions>,
        callback: (result: ApiResult<PageResult<AgencyGroup>>) => void,
    ) {
        this.props.api.getAgencyGroups(
            this.props.selectedAgency.id,
            AgencyGroupTypeEnum.dispatch,
            limit,
            activePage,
            sortConfig,
            searchString,
            callback,
        );
    }

    private closeAndReload(reload: boolean) {
        this.setState(
            {
                modalStatus: {key: "closed"},
            },
            () => {
                if (this.paginatedTable != null && reload == true) {
                    this.paginatedTable.reload();
                }
            },
        );
    }

    render() {
        return (
            <div className="underHorizNavContent" style={{display: "flex"}}>
                <PaginatedTable
                    {...this.props}
                    uniqueKey="groups"
                    className="underHorizNavContent tableUnderNavContent"
                    sortable
                    selectable={false}
                    rightItem={null}
                    ref={(r) => (this.paginatedTable = r)}
                    loader={{
                        loadId: this.props.selectedAgency.id,
                        loadItems: this.loadItems.bind(this),
                    }}
                    headerDataItems={[
                        {
                            i18nKey: "groups.name",
                            headerKey: "name",
                            width: 16,
                        },
                    ]}
                    zeroStateView={
                        <div className="flexCenteredContainer">
                            <ZeroStateView
                                header={this.props.t("groups.zeroStateHeader")}
                                subheader={
                                    <span>
                    <div style={{marginBottom: "10px"}}>
                      {this.props.t("groups.zeroStateSubheader")}
                    </div>
                    <a
                        style={{cursor: "pointer"}}
                        onClick={() => SharedSupportUtils.onOpenSupport()}
                    >
                      {this.props.t("groups.contactSupportLinkHead")}
                    </a>{" "}
                                        {this.props.t("groups.contactSupportLinkTail")}
                  </span>
                                }
                            />
                        </div>
                    }
                    renderItem={(item: any) => {
                        const group = item as AgencyGroup;

                        return (
                            <Table.Row key={group.id}>
                                <Table.Cell style={{borderLeft: `10px solid ${group.color}`}}>
                                    {group.name}
                                    <div style={{float: "right"}}>
                                        <Label
                                            content={this.props.t("groups.memberCounts", {
                                                replace: {
                                                    groupCount: group.membersCount,
                                                    totalCount: group.agencyMembersCount,
                                                },
                                            })}
                                            style={{
                                                marginRight: "200px",
                                                width: "130px",
                                                textAlign: "center",
                                            }}
                                        />
                                        <Dropdown
                                            icon={null}
                                            style={{margin: "0 10px"}}
                                            trigger={
                                                <Icon link size="large" name="ellipsis vertical"/>
                                            }
                                        >
                                            <Dropdown.Menu style={{right: 0, left: "auto"}}>
                                                <Dropdown.Item
                                                    icon="user"
                                                    onClick={() =>
                                                        this.setState({
                                                            modalStatus: {
                                                                key: "open",
                                                                type: "viewMembers",
                                                                group: group,
                                                            },
                                                        })
                                                    }
                                                    text={this.props.t(
                                                        "groups.specificGroupActions.viewMembers",
                                                    )}
                                                />
                                                <Dropdown.Item
                                                    icon="users"
                                                    disabled={
                                                        group.membersCount == group.agencyMembersCount
                                                    }
                                                    onClick={() =>
                                                        this.setState({
                                                            modalStatus: {
                                                                key: "open",
                                                                type: "addAllMembers",
                                                                group: group,
                                                            },
                                                        })
                                                    }
                                                    text={this.props.t(
                                                        "groups.specificGroupActions.addAllMembers",
                                                    )}
                                                />
                                                <Dropdown.Item
                                                    icon="x"
                                                    disabled={group.membersCount == 0}
                                                    onClick={() =>
                                                        this.setState({
                                                            modalStatus: {
                                                                key: "open",
                                                                type: "removeAllMembers",
                                                                group: group,
                                                            },
                                                        })
                                                    }
                                                    text={this.props.t(
                                                        "groups.specificGroupActions.removeAllMembers",
                                                    )}
                                                />
                                                <Dropdown.Item
                                                    icon="settings"
                                                    onClick={() =>
                                                        this.setState({
                                                            modalStatus: {
                                                                key: "open",
                                                                type: "editOverrides",
                                                                group: group,
                                                            },
                                                        })
                                                    }
                                                    text={this.props.t(
                                                        "groups.specificGroupActions.editOverrides",
                                                    )}
                                                />
                                                <Dropdown.Divider/>
                                                <Dropdown.Item
                                                    icon="linkify"
                                                    onClick={() =>
                                                        this.setState({
                                                            modalStatus: {
                                                                key: "open",
                                                                type: "getJoinLink",
                                                                group: group,
                                                            },
                                                        })
                                                    }
                                                    text={this.props.t(
                                                        "groups.specificGroupActions.getJoinLink",
                                                    )}
                                                />
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                </Table.Cell>
                            </Table.Row>
                        );
                    }}
                    defaultSorting={{
                        column: "name",
                        direction: "asc",
                    }}
                />
                <ViewMembersModal
                    {...this.props}
                    agencyId={this.props.selectedAgency.id}
                    viewStatus={
                        this.state.modalStatus.key == "open" &&
                        this.state.modalStatus.type == "viewMembers"
                            ? {
                                key: "shown",
                                group: this.state.modalStatus.group,
                            }
                            : {
                                key: "hidden",
                            }
                    }
                    onDismiss={this.closeAndReload.bind(this)}
                />
                <AddAllMembersModal
                    {...this.props}
                    agencyId={this.props.selectedAgency.id}
                    viewStatus={
                        this.state.modalStatus.key == "open" &&
                        this.state.modalStatus.type == "addAllMembers"
                            ? {
                                key: "shown",
                                group: this.state.modalStatus.group,
                            }
                            : {
                                key: "hidden",
                            }
                    }
                    onDismiss={this.closeAndReload.bind(this)}
                />
                <RemoveAllMembersModal
                    {...this.props}
                    agencyId={this.props.selectedAgency.id}
                    viewStatus={
                        this.state.modalStatus.key == "open" &&
                        this.state.modalStatus.type == "removeAllMembers"
                            ? {
                                key: "shown",
                                group: this.state.modalStatus.group,
                            }
                            : {
                                key: "hidden",
                            }
                    }
                    onDismiss={this.closeAndReload.bind(this)}
                />
                <EditOverridesModal
                    {...this.props}
                    agencyId={this.props.selectedAgency.id}
                    viewStatus={
                        this.state.modalStatus.key == "open" &&
                        this.state.modalStatus.type == "editOverrides"
                            ? {
                                key: "shown",
                                group: this.state.modalStatus.group,
                            }
                            : {
                                key: "hidden",
                            }
                    }
                    onDismiss={this.closeAndReload.bind(this)}
                />
                <GetJoinLinkModal
                    {...this.props}
                    agencyId={this.props.selectedAgency.id}
                    viewStatus={
                        this.state.modalStatus.key == "open" &&
                        this.state.modalStatus.type == "getJoinLink"
                            ? {
                                key: "shown",
                                group: this.state.modalStatus.group,
                            }
                            : {
                                key: "hidden",
                            }
                    }
                    onDismiss={this.closeAndReload.bind(this)}
                />
            </div>
        );
    }
}

export default withContext(DispatchGroupsTab, "api", "local", "i18n");
