import { Geometry, Polygon, Point } from "geojson";
import { Form, Header, Label, Segment, Icon, Button } from "semantic-ui-react";
import { useHistory } from "react-router-dom";
import * as React from "react";
import { useEffect, useRef, useCallback, useState } from "react";
import { useConstCallback } from "powerhooks";
import { Location, LocationSearch } from "./locationSearch";
import {
    AuthAgency,
    PageResult,
    MinimalSiteSurvey,
    Agency,
} from "@bryxinc/lunch/models";
import { useTranslation, useApi } from "@bryxinc/lunch/context";
import { ManagementApi } from "@bryxinc/lunch/utils";
import { ParseResult } from "@bryxinc/lunch/cerealParse";
import { SiteSurveyList } from "./siteSurveyList";
import { useAgency, PageLimit, SiteSurveyListProps } from "./shared";
import { Link } from "react-router-dom";
import { promisify } from "../../utils/promisify";
import { BryxMap, BryxPointMarker, BryxPolyMarker } from "@bryxinc/ui";
import { SurveyMap } from "./siteSurveyMap";
import { Map as LMap } from "leaflet";

function noop(..._args: unknown[]): void { }

function buildEmptyPage<T>() {
    const result = PageResult.parse(
        { count: 0, results: [] },
        "results",
        (_: any): ParseResult<T> => {
            throw new Error();
        }
    );
    return result.success ? result.value : (null as never);
}

type UseSurveysResults = {
    doSearch: (searchTerms: Location) => unknown;
    onSearch: () => Promise<unknown>;
    onUpdate: (location: Location) => unknown;
    searchTerms: Location;
    onMapMove: (center: Polygon) => unknown;
    mapRef: React.MutableRefObject<ReturnType<typeof BryxMap.useMap>>;
    center?: Point | Polygon;
} & SiteSurveyListProps;

function useSurveys(
    api: ManagementApi,
    selectedAgencyId: string
): UseSurveysResults {
    const [searchTerms, setSearchTerms] = useState<Location>({
        street: "",
        city: "",
        state: "",
    });
    const [results, setResults] = useState(buildEmptyPage<MinimalSiteSurvey>());
    const [center, setCenter] = useState<Polygon | Point | undefined>(undefined);
    const doSearch = useConstCallback(async (searchTerms: Location) => {
        // Geocode searc
        const geocode = await promisify(
            api,
            api.geocode,
            selectedAgencyId,
            searchTerms.street,
            searchTerms.city,
            searchTerms.state
        );
        setCenter(geocode.centroid);
    });
    const mapRef: UseSurveysResults["mapRef"] = useRef(null as never);
    useEffect(() => {
        if (!mapRef.current) return;
        const map = mapRef.current!;
        const mapBounds = map.getBounds();
        const center = map.getCenter();
        promisify(
            api,
            api.searchSiteSurveys,
            null,
            selectedAgencyId,
            center.lat,
            center.lng,
            // meters -> kilometers
            Math.max(
                map.distance(mapBounds.getNorthEast(), mapBounds.getSouthWest()),
                map.distance(mapBounds.getNorthWest(), mapBounds.getSouthEast())
            ),
            0,
            500
        ).then(setResults);
    }, [center]);
    const onUpdate = useCallback((location: Location) => {
        setSearchTerms(location);
    }, []);
    const onSearch = useConstCallback(async () => {
        await doSearch(searchTerms);
    });
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10 as PageLimit);
    const onSetLimit = useCallback((limit: PageLimit) => setLimit(limit), []);
    const onSetPage = useCallback((page: number) => setPage(page), []);
    return {
        mapRef,
        limit,
        page,
        searchTerms,
        onSearch,
        doSearch,
        onSetLimit,
        onSetPage,
        results,
        onUpdate,
        center,
        onMapMove: useConstCallback((newCenter: Polygon) => {
            setCenter(newCenter);
        }),
    };
}

function SurveyMarker({ id, geometry }: { id: string; geometry: Geometry }) {
    const history = useHistory();
    const onClick = useConstCallback((_event) => {
        history.push(`/site-surveys/edit/${id}`);
    });
    if (geometry.type == "Point") {
        return <BryxPointMarker point={geometry} onClick={onClick} />;
    } else if (geometry.type == "Polygon") {
        return <BryxPolyMarker polygon={geometry} onClick={onClick} />;
    } else {
        throw new Error("Unknown geometry: " + geometry.type);
    }
}

const MapWrapper = React.memo(
    (props: {
        center?: Point | Polygon;
        agency: Agency | null;
        onMapMove: (center: Polygon) => unknown;
        mapRef: UseSurveysResults["mapRef"];
        results: UseSurveysResults["results"];
    }) => {
        const { center, agency, onMapMove, mapRef, results } = props;
        return (
            <div className="siteSurveyMapWrapper">
                {agency && (
                    <SurveyMap
                        point={undefined}
                        setPoint={noop}
                        center={center}
                        agency={agency}
                        onMapMove={onMapMove}
                        mapRef={mapRef}
                    >
                        {results.items.flatMap((result) => {
                            if (result.location.type == "MultiPolygon") {
                                return result.location.coordinates.map(coords => (
                                    <SurveyMarker
                                        key={result.id}
                                        id={result.id}
                                        geometry={({type: "Polygon", coordinates: coords})}
                                    />
                                ))
                            } else {
                                return <SurveyMarker
                                    key={result.id}
                                    id={result.id}
                                    geometry={result.location}
                                />;
                            }
                        })}
                    </SurveyMap>
                )}
            </div>
        );
    }
);

export function SurveySearch({
                                 selectedAgency,
                             }: {
    selectedAgency: AuthAgency;
}) {
    const { t } = useTranslation();
    const { api } = useApi<ManagementApi>();
    const {
        onSearch,
        searchTerms,
        onUpdate,
        onSetPage,
        onSetLimit,
        page,
        limit,
        results,
        onMapMove,
        mapRef,
        center,
    } = useSurveys(api, selectedAgency.id);
    const agency = useAgency(selectedAgency.id);

    return (
        <div
            className="underHorizNavContent"
            style={{ padding: "40px 60px", overflowY: "auto", minHeight: "100%" }}
        >
            <Form>
                <Segment>
                    <Header as="h1">
                        {t("siteSurvey.createSiteSurvey.searchHeader")}
                    </Header>
                    <LocationSearch
                        onSearch={onSearch}
                        tall={false}
                        onUpdate={onUpdate}
                        location={searchTerms}
                    />
                </Segment>

                <div className="siteSurveyMapRow">
                    <MapWrapper
                        center={center}
                        agency={agency}
                        onMapMove={onMapMove}
                        mapRef={mapRef}
                        results={results}
                    />
                </div>
                <div className="siteSurveySearchRow">
                    <Link to="/site-surveys/create?from=blank">
                        <Button positive>
                            <Icon name="plus" />
                            {t("general.create")}
                        </Button>
                    </Link>
                </div>
                <Header as="h1">
                    {t("siteSurvey.createSiteSurvey.resultsHeader")}
                </Header>
                <div className="siteSurveyWrapper">
                    <SiteSurveyList
                        page={page}
                        onSetPage={onSetPage}
                        limit={limit}
                        onSetLimit={onSetLimit}
                        results={results}
                        center={center}
                    />
                </div>
            </Form>
        </div>
    );
}
