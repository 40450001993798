import * as React from "react";
import {Button, Form, Message, Modal} from "semantic-ui-react";
import {nullIfBlank} from "@bryxinc/lunch/utils/functions";

import {RouteComponentProps} from "react-router";
import BryxApi from "@bryxinc/lunch/utils/ManagementApi";
import {
    withContext,
    WithTranslation,
    WithLocal,
    WithApi,
} from "@bryxinc/lunch/context";

export type SetClientExternalIdModalViewStatus =
    | { key: "hidden" }
    | { key: "shown"; agencyId: string; clientId: string };

interface SetClientExternalIdModalProps
    extends RouteComponentProps<any>,
        WithTranslation,
        WithLocal,
        WithApi<BryxApi> {
    viewStatus: SetClientExternalIdModalViewStatus;
    onClose: () => void;
}

interface SetClientExternalIdModalState {
    loadStatus:
        | { key: "ready" }
        | { key: "loading" }
        | { key: "success"; externalId: string | null }
        | { key: "error"; message: string };
    updateStatus:
        | { key: "ready" }
        | { key: "loading" }
        | { key: "error"; message: string };
    externalId: string | null;
}

export class SetClientExternalIdModal extends React.Component<SetClientExternalIdModalProps,
    SetClientExternalIdModalState> {
    constructor(props: SetClientExternalIdModalProps, context: any) {
        super(props, context);
        this.state = SetClientExternalIdModal.getInitialState();
    }

    private static getInitialState(): SetClientExternalIdModalState {
        return {
            updateStatus: {key: "ready"},
            loadStatus: {key: "loading"},
            externalId: null,
        };
    }

    private loadExternalId(props: SetClientExternalIdModalProps) {
        const {viewStatus} = props;
        if (viewStatus.key == "hidden") {
            return;
        }
        this.props.api.getClientExternalId(
            viewStatus.agencyId,
            viewStatus.clientId,
            (result) => {
                if (result.success == true) {
                    this.setState({
                        loadStatus: {
                            key: "success",
                            externalId: result.value.id,
                        },
                        externalId: result.value.id,
                    });
                } else {
                    this.props.local.logWarn(
                        `Failed to load external client ID: ${
                            result.debugMessage || result.message
                        }`,
                    );
                    this.setState({
                        loadStatus: {key: "error", message: result.message},
                    });
                }
            },
        );
    }

    componentDidMount() {
        this.loadExternalId(this.props);
    }

    componentWillReceiveProps(nextProps: SetClientExternalIdModalProps) {
        if (
            this.props.viewStatus.key == "hidden" &&
            nextProps.viewStatus.key == "shown"
        ) {
            this.setState(SetClientExternalIdModal.getInitialState(), () => {
                this.loadExternalId(nextProps);
            });
        }
    }

    private canSubmit(): boolean {
        const {loadStatus, externalId} = this.state;
        if (loadStatus.key != "success") {
            return false;
        }
        return loadStatus.externalId != externalId;
    }

    private onSubmit() {
        const {loadStatus, updateStatus, externalId} = this.state;
        const {viewStatus} = this.props;
        if (
            loadStatus.key != "success" ||
            updateStatus.key == "loading" ||
            viewStatus.key != "shown"
        ) {
            return;
        }
        this.setState({updateStatus: {key: "loading"}});
        this.props.api.setClientExternalId(
            viewStatus.agencyId,
            viewStatus.clientId,
            externalId,
            (result) => {
                if (result.success == true) {
                    this.props.onClose();
                } else {
                    this.props.local.logWarn(
                        `Failed to set external client ID: ${
                            result.debugMessage || result.message
                        }`,
                    );
                    this.setState({
                        updateStatus: {key: "error", message: result.message},
                    });
                }
            },
        );
    }

    private onClearExternalId() {
        this.setState({externalId: null});
    }

    private renderModalContent(): JSX.Element | null {
        const {viewStatus} = this.props;
        const {loadStatus, updateStatus, externalId} = this.state;
        if (viewStatus.key == "hidden") {
            return <Modal.Content/>;
        }

        let panel = null;
        if (loadStatus.key == "error") {
            panel = <Message error content={loadStatus.message}/>;
        } else if (updateStatus.key == "error") {
            panel = <Message error content={updateStatus.message}/>;
        }

        return (
            <Modal.Content>
                <Form
                    onSubmit={(e) => {
                        e.preventDefault();
                        this.onSubmit();
                    }}
                >
                    <Form.Input
                        value={externalId || ""}
                        disabled={loadStatus.key != "success"}
                        action={{
                            type: "button",
                            content: this.props.t("general.clear"),
                            onClick: this.onClearExternalId.bind(this),
                        }}
                        onChange={(e, d) => {
                            this.setState({externalId: nullIfBlank(d.value)});
                        }}
                    />
                </Form>
                {panel}
            </Modal.Content>
        );
    }

    render() {
        const {viewStatus} = this.props;
        const {loadStatus, updateStatus} = this.state;
        return (
            <Modal
                size="mini"
                open={viewStatus.key == "shown"}
                onClose={this.props.onClose}
            >
                <Modal.Header>
                    {this.props.t("members.clients.setExternalId")}
                </Modal.Header>
                {this.renderModalContent()}
                <Modal.Actions>
                    <Button
                        content={this.props.t("general.cancel")}
                        disabled={
                            updateStatus.key == "loading" || loadStatus.key == "loading"
                        }
                        onClick={this.props.onClose}
                    />
                    <Button
                        primary
                        loading={
                            updateStatus.key == "loading" || loadStatus.key == "loading"
                        }
                        disabled={!this.canSubmit()}
                        content={this.props.t("general.save")}
                        onClick={this.onSubmit.bind(this)}
                    />
                </Modal.Actions>
            </Modal>
        );
    }
}

export default withContext(SetClientExternalIdModal, "api", "local", "i18n");
