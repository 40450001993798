import * as React from 'react';
import CopyToClipboard from "react-copy-to-clipboard";

interface CopyTimedProps extends React.HTMLProps<CopyTimed> {
    copyText: string;
    showCopiedTimeout?: number;
    renderContent: (showCopied: boolean) => React.ReactNode;
}

interface CopyTimedState {
    showCopied: boolean;
}

export class CopyTimed extends React.Component<CopyTimedProps, CopyTimedState> {
    private static readonly showCopiedTimeout = 2.0 * 1000;
    private timerId: number | null = null;

    constructor(props: CopyTimedProps, context: any) {
        super(props, context);
        this.state = {
            showCopied: false,
        };
    }

    componentWillUnmount() {
        if (this.timerId != null) {
            clearTimeout(this.timerId);
        }
    }

    onCopy() {
        this.setState({showCopied: true});
        this.timerId = window.setTimeout(() => {
            this.timerId = null;
            this.setState({showCopied: false});
        }, this.props.showCopiedTimeout || CopyTimed.showCopiedTimeout);
    }

    render() {
        return (
            <CopyToClipboard text={this.props.copyText} onCopy={this.onCopy.bind(this)}>
                {this.props.renderContent(this.state.showCopied)}
            </CopyToClipboard>
        );
    }
}
