import * as React from "react";
import {Button, Message, Modal, Segment, Tab} from "semantic-ui-react";
import {FullAgencySCU, SCUConfig} from "@bryxinc/lunch/models";

import {GeneralSettings} from "./config/generalSettings";
import {ZoneSettings} from "./config/zoneSettings";

import {RouteComponentProps} from "react-router";
import BryxApi from "@bryxinc/lunch/utils/ManagementApi";
import {
    WithApi,
    withContext,
    WithLocal,
    WithTranslation,
} from "@bryxinc/lunch/context";
// import {PrinterSettings} from "./config/printerSettings";
import {UnitSettings} from "./config/unitSettings";

export type EditSCUConfigModalViewStatus =
    | { key: "hidden" }
    | { key: "shown"; scuId: string; agencyId: string };

interface EditSCUConfigModalProps
    extends RouteComponentProps<any>,
        WithTranslation,
        WithLocal,
        WithApi<BryxApi> {
    viewStatus: EditSCUConfigModalViewStatus;

    onDismiss(reload: boolean): void;
}

interface EditSCUConfigModalState {
    loadStatus:
        | { key: "loading" }
        | { key: "ready"; scu: FullAgencySCU; config: SCUConfig }
        | { key: "failed"; message: string };
}

export class EditSCUConfigModal extends React.Component<EditSCUConfigModalProps,
    EditSCUConfigModalState> {
    constructor(props: EditSCUConfigModalProps, context: any) {
        super(props, context);

        this.state = this.getInitialState();
    }

    getInitialState(): EditSCUConfigModalState {
        return {
            loadStatus: {
                key: "loading",
            },
        };
    }

    componentWillReceiveProps(newProps: EditSCUConfigModalProps) {
        if (
            this.props.viewStatus.key == "hidden" &&
            newProps.viewStatus.key == "shown"
        ) {
            // Opening modal
            this.setState(this.getInitialState(), () => {
                this.reload();
            });
        }
    }

    private reload() {
        this.setState({loadStatus: {key: "loading"}});
        if (this.props.viewStatus.key == "shown") {
            this.props.api.getAgencySCU(
                this.props.viewStatus.agencyId,
                this.props.viewStatus.scuId,
                (result1: any) => {
                    if (result1.success) {
                        const scu = JSON.parse(JSON.stringify(result1.value));
                        if (this.props.viewStatus.key == "shown") {
                            this.props.api.getAgencySCUConfig(
                                this.props.viewStatus.agencyId,
                                this.props.viewStatus.scuId,
                                (result: any) => {
                                    if (result.success) {
                                        this.setState({
                                            loadStatus: {
                                                key: "ready",
                                                scu: scu,
                                                config: result.value.config,
                                            },
                                        });
                                    } else {
                                        this.props.local.logWarn(
                                            `Unable to load SCU config: ${(result as any).message}`,
                                        );
                                        this.setState({
                                            loadStatus: {
                                                key: "failed",
                                                message: (result as any).message,
                                            },
                                        });
                                    }
                                },
                            );
                        }
                    } else {
                        this.props.local.logWarn(
                            `Unable to load SCU: ${(result1 as any).message}`,
                        );
                        this.setState({
                            loadStatus: {key: "failed", message: (result1 as any).message},
                        });
                    }
                },
            );
        }
    }

    render() {
        const {loadStatus} = this.state;
        const onScuUnits =
            this.state.loadStatus.key == "ready"
                ? this.state.loadStatus.scu.units
                : [];
        let content;
        if (loadStatus.key == "loading") {
            content = (
                <div style={{paddingTop: "15px", height: "100%"}}>
                    <Segment loading>
                        <br/>
                        <br/>
                    </Segment>
                </div>
            );
        } else if (loadStatus.key == "failed") {
            content = (
                <div style={{paddingTop: "15px", height: "100%"}}>
                    <Message negative content={loadStatus.message}/>
                </div>
            );
        } else {
            const scu = loadStatus.scu;
            const agencyId =
                this.props.viewStatus.key == "shown"
                    ? this.props.viewStatus.agencyId
                    : "";

            const panes = [
                {
                    menuItem: this.props.t(
                        "stationAlerting.configurationModal.tabs.general",
                    ),
                    render: () => (
                        <Tab.Pane>
                            <GeneralSettings
                                {...this.props}
                                config={loadStatus.config}
                                scuId={scu.id}
                                agencyId={agencyId}
                            />
                        </Tab.Pane>
                    ),
                },
                // {
                //     menuItem: 'Printers',
                //     render: () => (
                //         <Tab.Pane>
                //             <PrinterSettings
                //                 {...this.props}
                //                 config={loadStatus.config.printers}
                //                 unitOptions={unitOptions}
                //                 scuId={scu.id}
                //             />
                //         </Tab.Pane>
                //     )
                // },
            ];

            if (loadStatus.config.zones.length > 1) {
                panes.push({
                    menuItem: this.props.t(
                        "stationAlerting.configurationModal.tabs.zones",
                    ),
                    render: () => (
                        <Tab.Pane>
                            <ZoneSettings
                                {...this.props}
                                zones={loadStatus.config.zones}
                                scuId={scu.id}
                                agencyId={agencyId}
                                onScuUnits={onScuUnits}
                            />
                        </Tab.Pane>
                    ),
                });
            } else {
                panes.push({
                    menuItem: this.props.t(
                        "stationAlerting.configurationModal.tabs.units",
                    ),
                    render: () => (
                        <Tab.Pane>
                            <UnitSettings
                                {...this.props}
                                agencyId={agencyId}
                                scuId={scu.id}
                            />
                        </Tab.Pane>
                    ),
                });
            }

            content = (
                <div style={{paddingTop: "15px", height: "100%"}}>
                    <Tab panes={panes}/>
                </div>
            );
        }

        return (
            <Modal
                size="large"
                open={this.props.viewStatus.key == "shown"}
                onClose={() => this.props.onDismiss(true)}
            >
                <Modal.Header>
                    <h2 style={{display: "inline"}}>
                        {this.props.t(
                            "stationAlerting.configurationModal.configurationModalTitle",
                        )}
                    </h2>
                </Modal.Header>
                <Modal.Content
                    scrolling
                    style={{
                        backgroundColor: "#fbfbfb",
                        height: "700px",
                        padding: "0 10px 10px 10px",
                    }}
                >
                    {content}
                </Modal.Content>
            </Modal>
        );
    }
}

export default withContext(EditSCUConfigModal, "api", "local", "i18n");
