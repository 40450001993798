import * as React from "react";
import {Button, Message, Modal} from "semantic-ui-react";
import {CopyButton} from "../../components/copyButton";
import {User} from "@bryxinc/lunch/models";

import {RouteComponentProps} from "react-router";
import BryxApi from "@bryxinc/lunch/utils/ManagementApi";
import {
    withContext,
    WithTranslation,
    WithLocal,
    WithApi,
} from "@bryxinc/lunch/context";

export type ExportUsersModalViewStatus =
    | { key: "hidden" }
    | { key: "shown"; users: User[] };

interface ExportUsersModalProps
    extends RouteComponentProps<any>,
        WithTranslation,
        WithLocal,
        WithApi<BryxApi> {
    viewStatus: ExportUsersModalViewStatus;
    onClose: () => void;
}

export class ExportUsersModal extends React.Component<ExportUsersModalProps, {}> {
    render() {
        const {viewStatus} = this.props;
        const users = viewStatus.key == "shown" ? viewStatus.users : [];
        const copyContent = users
            .map((u) => `${u.commonName} <${u.email}>`)
            .join(";\n");
        return (
            <Modal
                size="small"
                open={viewStatus.key == "shown"}
                onClose={this.props.onClose}
            >
                <Modal.Header>
                    {this.props.t("members.users.exportUsersModal.title")}
                </Modal.Header>
                <Modal.Content
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        height: "200px",
                        position: "relative",
                    }}
                >
                    <textarea style={{flex: 1}} defaultValue={copyContent}/>
                    <CopyButton
                        copyText={copyContent}
                        style={{position: "absolute", top: "25px", right: "22px"}}
                    />
                    <Message
                        info
                        content={this.props.t("members.users.exportUsersModal.messageBody")}
                    />
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        content={this.props.t("general.done")}
                        onClick={this.props.onClose}
                    />
                </Modal.Actions>
            </Modal>
        );
    }
}

export default withContext(ExportUsersModal, "api", "local", "i18n");
