import * as L from "leaflet";
import * as React from "react";
import {Marker, Polygon, Polyline} from "react-leaflet";
import {Button, Form, Icon, List, Message, Modal} from "semantic-ui-react";
import {BryxGeoJSONLayer} from "../../components/bryxGeoJSONLayer";
import {BryxMap} from "../../components/bryxMap";
import {Agency} from "@bryxinc/lunch/models";
import BryxApi from "@bryxinc/lunch/utils/ManagementApi";

import {RouteComponentProps} from "react-router";
import {
    withContext,
    WithTranslation,
    WithLocal,
    WithApi,
} from "@bryxinc/lunch/context";

export type EditBoundaryModalViewStatus =
    | { key: "hidden" }
    | { key: "shown"; agency: Agency };

interface EditBoundaryModalProps
    extends RouteComponentProps<any>,
        WithTranslation,
        WithLocal,
        WithApi<BryxApi> {
    viewStatus: EditBoundaryModalViewStatus;
    onClose: () => void;
}

interface EditBoundaryModalState {
    status:
        | { key: "ready" }
        | { key: "loading" }
        | { key: "success" }
        | { key: "error"; message: string };
    mode: "map" | "file";
    points: L.LatLng[];
    polyComplete: boolean;
    file: File | null;
    fileInputKey: number;
}

export class EditBoundaryModal extends React.Component<EditBoundaryModalProps,
    EditBoundaryModalState> {
    constructor(props: EditBoundaryModalProps, context: any) {
        super(props, context);
        this.state = EditBoundaryModal.getInitialState();
    }

    private static getInitialState(): EditBoundaryModalState {
        return {
            mode: "map",
            status: {key: "ready"},
            points: [],
            polyComplete: false,
            file: null,
            fileInputKey: Date.now(),
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps: EditBoundaryModalProps) {
        if (
            this.props.viewStatus.key == "hidden" &&
            nextProps.viewStatus.key == "shown"
        ) {
            this.setState(EditBoundaryModal.getInitialState());
        }
    }

    private canSubmit(): boolean {
        return (
            (this.state.mode == "map" &&
                this.state.points.length >= 3 &&
                this.state.polyComplete) ||
            (this.state.mode == "file" && this.state.file != null)
        );
    }

    private submit() {
        const email = this.props.local.email;
        if (this.props.viewStatus.key != "shown" || email == null) {
            return;
        }
        const {points, file} = this.state;
        const {agency} = this.props.viewStatus;
        this.setState({status: {key: "loading"}});
        this.props.api.sendBoundaryUpdateRequest(
            email,
            agency,
            points.length >= 3 ? L.polygon(points).toGeoJSON().geometry : null,
            file,
            this.props.local,
            (result) => {
                if (result.success) {
                    this.setState({status: {key: "success"}});
                } else {
                    this.setState({status: {key: "error", message: result.message}});
                    this.props.local.logWarn(
                        `Failed to send boundary update request: ${
                            result.debugMessage || result.message
                        }`,
                    );
                }
            },
        );
    }

    private renderModalContent(): JSX.Element | null {
        const {viewStatus} = this.props;
        const {status, points} = this.state;
        if (viewStatus.key == "hidden") {
            return <Modal.Content/>;
        }

        if (status.key == "success") {
            return (
                <Modal.Content>
                    <Message
                        success
                        icon="check circle outline"
                        content={this.props.t("agency.general.editBoundary.success")}
                    />
                </Modal.Content>
            );
        }

        return (
            <Modal.Content>
                <Message icon info>
                    <Icon name="map"/>
                    <Message.Content>
                        <List bulleted>
                            {this.state.mode == "map" ? (
                                <List.Item>
                                    {this.props.t("agency.general.editBoundary.clickPointsToAdd")}
                                </List.Item>
                            ) : null}
                            {this.state.mode == "map" ? (
                                <List.Item>
                                    {this.props.t(
                                        "agency.general.editBoundary.clickStartToFinish",
                                    )}
                                </List.Item>
                            ) : null}
                            <List.Item>
                                {this.props.t("agency.general.editBoundary.uploadInstead")}
                            </List.Item>
                        </List>
                        <Form style={{marginTop: "5px"}}>
                            <Form.Input
                                key={this.state.fileInputKey}
                                type="file"
                                onChange={(e) => {
                                    const target = e.target as HTMLInputElement;
                                    this.setState({
                                        mode: "file",
                                        file: target.files != null ? target.files[0] : null,
                                    });
                                }}
                            />
                            {this.state.mode == "file" ? (
                                <Form.Button
                                    content={this.props.t("general.back")}
                                    onClick={() =>
                                        this.setState({
                                            mode: "map",
                                            file: null,
                                            fileInputKey: Date.now(),
                                        })
                                    }
                                />
                            ) : null}
                        </Form>
                    </Message.Content>
                </Message>
                {this.state.mode == "map" ? (
                    <BryxMap
                        {...this.props}
                        bounds={L.geoJSON(viewStatus.agency.bufferedBoundary).getBounds()}
                        onclick={(e) =>
                            this.setState((prevState) => ({
                                points: prevState.points.concat([e.latlng]),
                                polyComplete: false,
                            }))
                        }
                        style={{height: "440px", cursor: "crosshair"}}
                    >
                        {this.state.points.map((point, index) => (
                            <Marker
                                key={point.toString()}
                                position={point}
                                icon={
                                    index == 0
                                        ? !this.state.polyComplete
                                        ? BryxMap.genericIcon
                                        : BryxMap.checkIcon
                                        : BryxMap.dotIcon
                                }
                                onclick={() => {
                                    if (index == 0) {
                                        this.setState((prevState) => ({
                                            polyComplete: !prevState.polyComplete,
                                        }));
                                    } else {
                                        this.setState((prevState) => ({
                                            points: prevState.points.filter((p) => !p.equals(point)),
                                            polyComplete: false,
                                        }));
                                    }
                                }}
                            />
                        ))}
                        {this.state.polyComplete ? (
                            <Polygon positions={this.state.points}/>
                        ) : (
                            <Polyline positions={this.state.points}/>
                        )}
                        {points.length < 1 ? (
                            <BryxGeoJSONLayer
                                {...this.props}
                                geojson={viewStatus.agency.bufferedBoundary}
                                pathOptions={{opacity: 0.3}}
                            />
                        ) : null}

                    </BryxMap>
                ) : null}
                {status.key == "error" ? (
                    <Message error content={status.message}/>
                ) : null}
            </Modal.Content>
        );
    }

    render() {
        const {viewStatus} = this.props;
        const {status, mode, points} = this.state;
        return (
            <Modal
                size="large"
                open={viewStatus.key == "shown"}
                onClose={this.props.onClose}
            >
                <Modal.Header>
                    {this.props.t("agency.general.editBoundary.header")}
                </Modal.Header>
                {this.renderModalContent()}
                <Modal.Actions>
                    <Button
                        content={
                            status.key != "success"
                                ? this.props.t("general.cancel")
                                : this.props.t("general.done")
                        }
                        disabled={status.key == "loading"}
                        onClick={this.props.onClose}
                    />
                    {mode == "map" && status.key != "success" ? (
                        <Button
                            content={this.props.t("general.reset")}
                            disabled={points.length == 0 || status.key == "loading"}
                            onClick={() =>
                                this.setState({
                                    points: [],
                                    polyComplete: false,
                                    fileInputKey: Date.now(),
                                })
                            }
                        />
                    ) : null}
                    {status.key != "success" ? (
                        <Button
                            primary
                            loading={status.key == "loading"}
                            disabled={!this.canSubmit()}
                            content={this.props.t("general.update")}
                            onClick={() => this.submit()}
                        />
                    ) : null}
                </Modal.Actions>
            </Modal>
        );
    }
}

export default withContext(EditBoundaryModal, "api", "local", "i18n");
