import * as React from "react";
import {Redirect, Route, RouteComponentProps, Switch} from "react-router";
import {Menu} from "semantic-ui-react";
import RouterMenuItem from "../../components/routerMenuItem";
import {AuthAgency} from "@bryxinc/lunch/models";
import {DispatchGroupsTab} from "./dispatchGroupsTab";
import {MessagingGroupsTab} from "./messagingGroupsTab";

import BryxApi from "@bryxinc/lunch/utils/ManagementApi";
import {
    withContext,
    WithTranslation,
    WithLocal,
    WithApi,
} from "@bryxinc/lunch/context";

export interface GroupsProps
    extends RouteComponentProps<any>,
        WithTranslation,
        WithLocal,
        WithApi<BryxApi> {
    selectedAgency: AuthAgency;
}

export class GroupsPage extends React.Component<GroupsProps, {}> {
    render() {
        return (
            <div id="GroupsContent" className="pageContent horizontalNavAndContent">
                <div id="GroupsHorizontalNav" className="Component_HorizontalNav">
                    <Menu attached="top" tabular>
                        <RouterMenuItem to="/groups/dispatch">
                            {this.props.t("groups.tabs.dispatch")}
                        </RouterMenuItem>
                        <RouterMenuItem to="/groups/messaging">
                            {this.props.t("groups.tabs.messaging")}
                        </RouterMenuItem>
                    </Menu>
                </div>

                <Switch>
                    <Route
                        path="/groups/dispatch"
                        render={(props) => (
                            <DispatchGroupsTab
                                {...this.props}
                                selectedAgency={this.props.selectedAgency}
                                {...props}
                            />
                        )}
                    />
                    <Route
                        path="/groups/messaging"
                        render={(props) => (
                            <MessagingGroupsTab
                                {...this.props}
                                selectedAgency={this.props.selectedAgency}
                                {...props}
                            />
                        )}
                    />

                    <Redirect to="/groups/dispatch"/>
                </Switch>
            </div>
        );
    }
}

export default withContext(GroupsPage, "api", "local", "i18n");
