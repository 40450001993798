import * as React from 'react';
import {Marker, Polyline, Polygon, LayerGroup, LeafletEvents} from "react-leaflet";
import * as Leaflet from 'leaflet';
import {BryxMap} from "./bryxMap";
import {GeoJSON, Icon} from "leaflet";
import {
    Feature as GeoJSONFeature,
    FeatureCollection as GeoJSONFeatureCollection,
    GeometryCollection as GeoJSONGeometryCollection,
    MultiPolygon as GeoJSONMultiPolygon,
    Polygon as GeoJSONPolygon,
    MultiLineString as GeoJSONMultiLineString,
    LineString as GeoJSONLineString,
    MultiPoint as GeoJSONMultiPoint,
    Point as GeoJSONPoint, GeoJsonObject, GeometryObject, Feature
} from "geojson";

import BryxApi from '@bryxinc/lunch/utils/ManagementApi';
import {withContext, WithTranslation, WithLocal, WithApi} from '@bryxinc/lunch/context';
import {RouteComponentProps} from "react-router";

interface BryxGeoJSONLayerProps extends LeafletEvents, RouteComponentProps, WithTranslation, WithLocal, WithApi<BryxApi> {
    geojson: GeoJsonObject;
    icon?: Icon;
    pathOptions?: Leaflet.PathOptions;

    onclick?(event: Leaflet.LeafletMouseEvent): void;
}

export class BryxGeoJSONLayer extends React.Component<BryxGeoJSONLayerProps, any> {
    render() {
        function geoJSONToLayer(geojson: GeoJsonObject, icon?: Icon, pathOptions?: Leaflet.PathOptions | null): JSX.Element | null {
            switch (geojson.type) {
                case 'Point':
                    const point = geojson as GeoJSONPoint;
                    return (
                        <Marker
                            position={GeoJSON.coordsToLatLng(point.coordinates as [number, number] | [number, number, number])}
                            icon={icon || BryxMap.genericIcon}/>
                    );

                case 'MultiPoint':
                    const multipoint = geojson as GeoJSONMultiPoint;
                    return (
                        <LayerGroup>
                            {multipoint.coordinates.map((c: number[], index: number) => {
                                return (
                                    <Marker
                                        key={index}
                                        position={GeoJSON.coordsToLatLng(c as [number, number] | [number, number, number])}
                                        icon={icon || BryxMap.genericIcon}/>
                                );
                            })}
                        </LayerGroup>
                    );

                case 'LineString':
                    const linestring = geojson as GeoJSONLineString;
                    return (
                        <Polyline
                            positions={GeoJSON.coordsToLatLngs(linestring.coordinates, 0, GeoJSON.coordsToLatLng)}
                            {...pathOptions}/>
                    );

                case 'MultiLineString':
                    const multilinestring = geojson as GeoJSONMultiLineString;
                    return (
                        <Polyline
                            positions={GeoJSON.coordsToLatLngs(multilinestring.coordinates, 1, GeoJSON.coordsToLatLng)}
                            {...pathOptions}/>
                    );

                case 'Polygon':
                    const polygon = geojson as GeoJSONPolygon;
                    return (
                        <Polygon
                            positions={GeoJSON.coordsToLatLngs(polygon.coordinates, 1, GeoJSON.coordsToLatLng)}
                            {...pathOptions}/>
                    );

                case 'MultiPolygon':
                    const multipolygon = geojson as GeoJSONMultiPolygon;
                    return (
                        <Polygon
                            positions={GeoJSON.coordsToLatLngs(multipolygon.coordinates, 2, GeoJSON.coordsToLatLng)}
                            {...pathOptions}/>
                    );

                case 'GeometryCollection':
                    const geometrycollection = geojson as GeoJSONGeometryCollection;
                    return (
                        <LayerGroup>
                            {geometrycollection.geometries.map((g: GeometryObject) => {
                                return geoJSONToLayer({
                                    geometry: g,
                                    type: 'Feature',
                                    properties: null,
                                } as Feature<GeometryObject>);
                            }).filter(Boolean) as JSX.Element[]}
                        </LayerGroup>
                    );

                case 'Feature':
                    const feature = geojson as GeoJSONFeature<GeometryObject>;
                    return geoJSONToLayer(feature.geometry);

                case 'FeatureCollection':
                    const featurecollection = geojson as GeoJSONFeatureCollection<GeometryObject>;
                    return (
                        <LayerGroup>
                            {featurecollection.features.map((f: Feature<GeometryObject>) => {
                                return geoJSONToLayer(f);
                            }).filter(Boolean) as JSX.Element[]}
                        </LayerGroup>
                    );

                default:
                    return null;
            }
        }

        return (
            <LayerGroup>
                {geoJSONToLayer(this.props.geojson, this.props.icon, this.props.pathOptions)}
            </LayerGroup>
        );
    }
}

export default withContext(BryxGeoJSONLayer, 'api', 'local', 'i18n');
