import * as React from "react";
import {Button, List, Message, Modal} from "semantic-ui-react";
import {AuthAgency} from "@bryxinc/lunch/models";

import {RouteComponentProps} from "react-router";
import BryxApi from "@bryxinc/lunch/utils/ManagementApi";
import {
    withContext,
    WithTranslation,
    WithLocal,
    WithApi,
} from "@bryxinc/lunch/context";

interface StationBoardTrialRequestProps
    extends RouteComponentProps<any>,
        WithTranslation,
        WithLocal,
        WithApi<BryxApi> {
    agency: AuthAgency;
    open: boolean;

    onSubmit(agency: AuthAgency): void;

    onDismiss(): void;
}

interface StationBoardTrialRequestState {
    actionStatus:
        | { key: "ready" }
        | { key: "loading" }
        | { key: "error"; message: string };
}

export class StationBoardTrialRequestModal extends React.Component<StationBoardTrialRequestProps,
    StationBoardTrialRequestState> {
    constructor(props: StationBoardTrialRequestProps, context: any) {
        super(props, context);

        this.state = StationBoardTrialRequestModal.getInitialState();
    }

    static getInitialState(): StationBoardTrialRequestState {
        return {
            actionStatus: {key: "ready"},
        };
    }

    componentWillReceiveProps(nextProps: StationBoardTrialRequestProps) {
        if (this.props.open == false && nextProps.open == true) {
            this.setState(StationBoardTrialRequestModal.getInitialState());
        }
    }

    private sendTrialRequest() {
        this.setState({actionStatus: {key: "loading"}});
        this.props.api.sendStationBoardTrialRequest(
            this.props.agency.id,
            (result) => {
                if (result.success == true) {
                    this.props.onSubmit(result.value);
                } else {
                    this.props.local.logWarn(
                        `Failed to send station board trial request: ${result.debugMessage}`,
                    );
                    this.setState({
                        actionStatus: {key: "error", message: result.message},
                    });
                }
            },
        );
    }

    render() {
        const {onDismiss, open, agency} = this.props;
        const {actionStatus} = this.state;

        return (
            <Modal size="small" open={open} onClose={onDismiss}>
                <Modal.Header>
                    {this.props.t("stationBoards.requestTrial.header")}
                </Modal.Header>
                <Modal.Content>
                    <List bulleted>
                        <List.Item>
                            {this.props.t("stationBoards.requestTrial.infoBody1", {
                                replace: {agencyName: agency.name},
                            })}
                        </List.Item>
                        <List.Item>
                            {this.props.t("stationBoards.requestTrial.infoBody2")}
                        </List.Item>
                        <List.Item>
                            {this.props.t("stationBoards.requestTrial.infoBody3")}
                        </List.Item>
                    </List>
                    {actionStatus.key == "error" ? (
                        <Message negative content={actionStatus.message}/>
                    ) : undefined}
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        content={this.props.t("general.cancel")}
                        disabled={actionStatus.key == "loading"}
                        onClick={onDismiss}
                    />
                    <Button
                        primary
                        onClick={() => this.sendTrialRequest()}
                        loading={actionStatus.key == "loading"}
                        content={this.props.t("general.submit")}
                    />
                </Modal.Actions>
            </Modal>
        );
    }
}

export default withContext(
    StationBoardTrialRequestModal,
    "api",
    "local",
    "i18n",
);
