import * as React from "react";
import {Form, Message, Segment} from "semantic-ui-react";
import {ApiResult, SCUConfig, SCUTTSVoice} from "@bryxinc/lunch/models";
import BryxApi from "@bryxinc/lunch/utils/ManagementApi";
import {
    WithApi,
    withContext,
    WithLocal,
    WithTranslation,
} from "@bryxinc/lunch/context";
import {InfoLabel} from "../../../components/infoLabel";
import {
    getEnumDropdowns,
    parseFloatOrNull,
    parseIntOrNull,
    str2e,
} from "@bryxinc/lunch/utils/functions";
import {LinkZoneModal} from "./zoneSettings/linkZoneModal";

interface GeneralSettingsProps
    extends WithLocal,
        WithTranslation,
        WithApi<BryxApi> {
    agencyId: string;
    config: SCUConfig;
    scuId: string;
}

interface GeneralSettingsState {
    actionState:
        | { key: "idle" }
        | { key: "modal" }
        | { key: "error"; message: string }
        | { key: "success" }
        | { key: "updating" };
    systemVolume: string;
    toneVolume: string;
    ttsVolume: string;
    ttsRate: string;
    ttsRepetitions: string;
    ttsVoice: SCUTTSVoice;
}

export class GeneralSettings extends React.Component<GeneralSettingsProps,
    GeneralSettingsState> {
    constructor(props: GeneralSettingsProps, context: any) {
        super(props, context);
        this.state = GeneralSettings.initialState(props);
    }

    private static initialState(
        props: GeneralSettingsProps,
    ): GeneralSettingsState {
        return {
            actionState: {key: "idle"},
            systemVolume: props.config.audio.systemVolume.toString(),
            toneVolume: props.config.audio.toneVolume.toString(),
            ttsVolume: props.config.tts.ttsVolume.toString(),
            ttsRate: (props.config.tts.rate || 100).toString(),
            ttsRepetitions: props.config.tts.repetitions.toString(),
            ttsVoice: props.config.tts.voice,
        };
    }

    onSave() {
        const {config} = this.props;
        this.setState({actionState: {key: "updating"}});
        this.props.api.editGeneralScuConfig(
            this.props.agencyId,
            this.props.scuId,
            parseInt(this.state.systemVolume, 10),
            parseInt(this.state.toneVolume, 10),
            this.state.ttsVoice,
            parseInt(this.state.ttsVolume, 10),
            parseInt(this.state.ttsRate, 10),
            parseInt(this.state.ttsRepetitions, 10),
            (result: ApiResult<null>) => {
                if (result.success) {
                    this.props.api.pushScuConfig(
                        this.props.agencyId,
                        this.props.scuId,
                        (resultPush: ApiResult<string>) => {
                            if (resultPush.success) {
                                this.setState({
                                    actionState: {key: "success"},
                                });
                            } else {
                                this.setState({
                                    actionState: {key: "error", message: resultPush.message},
                                });
                            }
                        },
                    );
                } else {
                    const errorMessage = this.props.t(
                        "stationAlerting.configurationModal.saveFailure",
                    );
                    this.setState({
                        actionState: {key: "error", message: result.message},
                    });
                }
            },
        );
    }

    handleDismiss() {
        if (this.state.actionState.key == "success") {
            this.setState({actionState: {key: "idle"}});
        }
    }

    openPairModal() {
        this.setState({
            actionState: { key: "modal" },
        });
    }

    closePairModal() {
        this.setState({
            actionState: { key: "idle" },
        });
    }

    static numberValid(
        input: string | null,
        min: number,
        max: number,
        allowNull = false,
    ): boolean {
        if (input == null) {
            return allowNull;
        }

        const num =
            input.indexOf(".") > 0 ? parseFloatOrNull(input) : parseIntOrNull(input);
        return num != null && num >= min && num <= max;
    }

    render() {
        const {
            actionState,
            systemVolume,
            toneVolume,
            ttsVolume,
            ttsRate,
            ttsRepetitions,
            ttsVoice,
        } = this.state;
        const { scuId, agencyId, config } = this.props;
        const error =
            actionState.key == "error" ? (
                <Message negative content={actionState.message}/>
            ) : null;
        const success =
            actionState.key == "success" ? (
                <Message
                    onDismiss={this.handleDismiss.bind(this)}
                    positive
                    content={this.props.t(
                        "stationAlerting.configurationModal.saveConfirm",
                    )}
                />
            ) : null;

        return (
            <Segment.Group style={{height: "100%"}}>
                <Segment>
                    {error}
                    {success}
                    <Form>
                        <Form.Group>
                            <Form.Input
                                label={this.props.t(
                                    "stationAlerting.configurationModal.general.systemVolume",
                                )}
                                value={systemVolume}
                                onChange={(e, d) => this.setState({systemVolume: d.value})}
                                error={!GeneralSettings.numberValid(systemVolume, 0, 99)}
                            />
                            <Form.Input
                                label={
                                    <InfoLabel
                                        title={this.props.t(
                                            "stationAlerting.configurationModal.general.toneVolume",
                                        )}
                                        content={this.props.t(
                                            "stationAlerting.configurationModal.general.toneVolumeDescription",
                                        )}
                                    />
                                }
                                value={toneVolume}
                                onChange={(e, d) => this.setState({toneVolume: d.value})}
                                error={!GeneralSettings.numberValid(toneVolume, -80, 6)}
                            />
                            <Form.Input
                                label={
                                    <InfoLabel
                                        title={this.props.t(
                                            "stationAlerting.configurationModal.general.ttsVolume",
                                        )}
                                        content={this.props.t(
                                            "stationAlerting.configurationModal.general.ttsVolumeDescription",
                                        )}
                                    />
                                }
                                value={ttsVolume}
                                onChange={(e, d) => this.setState({ttsVolume: d.value})}
                                error={!GeneralSettings.numberValid(ttsVolume, -80, 6)}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Input
                                label={
                                    <InfoLabel
                                        title={this.props.t(
                                            "stationAlerting.configurationModal.general.ttsRate",
                                        )}
                                        content={this.props.t(
                                            "stationAlerting.configurationModal.general.ttsRateDescription",
                                        )}
                                    />
                                }
                                value={ttsRate}
                                onChange={(e, d) => this.setState({ttsRate: d.value})}
                                error={!GeneralSettings.numberValid(ttsRate, 50, 200)}
                            />
                            <Form.Input
                                label={this.props.t(
                                    "stationAlerting.configurationModal.general.ttsRepetitions",
                                )}
                                value={ttsRepetitions}
                                onChange={(e, d) => this.setState({ttsRepetitions: d.value})}
                                error={!GeneralSettings.numberValid(ttsRepetitions, 0, 10)}
                            />
                            <Form.Select
                                label={this.props.t(
                                    "stationAlerting.configurationModal.general.ttsVoice",
                                )}
                                value={SCUTTSVoice[ttsVoice]}
                                options={getEnumDropdowns(SCUTTSVoice)}
                                onChange={(e, d) =>
                                    this.setState({
                                        ttsVoice: str2e(d.value as string, SCUTTSVoice),
                                    })
                                }
                            />
                        </Form.Group>
                        <Form.Group>
                            {config.zones.length == 1 && (
                                <Form.Button
                                    icon="linkify"
                                    primary
                                    content={this.props.t(
                                        "stationAlerting.configurationModal.pairButtonText",
                                    )}
                                    disabled={this.state.actionState.key == "updating"}
                                    onClick={this.openPairModal.bind(this)}
                                    style={{marginTop: "-4px"}}
                                />
                            )}
                            <Form.Button
                                icon="save"
                                primary
                                content={this.props.t(
                                    "stationAlerting.configurationModal.saveButtonText",
                                )}
                                loading={this.state.actionState.key == "updating"}
                                onClick={this.onSave.bind(this)}
                                style={{marginTop: "-4px"}}
                            />
                        </Form.Group>
                    </Form>
                </Segment>
                <LinkZoneModal
                    viewStatus={actionState.key == "modal" ? { key: "visible", zoneId: config.zones[0].id, scuId, agencyId } : { key: "hidden" }}
                    onDismiss={this.closePairModal.bind(this)}
                    local={this.props.local}
                    api={this.props.api}
                />
            </Segment.Group>
        );
    }
}

export default withContext(GeneralSettings, "api", "local");
