import * as React from "react";
import {
    Button,
    Input,
    Loader,
    Segment,
    Message,
    Modal,
} from "semantic-ui-react";
import {EmailRegistration} from "@bryxinc/lunch/models";
import {nullIfBlank} from "@bryxinc/lunch/utils/functions";

import {RouteComponentProps} from "react-router";
import BryxApi from "@bryxinc/lunch/utils/ManagementApi";
import {
    withContext,
    WithTranslation,
    WithLocal,
    WithApi,
} from "@bryxinc/lunch/context";

export type EditEmailAlertsModalViewStatus =
    | { key: "hidden" }
    | { key: "shown"; clientId: string };

interface EditEmailAlertsModalProps
    extends RouteComponentProps<any>,
        WithTranslation,
        WithLocal,
        WithApi<BryxApi> {
    viewStatus: EditEmailAlertsModalViewStatus;
    onClose: () => void;
}

interface EditEmailAlertsModalState {
    loadStatus:
        | { key: "loading" }
        | { key: "success"; emails: EmailRegistration[] }
        | { key: "error"; message: string };
    actionStatus:
        | { key: "ready" }
        | { key: "loading"; action: "creating" | "removing" }
        | { key: "error"; message: string };
    newEmail: string | null;
}

export class EditEmailAlertsModal extends React.Component<EditEmailAlertsModalProps,
    EditEmailAlertsModalState> {
    constructor(props: EditEmailAlertsModalProps, context: any) {
        super(props, context);
        this.state = this.getInitialState();
    }

    private getInitialState(): EditEmailAlertsModalState {
        return {
            loadStatus: {key: "loading"},
            actionStatus: {key: "ready"},
            newEmail: null,
        };
    }

    private loadRegistrations() {
        if (this.props.viewStatus.key != "shown") {
            return;
        }
        this.setState({loadStatus: {key: "loading"}});
        this.props.api.getClientEmailRegistrations(
            this.props.viewStatus.clientId,
            (result) => {
                if (result.success == true) {
                    this.setState({
                        loadStatus: {key: "success", emails: result.value},
                    });
                } else {
                    this.props.local.logWarn(
                        `Failed to load email registrations: ${
                            result.debugMessage || result.message
                        }`,
                    );
                    this.setState({
                        loadStatus: {key: "error", message: result.message},
                    });
                }
            },
        );
    }

    componentDidMount() {
        this.loadRegistrations();
    }

    componentWillReceiveProps(nextProps: EditEmailAlertsModalProps) {
        if (
            this.props.viewStatus.key == "hidden" &&
            nextProps.viewStatus.key == "shown"
        ) {
            this.setState(this.getInitialState(), () => this.loadRegistrations());
        }
    }

    private createRegistration() {
        const {newEmail} = this.state;
        const {viewStatus} = this.props;
        if (viewStatus.key != "shown" || newEmail == null) {
            return;
        }

        this.setState({actionStatus: {key: "loading", action: "creating"}});
        this.props.api.createClientEmailRegistration(
            viewStatus.clientId,
            newEmail,
            (result) => {
                if (result.success == true) {
                    this.setState((prevState: EditEmailAlertsModalState) => {
                        prevState.actionStatus = {key: "ready"};
                        prevState.newEmail = null;
                        if (prevState.loadStatus.key == "success") {
                            prevState.loadStatus.emails.push(result.value);
                        }
                        return prevState;
                    });
                } else {
                    this.props.local.logWarn(
                        `Failed to create client email registration: ${
                            result.debugMessage || result.message
                        }`,
                    );
                    this.setState({
                        actionStatus: {key: "error", message: result.message},
                    });
                }
            },
        );
    }

    private removeRegistration(deviceId: string) {
        const {viewStatus} = this.props;
        if (viewStatus.key != "shown") {
            return;
        }

        this.setState({actionStatus: {key: "loading", action: "removing"}});
        this.props.api.removeClientEmailRegistration(
            viewStatus.clientId,
            deviceId,
            (result) => {
                if (result.success == true) {
                    this.setState((prevState: EditEmailAlertsModalState) => {
                        prevState.actionStatus = {key: "ready"};
                        if (prevState.loadStatus.key == "success") {
                            prevState.loadStatus.emails = prevState.loadStatus.emails.filter(
                                (d) => d.id != deviceId,
                            );
                        }
                        return prevState;
                    });
                } else {
                    this.props.local.logWarn(
                        `Failed to remove client email registration: ${
                            result.debugMessage || result.message
                        }`,
                    );
                    this.setState({
                        actionStatus: {key: "error", message: result.message},
                    });
                }
            },
        );
    }

    private renderModalContent(): JSX.Element | null {
        const {viewStatus} = this.props;
        const {loadStatus, actionStatus, newEmail} = this.state;
        if (viewStatus.key == "hidden") {
            return null;
        }

        if (loadStatus.key == "loading") {
            return <Loader className="dark" active/>;
        } else if (loadStatus.key == "error") {
            return <Message negative content={loadStatus.message}/>;
        }

        return (
            <div>
                <Message
                    info
                    icon="help circle"
                    header={this.props.t("members.users.editEmailAlertsModal.infoTitle")}
                    list={[
                        this.props.t("members.users.editEmailAlertsModal.infoBody1"),
                        this.props.t("members.users.editEmailAlertsModal.infoBody2"),
                        this.props.t("members.users.editEmailAlertsModal.infoBody3"),
                    ]}
                />
                <p></p>
                <Segment.Group>
                    {loadStatus.emails.map((email) => (
                        <Segment
                            key={email.id}
                            style={{display: "flex", alignItems: "center"}}
                        >
                            <span style={{flex: 1}}>{email.email}</span>
                            <Button
                                negative
                                icon="trash"
                                loading={
                                    actionStatus.key == "loading" &&
                                    actionStatus.action == "removing"
                                }
                                onClick={() => this.removeRegistration(email.id)}
                            />
                        </Segment>
                    ))}
                    <Segment style={{display: "flex"}}>
                        <Input
                            placeholder={this.props.t(
                                "members.users.editEmailAlertsModal.emailPlaceholder",
                            )}
                            value={newEmail || ""}
                            onChange={(e, d) =>
                                this.setState({newEmail: nullIfBlank(d.value)})
                            }
                            onKeyPress={(e: KeyboardEvent) => {
                                if (e.key == "Enter") {
                                    this.createRegistration();
                                }
                            }}
                            style={{flex: 1, marginRight: "10px"}}
                        />
                        <Button
                            positive
                            icon="plus"
                            loading={
                                actionStatus.key == "loading" &&
                                actionStatus.action == "creating"
                            }
                            disabled={newEmail == null}
                            onClick={() => this.createRegistration()}
                        />
                    </Segment>
                </Segment.Group>
                {actionStatus.key == "error" ? (
                    <Message negative content={actionStatus.message}/>
                ) : null}
            </div>
        );
    }

    render() {
        const {viewStatus} = this.props;
        return (
            <Modal
                size="small"
                open={viewStatus.key == "shown"}
                onClose={this.props.onClose}
            >
                <Modal.Header>
                    {this.props.t("members.users.editEmailAlertsModal.title")}
                </Modal.Header>
                <Modal.Content>{this.renderModalContent()}</Modal.Content>
                <Modal.Actions>
                    <Button
                        content={this.props.t("general.done")}
                        onClick={this.props.onClose}
                    />
                </Modal.Actions>
            </Modal>
        );
    }
}

export default withContext(EditEmailAlertsModal, "api", "local", "i18n");
