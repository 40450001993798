import * as React from "react";
import { useState, useCallback, useEffect } from "react";
import { Button, Modal, Table, Input, Select, Icon } from "semantic-ui-react";
import {
  AgencyGroup,
  AlertOverrideType,
  GroupAlertOverride as RawGroupAlertOverride,
} from "@bryxinc/lunch/models";
import { useTranslation, useApi } from "@bryxinc/lunch/context";

export type EditOverridesModalViewStatus =
  | { key: "hidden" }
  | { key: "shown"; group: AgencyGroup };

interface EditOverridesModalProps {
  agencyId: string;
  viewStatus: EditOverridesModalViewStatus;

  onDismiss(reload: boolean): void;
}

enum DirtyType {
  modified,
  created,
}

type GroupAlertOverride = RawGroupAlertOverride & {
  dirty?: DirtyType;
};

function dirty(groupAlertOverride: GroupAlertOverride) {
  if (!groupAlertOverride.dirty) {
    groupAlertOverride.dirty = DirtyType.modified;
  }
}

// https://gitlab.bryx.com/bryx911/ios/bryx911-ios/-/blob/3b6ebc6a44e30e2bb9592d0ff9cb9ac4da469cfa/AlertToneFileManager.swift#L88-110

const ALERT_TONES = [
  // Not a real tone
  "user",
  "none",
  "silence",
  "standard",
  "standard1k",
  "continuous",
  "station51",
  "BryxTone",
  "dtmf",
  "henf",
  "251",
  "254",
  "068",
  "1465-950",
  "bell",
  "chirp",
  "tone1",
  "tone2",
  "standard-long",
  "sd10-long",
];

interface OverrideRowProps {
  groupAlertOverride: GroupAlertOverride;

  onChange(groupAlertOverride: GroupAlertOverride): void;
  onDelete(groupAlertOverride: GroupAlertOverride): void;
  onUp(groupAlertOverride: GroupAlertOverride): void;
  onDown(groupAlertOverride: GroupAlertOverride): void;
}
function OverrideRow(props: OverrideRowProps) {
  const { groupAlertOverride, onChange, onDelete, onUp, onDown } = props;
  const { t } = useTranslation();

  const thumbs = (
    <Button.Group>
      <Button
        icon
        compact
        attached="left"
        onClick={useCallback(() => {
          onDown(groupAlertOverride);
        }, [groupAlertOverride, onDown])}
      >
        <Icon name="angle down" />
      </Button>
      <Button
        icon
        compact
        attached="right"
        onClick={useCallback(() => {
          onUp(groupAlertOverride);
        }, [groupAlertOverride, onUp])}
      >
        <Icon name="angle up" />
      </Button>
    </Button.Group>
  );

  return (
    <Table.Row>
      <Table.Cell>
        <Input
          placeholder={t("groups.editOverrides.description")}
          value={groupAlertOverride.description}
          onChange={useCallback(
            (_event, data) => {
              groupAlertOverride.description = data.value;
              onChange(groupAlertOverride);
            },
            [groupAlertOverride, onChange]
          )}
        />
      </Table.Cell>
      <Table.Cell>
        <Select
          options={Object.keys(AlertOverrideType).map((typeKey) => ({
            key: typeKey,
            value: typeKey,
            text: typeKey,
          }))}
          value={groupAlertOverride.type}
          onChange={useCallback(
            (_event, data) => {
              groupAlertOverride.type = data.value;
              onChange(groupAlertOverride);
            },
            [groupAlertOverride, onChange]
          )}
        />
      </Table.Cell>
      <Table.Cell>
        <Input
          placeholder={t("groups.editOverrides.value")}
          value={groupAlertOverride.value}
          onChange={useCallback(
            (_event, data) => {
              groupAlertOverride.value = data.value;
              onChange(groupAlertOverride);
            },
            [groupAlertOverride, onChange]
          )}
        />
      </Table.Cell>
      {/* <Table.Cell>
        <Select
          placeholder={t("groups.editOverrides.alert")}
          value={groupAlertOverride.alert}
          options={ALERT_TONES.map((toneName) => ({
            key: toneName,
            value: toneName,
            text: toneName,
          }))}
          onChange={useCallback(
            (_event, data) => {
              groupAlertOverride.alert = data.value;
              onChange(groupAlertOverride);
            },
            [groupAlertOverride, onChange]
          )}
        />
        </Table.Cell> */}
      <Table.Cell>{thumbs}</Table.Cell>
      <Table.Cell>
        <Button
          compact
          color="red"
          onClick={useCallback(() => {
            onDelete(groupAlertOverride);
          }, [groupAlertOverride, onDelete])}
        >
          {t("general.delete")}
        </Button>
      </Table.Cell>
    </Table.Row>
  );
}

export function EditOverridesModal(props: EditOverridesModalProps) {
  const { agencyId, viewStatus, onDismiss } = props;
  const { t } = useTranslation();
  // Yucky:
  const [overrides, setOverrides] = useState(
    (
      ((viewStatus.key == "shown" && viewStatus.group.alertOverrides) ||
        []) as GroupAlertOverride[]
    ).sort((a, b) => b.priority - a.priority)
  );
  useEffect(() => {
    setOverrides(
      (
        (viewStatus.key == "shown" && viewStatus.group.alertOverrides) ||
        []
      ).sort(
        (a: GroupAlertOverride, b: GroupAlertOverride) =>
          a.priority - b.priority
      )
    );
  }, [viewStatus.key == "shown" && viewStatus.group.alertOverrides]);
  const [deleted, setDeleted] = useState([] as GroupAlertOverride[]);

  const onChange = useCallback((groupAlertOverride) => {
    setOverrides((oldOverrides) => {
      const overrides = Array.from(oldOverrides);
      for (let i = 0; i < overrides.length; ++i) {
        if (overrides[i].id == groupAlertOverride.id) {
          overrides[i] = groupAlertOverride;
          dirty(overrides[i]);
          break;
        }
      }
      return overrides;
    });
  }, []);
  const onDelete = useCallback((groupAlertOverride) => {
    setOverrides((oldOverrides) => {
      const overrides = Array.from(oldOverrides);
      overrides.splice(overrides.indexOf(groupAlertOverride), 1);
      for (let i = 0; i < overrides.length; ++i) {
        if (overrides[i].priority > groupAlertOverride.priority) {
          --overrides[i].priority;
          dirty(overrides[i]);
        }
      }
      if (groupAlertOverride.dirty != DirtyType.created) {
        setDeleted((deleted) => [groupAlertOverride].concat(deleted));
      }
      return overrides;
    });
  }, []);
  const onUp = useCallback((groupAlertOverride) => {
    setOverrides((oldOverrides) => {
      const overrides = Array.from(oldOverrides);
      const index = overrides.indexOf(groupAlertOverride);
      if (index == 0) return overrides;
      const oldPriority = overrides[index - 1].priority;
      overrides[index - 1].priority = groupAlertOverride.priority;
      dirty(overrides[index - 1]);
      groupAlertOverride.priority = oldPriority;
      dirty(groupAlertOverride);
      overrides[index] = overrides[index - 1];
      overrides[index - 1] = groupAlertOverride;

      return overrides;
    });
  }, []);
  const onDown = useCallback(
    (groupAlertOverride) => {
      const index = overrides.indexOf(groupAlertOverride);
      if (index == overrides.length - 1) {
        return;
      }
      onUp(overrides[index + 1]);
    },
    [overrides]
  );
  const { api } = useApi();
  const commitChanges = useCallback(() => {
    if (viewStatus.key == "hidden") return;
    const promises = [];
    for (const groupAlertOverride of overrides) {
      if (groupAlertOverride.dirty == DirtyType.created) {
        promises.push(
          new Promise((resolve, reject) => {
            api.createGroupAlertOverride(
              agencyId,
              viewStatus.group.id,
              groupAlertOverride,
              (result) => {
                if (result.success) {
                  resolve(result.body);
                } else {
                  reject(result.reason);
                }
              }
            );
          })
        );
      } else if (groupAlertOverride.dirty == DirtyType.modified) {
        promises.push(
          new Promise((resolve, reject) => {
            api.editGroupAlertOverride(
              agencyId,
              viewStatus.group.id,
              groupAlertOverride,
              (result) => {
                if (result.success) {
                  resolve(result.body);
                } else {
                  reject(result.reason);
                }
              }
            );
          })
        );
      }
    }
    for (const groupAlertOverride of deleted) {
      promises.push(
        new Promise((resolve, reject) => {
          api.deleteGroupAlertOverride(
            agencyId,
            viewStatus.group.id,
            groupAlertOverride.id,
            (result) => {
              if (result.success) {
                resolve(result.body);
              } else {
                reject(result.reason);
              }
            }
          );
        })
      );
    }
    Promise.all(promises).then(() => onDismiss(true));
  }, [overrides, viewStatus, deleted]);

  const rows = overrides.map((groupAlertOverride) => (
    <OverrideRow
      key={groupAlertOverride.id}
      groupAlertOverride={groupAlertOverride}
      onChange={onChange}
      onDelete={onDelete}
      onUp={onUp}
      onDown={onDown}
    />
  ));

  const addRow = useCallback(() => {
    setOverrides((oldOverrides) => {
      const overrides = Array.from(oldOverrides);
      const lowestPriority = overrides.length
        ? overrides[overrides.length - 1].priority
        : 0;
      for (let i = 0; i < overrides.length; ++i) {
        ++overrides[i].priority;
        dirty(overrides[i]);
      }
      const newOverride = new RawGroupAlertOverride(
        Date.now().toString(),
        "",
        AlertOverrideType.id,
        "",
        lowestPriority,
        // https://bryx.slack.com/archives/C0G3QNREK/p1674164776600259?thread_ts=1674151682.033219&cid=C0G3QNREK
        // Travis commands it
        "tone"
      ) as GroupAlertOverride;
      newOverride.dirty = DirtyType.created;
      overrides.push(newOverride);
      return overrides;
    });
  }, []);

  const content = (
    <Table>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>
            {t("groups.editOverrides.description")}
          </Table.HeaderCell>
          <Table.HeaderCell>{t("groups.editOverrides.type")}</Table.HeaderCell>
          <Table.HeaderCell>{t("groups.editOverrides.value")}</Table.HeaderCell>
          <Table.HeaderCell>
            {t("groups.editOverrides.priority")}
          </Table.HeaderCell>
          <Table.HeaderCell>{t("general.delete")}</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>{rows}</Table.Body>
    </Table>
  );
  const modal = (
    <Modal
      size="large"
      open={viewStatus.key == "shown"}
      onClose={useCallback(() => onDismiss(true), [onDismiss])}
    >
      <Modal.Header>
        {t("groups.editOverrides.header", {
          replace: {
            groupName: viewStatus.key == "shown" ? viewStatus.group.name : "",
          },
        })}
      </Modal.Header>
      <Modal.Content
        style={{
          backgroundColor: "#fbfbfb",
          height: "700px",
          padding: "1em",
        }}
      >
        {content}
        <Button color="green" onClick={addRow} icon labelPosition="right">
          {t("general.create")}
          <Icon name="plus" />
        </Button>
      </Modal.Content>
      <Modal.Actions>
        <Button
          onClick={useCallback(() => {
            onDismiss(false);
          }, [onDismiss])}
        >
          {t("general.cancel")}
        </Button>
        <Button color="green" onClick={commitChanges}>
          {t("general.save")}
        </Button>
      </Modal.Actions>
    </Modal>
  );
  return viewStatus.key == "shown" ? modal : null;
}
