import {GeoJsonObject} from "geojson";
import * as L from "leaflet";
import {GeoJSON} from "leaflet";
import * as React from "react";
import {FormEvent} from "react";
import {Dimmer, Form, Header, Icon, Loader} from "semantic-ui-react";
import {BryxGeoJSONLayer} from "../../components/bryxGeoJSONLayer";
import {BryxMap} from "../../components/bryxMap";
import {ApiResult, AgencyRegion, Geocode} from "@bryxinc/lunch/models";

import {LocationServicesProps} from "./locationServicesPage";
import {withContext} from "@bryxinc/lunch/context";

interface GeocodeTesterState {
    location: string | null;
    city: string | null;
    state: string | null;
    boundary: GeoJsonObject | null;
    status: GeocodeTabStatus;
}

type GeocodeTabStatus =
    | { key: "init" }
    | { key: "ready" }
    | { key: "sending" }
    | { key: "success"; geocodeLocation: Geocode }
    | { key: "error" };

export class GeocodeTesterTab extends React.Component<LocationServicesProps,
    GeocodeTesterState> {
    constructor(props: any, context: any) {
        super(props, context);

        this.initAgency(null, props);

        this.state = {
            location: null,
            city: null,
            state: null,
            boundary: null,
            status: {key: "init"},
        };
    }

    private initAgency(statusKey: "init" | null, props: LocationServicesProps) {
        if (statusKey) {
            this.setState({
                status: {key: "init"},
            });
        }

        this.props.api.getAgencyRegion(
            props.selectedAgency.id,
            (result: ApiResult<AgencyRegion>) => {
                this.setState({
                    status: {key: "ready"},
                    city: result.success ? result.value.city : null,
                    state: result.success ? result.value.state : null,
                    boundary: result.success ? result.value.bufferedBoundary : null,
                });
            },
        );
    }

    componentWillReceiveProps(
        nextProps: LocationServicesProps,
        nextContext: any,
    ) {
        if (nextProps.selectedAgency.id != this.props.selectedAgency.id) {
            this.initAgency("init", nextProps);
        }
    }

    private geocodeOnClick(): void {
        this.setState({
            status: {key: "sending"},
        });

        const agencyId = this.props.selectedAgency.id;
        // Safe to assert, we can't get here without checking all inputs
        const location = this.state.location!;
        const city = this.state.city!;
        const state = this.state.state!;

        this.props.api.geocode(
            agencyId,
            location.replace(/^\s+|\s+$/g, ""),
            city.replace(/^\s+|\s+$/g, ""),
            state.replace(/^\s+|\s+$/g, ""),
            (result: ApiResult<Geocode>) => {
                if (result.success == true) {
                    this.setState({
                        status: {
                            key: "success",
                            geocodeLocation: result.value,
                        },
                    });
                } else {
                    this.props.local.logWarn(
                        `Failed to geocode location: ${
                            result.debugMessage || result.message
                        }`,
                    );
                    this.setState({
                        status: {key: "error"},
                    });
                }
            },
        );
    }

    private onSubmit(e: FormEvent<any>) {
        e.preventDefault();
        this.geocodeOnClick();
    }

    private formValid(): boolean {
        return (
            this.state.location != null &&
            this.state.city != null &&
            this.state.state != null
        );
    }

    private onChangeLocation(event: any) {
        this.setState({
            location: event.target.value,
        });
    }

    private onChangeCity(event: any) {
        this.setState({
            city: event.target.value,
        });
    }

    private onChangeState(event: any) {
        this.setState({
            state: event.target.value,
        });
    }

    render() {
        const isLoading =
            this.state.status.key == "sending" || this.state.status.key == "init";
        const dimmerActive = this.state.status.key != "success";

        const dimmerContent = (() => {
            switch (this.state.status.key) {
                case "init":
                case "sending":
                    return <Loader active/>;
                case "ready":
                    return (
                        <Header as="h2">
                            <Icon name="search"/>
                            {this.props.t("locationServices.geocode.searchForLocation")}
                        </Header>
                    );
                case "error":
                    return (
                        <Header as="h2">
                            <Icon name="x"/>
                            {this.props.t("locationServices.geocode.noResults")}
                        </Header>
                    );
                case "success":
                    return null;
            }
        })();

        const bounds = (() => {
            switch (this.state.status.key) {
                case "init":
                    return undefined;
                case "ready":
                case "sending":
                case "error":
                    return (
                        (this.state.boundary &&
                            L.geoJSON(this.state.boundary).getBounds()) ||
                        undefined
                    );
                case "success":
                    return GeoJSON.coordsToLatLng(
                        this.state.status.geocodeLocation.centroid.coordinates as [
                            number,
                            number,
                        ],
                    ).toBounds(300);
            }
        })();

        return (
            <div
                className="underHorizNavContent"
                style={{
                    display: "flex",
                    flexDirection: "column",
                    padding: "40px 60px 40px 60px",
                }}
            >
                <Form
                    onSubmit={this.onSubmit.bind(this)}
                    style={{marginBottom: "20px"}}
                >
                    <Form.Group inline>
                        <Form.Input
                            type="text"
                            label={this.props.t("locationServices.geocode.location")}
                            placeholder={this.props.t("locationServices.geocode.location")}
                            value={this.state.location || ""}
                            onChange={this.onChangeLocation.bind(this)}
                            disabled={isLoading}
                        />
                        <Form.Input
                            type="text"
                            label={this.props.t("locationServices.geocode.city")}
                            placeholder={this.props.t("locationServices.geocode.city")}
                            value={this.state.city || ""}
                            onChange={this.onChangeCity.bind(this)}
                            disabled={isLoading}
                        />
                        <Form.Input
                            type="text"
                            label={this.props.t("locationServices.geocode.state")}
                            placeholder={this.props.t("locationServices.geocode.state")}
                            value={this.state.state || ""}
                            onChange={this.onChangeState.bind(this)}
                            disabled={isLoading}
                        />
                        <Form.Button
                            primary
                            disabled={!this.formValid() || isLoading}
                            onClick={this.geocodeOnClick.bind(this)}
                        >
                            {this.props.t("locationServices.geocode.mapLocation")}
                        </Form.Button>
                    </Form.Group>
                </Form>

                <Dimmer.Dimmable
                    blurring
                    dimmed={dimmerActive}
                    style={{display: "flex", flex: 1}}
                >
                    <Dimmer active={dimmerActive} inverted>
                        {dimmerContent}
                    </Dimmer>
                    <BryxMap {...this.props} bounds={bounds}>
                        {this.state.status.key == "success" ? (
                            <BryxGeoJSONLayer
                                {...this.props}
                                geojson={this.state.status.geocodeLocation.location}
                            />
                        ) : null}
                        {this.state.status.key == "success" &&
                        this.state.status.geocodeLocation.location.type != "Point" ? (
                            <BryxGeoJSONLayer
                                {...this.props}
                                geojson={this.state.status.geocodeLocation.centroid}
                            />
                        ) : null}
                    </BryxMap>
                </Dimmer.Dimmable>
            </div>
        );
    }
}

export default withContext(GeocodeTesterTab, "api", "local", "i18n");
