import * as i18n from "i18next";
import * as React from "react";
import {Button, Form, Loader, Message, Modal} from "semantic-ui-react";
import {
    AgencyGroup,
    AgencyGroupTypeEnum,
    ResponseOptionTypeEnum,
} from "@bryxinc/lunch/models";
import {RespondOptionSelectItem} from "../members/respondToJoinRequestModal";
import {SelectPermissions} from "../members/selectPermissions";
import {SelectResponseOptions} from "../members/selectResponseOptions";
import BryxApi from "@bryxinc/lunch/utils/ManagementApi";
import {RouteComponentProps} from "react-router";
import {
    withContext,
    WithTranslation,
    WithLocal,
    WithApi,
} from "@bryxinc/lunch/context";

export type AddAllMembersModalViewStatus =
    | { key: "hidden" }
    | { key: "shown"; group: AgencyGroup };

interface AddAllMembersModalProps
    extends RouteComponentProps<any>,
        WithTranslation,
        WithLocal,
        WithApi<BryxApi> {
    agencyId: string;

    onDismiss(reload: boolean): void;

    viewStatus: AddAllMembersModalViewStatus;
}

interface AddAllMembersModalState {
    status:
        | { key: "loading" }
        | { key: "ready"; responseOptions: RespondOptionSelectItem[] }
        | { key: "error"; message: string };
    actionStatus:
        | { key: "loading" }
        | { key: "ready" }
        | { key: "error"; message: string };
    canSend: boolean;
    canRespond: boolean;
}

export class AddAllMembersModal extends React.Component<AddAllMembersModalProps,
    AddAllMembersModalState> {
    constructor(props: AddAllMembersModalProps, context: any) {
        super(props, context);

        this.state = AddAllMembersModal.getInitialState();
    }

    static getInitialState(): AddAllMembersModalState {
        return {
            canSend: false,
            canRespond: false,
            actionStatus: {key: "ready"},
            status: {key: "loading"},
        };
    }

    private loadResponseOptions() {
        this.props.api.getAgencyResponseOptions(
            this.props.agencyId,
            null,
            [ResponseOptionTypeEnum.positive, ResponseOptionTypeEnum.negative],
            (result) => {
                if (result.success == true) {
                    this.setState({
                        status: {
                            key: "ready",
                            responseOptions: SelectResponseOptions.getWrappedResponseOptions(
                                result.value,
                            ),
                        },
                    });
                } else {
                    this.props.local.logWarn(
                        `Failed to get agency response options: ${result.debugMessage}`,
                    );
                    this.setState({
                        status: {key: "error", message: result.message},
                    });
                }
            },
        );
    }

    componentWillReceiveProps(newProps: AddAllMembersModalProps) {
        if (
            this.props.viewStatus.key == "hidden" &&
            newProps.viewStatus.key == "shown"
        ) {
            // Opening modal
            this.setState(AddAllMembersModal.getInitialState(), () => {
                this.loadResponseOptions();
            });
        }
    }

    private addAllMembers() {
        const {viewStatus} = this.props;
        const {canSend, canRespond, status} = this.state;

        if (viewStatus.key == "shown" && status.key == "ready") {
            const selectedResponseOptions = status.responseOptions
                .filter((r) => r.selected)
                .map((r) => r.responseOption);
            this.setState({
                actionStatus: {key: "loading"},
            });

            if (viewStatus.group.type == AgencyGroupTypeEnum.dispatch) {
                this.props.api.addAllClientsToDispatchGroup(
                    this.props.agencyId,
                    viewStatus.group.id,
                    selectedResponseOptions,
                    canSend,
                    canRespond,
                    (result) => {
                        if (result.success == true) {
                            this.setState(
                                {
                                    actionStatus: {key: "ready"},
                                },
                                () => this.props.onDismiss(true),
                            );
                        } else {
                            this.props.local.logWarn(
                                `Failed to add all members: ${result.debugMessage}`,
                            );
                            this.setState({
                                actionStatus: {key: "error", message: result.message},
                            });
                        }
                    },
                );
            } else {
                this.props.api.addAllClientsToMessagingGroup(
                    this.props.agencyId,
                    viewStatus.group.id,
                    canSend,
                    (result) => {
                        if (result.success == true) {
                            this.setState(
                                {
                                    actionStatus: {key: "ready"},
                                },
                                () => this.props.onDismiss(true),
                            );
                        } else {
                            this.props.local.logWarn(
                                `Failed to add all members: ${result.debugMessage}`,
                            );
                            this.setState({
                                actionStatus: {key: "error", message: result.message},
                            });
                        }
                    },
                );
            }
        }
    }

    private toggleResponseOption(option: RespondOptionSelectItem) {
        this.setState((prevState) => {
            if (prevState.status.key == "ready") {
                prevState.status.responseOptions.forEach((o) => {
                    if (o.responseOption.id == option.responseOption.id) {
                        // Found response option
                        o.selected = !o.selected;
                    }
                });
            }
            return prevState;
        });
    }

    private canAdd(responseOptions: RespondOptionSelectItem[]) {
        if (
            this.props.viewStatus.key == "shown" &&
            this.props.viewStatus.group.type == AgencyGroupTypeEnum.messaging
        ) {
            return true;
        }

        const atLeastOneNegative =
            responseOptions.filter(
                (o) =>
                    o.responseOption.type == ResponseOptionTypeEnum.negative &&
                    o.selected == true,
            ).length > 0;
        const atLeastOnePositive =
            responseOptions.filter(
                (o) =>
                    o.responseOption.type == ResponseOptionTypeEnum.positive &&
                    o.selected == true,
            ).length > 0;

        return atLeastOneNegative && atLeastOnePositive;
    }

    render() {
        const {onDismiss, viewStatus} = this.props;
        const {actionStatus, canSend, canRespond, status} = this.state;

        const addButton =
            viewStatus.key == "shown" && status.key == "ready" ? (
                <Button
                    positive
                    loading={actionStatus.key == "loading"}
                    disabled={!this.canAdd(status.responseOptions)}
                    onClick={() => this.addAllMembers()}
                    content={this.props.t("groups.addAllMembers.addAll")}
                />
            ) : undefined;

        let content;
        if (status.key == "loading") {
            content = <Loader className="dark"/>;
        } else if (status.key == "error") {
            content = <Message negative content={status.message}/>;
        } else {
            if (viewStatus.key == "shown") {
                const memberCount =
                    viewStatus.group.agencyMembersCount && viewStatus.group.membersCount
                        ? viewStatus.group.agencyMembersCount -
                        viewStatus.group.membersCount
                        : 0;
                content = (
                    <div>
                        <Form>
                            {viewStatus.group.type == AgencyGroupTypeEnum.dispatch ? (
                                <SelectResponseOptions
                                    {...this.props}
                                    responseOptions={status.responseOptions}
                                    onSelect={(option) => this.toggleResponseOption(option)}
                                />
                            ) : undefined}
                            <SelectPermissions
                                {...this.props}
                                style={{marginBottom: "10px"}}
                                canSend={canSend}
                                canRespond={
                                    viewStatus.key == "shown" &&
                                    viewStatus.group.type == AgencyGroupTypeEnum.dispatch
                                        ? canRespond
                                        : undefined
                                }
                                onPermissionClick={(t, v) => {
                                    if (t == "send") {
                                        this.setState({
                                            canSend: v,
                                        });
                                    } else {
                                        this.setState({
                                            canRespond: v,
                                        });
                                    }
                                }}
                            />
                            <Message
                                style={{marginTop: "30px"}}
                                content={this.props.t(
                                    memberCount != 1
                                        ? "groups.addAllMembers.explain_plural"
                                        : "groups.addAllMembers.explain",
                                    {
                                        replace: {
                                            memberCount: memberCount,
                                            groupName: viewStatus.group.name,
                                        },
                                    },
                                )}
                            />
                        </Form>
                        {actionStatus.key == "error" ? (
                            <Message error content={actionStatus.message}/>
                        ) : undefined}
                    </div>
                );
            } else {
                content = undefined;
            }
        }

        return (
            <Modal open={viewStatus.key == "shown"} onClose={() => onDismiss(false)}>
                <Modal.Header>
                    {viewStatus.key == "shown"
                        ? this.props.t("groups.addAllMembers.header", {
                            replace: {groupName: viewStatus.group.name},
                        })
                        : null}
                </Modal.Header>
                <Modal.Content
                    style={{backgroundColor: "#fbfbfb", padding: "20px 30px"}}
                >
                    {content}
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        content={this.props.t("general.cancel")}
                        onClick={() => onDismiss(false)}
                    />
                    {addButton}
                </Modal.Actions>
            </Modal>
        );
    }
}

export default withContext(AddAllMembersModal, "api", "local", "i18n");
