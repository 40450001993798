import {Icon, LatLngBoundsExpression} from "leaflet";
import * as React from 'react';
import {LayersControl, LeafletEvents, Map, TileLayer} from "react-leaflet";

import BryxApi from '@bryxinc/lunch/utils/ManagementApi';
import {withContext, WithTranslation, WithLocal, WithApi} from '@bryxinc/lunch/context';
import {RouteComponentProps} from "react-router";

interface BryxMapProps extends LeafletEvents, RouteComponentProps, WithTranslation, WithLocal, WithApi<BryxApi> {
    bounds?: LatLngBoundsExpression;
    style?: React.CSSProperties;
    scrollWheelZoom?: boolean;
}

export class BryxMap extends React.Component<BryxMapProps, any> {
    public static readonly genericIcon = new Icon({
        iconUrl: "/resources/assets/generic_pin.svg",
        iconSize: [23, 45],
        iconAnchor: [11.5, 45],
    });

    public static readonly checkIcon = new Icon({
        iconUrl: "/resources/assets/check_pin.svg",
        iconSize: [23, 45],
        iconAnchor: [11.5, 45],
    });

    public static readonly stationIcon = new Icon({
        iconUrl: "/resources/assets/station_pin.svg",
        iconSize: [23, 45],
        iconAnchor: [11.5, 45],
    });

    public static readonly dotIcon = new Icon({
        iconUrl: "/resources/assets/dot_icon.svg",
        iconSize: [10, 10],
        iconAnchor: [5, 5],
    });
    public static readonly existingSiteSurveyIcon = new Icon({
        iconUrl: "/resources/assets/site-survey-icons/existing_site_survey_pin.svg",
        iconSize: [23, 45],
        iconAnchor: [11.5, 45],
    });

    public static readonly selectedExistingSiteSurveyIcon = new Icon({
        iconUrl: "/resources/assets/site-survey-icons/selected_existing_site_survey_pin.svg",
        iconSize: [23, 45],
        iconAnchor: [11.5, 45],
    });

    public static readonly newSiteSurveyIcon = new Icon({
        iconUrl: "/resources/assets/site-survey-icons/new_site_survey_pin.svg",
        iconSize: [23, 45],
        iconAnchor: [11.5, 45],
    });

    public static readonly selectedNewSiteSurveyIcon = new Icon({
        iconUrl: "/resources/assets/site-survey-icons/selected_new_site_survey_pin.svg",
        iconSize: [23, 45],
        iconAnchor: [11.5, 45],
    });

    private static readonly ACCESS_TOKEN = "pk.eyJ1IjoidGVicm93biIsImEiOiIzb0xlTjlzIn0.qRGtH1Q-ZAViez0fTPX9fg";
    private static readonly MB_URL = "https://api.tiles.mapbox.com/v4/{id}/{z}/{x}/{y}.png?access_token={accessToken}";
    private static readonly MB_ATTRIBUTION = 'Data &copy; <a href="http://bryx.com">Bryx, Inc.</a> | Imagery from <a href="http://mapbox.com/about/maps/">MapBox</a> | Map &copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>';
    private static readonly MB_STYLE_URL = "https://api.mapbox.com/styles/v1/{user}/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}";

    render() {
        return (
            <Map center={[0, 0]} zoom={13} zoomAnimation={false} className="bryxMap" {...this.props}>
                <LayersControl position="topright">
                    <LayersControl.BaseLayer checked name={this.props.t("map.streets")}>
                        <TileLayer
                            url={BryxMap.MB_STYLE_URL}
                            accessToken={BryxMap.ACCESS_TOKEN}
                            user="mapbox"
                            id="streets-v11"
                            attribution={BryxMap.MB_ATTRIBUTION}/>
                    </LayersControl.BaseLayer>
                    <LayersControl.BaseLayer checked name={this.props.t("map.outdoors")}>
                        <TileLayer
                          url={BryxMap.MB_STYLE_URL}
                          accessToken={BryxMap.ACCESS_TOKEN}
                          user="mapbox"
                          id="outdoors-v11"
                          attribution={BryxMap.MB_ATTRIBUTION}/>
                    </LayersControl.BaseLayer>
                    <LayersControl.BaseLayer checked name={this.props.t("map.light")}>
                        <TileLayer
                          url={BryxMap.MB_STYLE_URL}
                          accessToken={BryxMap.ACCESS_TOKEN}
                          user="mapbox"
                          id="light-v10"
                          attribution={BryxMap.MB_ATTRIBUTION}/>
                    </LayersControl.BaseLayer>
                    <LayersControl.BaseLayer name={this.props.t("map.dark")}>
                        <TileLayer
                          url={BryxMap.MB_STYLE_URL}
                          accessToken={BryxMap.ACCESS_TOKEN}
                          subdomains="bryx911.com"
                          user="mapbox"
                          id="dark-v10"
                          attribution={BryxMap.MB_ATTRIBUTION}/>
                    </LayersControl.BaseLayer>
                    <LayersControl.BaseLayer name={this.props.t("map.satellite")}>
                        <TileLayer
                          url={BryxMap.MB_STYLE_URL}
                          accessToken={BryxMap.ACCESS_TOKEN}
                          subdomains="bryx911.com"
                          user="mapbox"
                          id="satellite-v9"
                          attribution={BryxMap.MB_ATTRIBUTION}/>
                    </LayersControl.BaseLayer>
                    <LayersControl.BaseLayer checked name={this.props.t("map.hybrid")}>
                        <TileLayer
                          url={BryxMap.MB_STYLE_URL}
                          accessToken={BryxMap.ACCESS_TOKEN}
                          user="mapbox"
                          id="satellite-streets-v11"
                          attribution={BryxMap.MB_ATTRIBUTION}/>
                    </LayersControl.BaseLayer>
                    <LayersControl.BaseLayer checked name={this.props.t("map.navigationDay")}>
                        <TileLayer
                          url={BryxMap.MB_STYLE_URL}
                          accessToken={BryxMap.ACCESS_TOKEN}
                          user="mapbox"
                          id="navigation-day-v1"
                          attribution={BryxMap.MB_ATTRIBUTION}/>
                    </LayersControl.BaseLayer>
                    <LayersControl.BaseLayer checked name={this.props.t("map.navigationNight")}>
                        <TileLayer
                          url={BryxMap.MB_STYLE_URL}
                          accessToken={BryxMap.ACCESS_TOKEN}
                          user="mapbox"
                          id="navigation-night-v1"
                          attribution={BryxMap.MB_ATTRIBUTION}/>
                    </LayersControl.BaseLayer>
                </LayersControl>

                {this.props.children}
            </Map>
        );
    }
}

export default withContext(BryxMap, 'api', 'local', 'i18n');
