import * as React from "react";
import {Button, Form, Header, Icon, Message} from "semantic-ui-react";
import {SheetOverlay} from "../../components/sheet";
import {
    Client,
    AgencyGroupTypeEnum,
    ClientGroup,
    ResponseOptionTypeEnum,
} from "@bryxinc/lunch/models";

import {
    ConfirmRemoveGroupModal,
    ConfirmRemoveGroupModalViewStatus,
} from "./confirmRemoveGroupModal";
import {RespondOptionSelectItem} from "./respondToJoinRequestModal";
import {SelectPermissions} from "./selectPermissions";
import {SelectResponseOptions} from "./selectResponseOptions";

import {RouteComponentProps} from "react-router";
import BryxApi from "@bryxinc/lunch/utils/ManagementApi";
import {
    withContext,
    WithTranslation,
    WithLocal,
    WithApi,
} from "@bryxinc/lunch/context";

type EditGroupOverlayViewStatus =
    | { key: "hidden" }
    | { key: "shown"; agencyId: string; client: Client; group: ClientGroup };

interface EditGroupOverlayProps
    extends RouteComponentProps<any>,
        WithTranslation,
        WithLocal,
        WithApi<BryxApi> {
    viewStatus: EditGroupOverlayViewStatus;
    onEditGroup: () => void;
    onDismiss: () => void;
}

interface EditGroupOverlayState {
    updateStatus:
        | { key: "ready" }
        | { key: "loading" }
        | { key: "error"; message: string };
    canSend: boolean;
    canRespond: boolean;
    responseOptions: RespondOptionSelectItem[];
    overlay: "none" | "removeGroup";
}

export class EditGroupOverlay extends React.Component<EditGroupOverlayProps,
    EditGroupOverlayState> {
    constructor(props: EditGroupOverlayProps, context: any) {
        super(props, context);
        this.state = this.getDefaultState(props);
    }

    private getDefaultState(props: EditGroupOverlayProps): EditGroupOverlayState {
        const responseOptions =
            props.viewStatus.key == "shown" &&
            props.viewStatus.group.type == AgencyGroupTypeEnum.dispatch
                ? props.viewStatus.group.responseOptions.map((ro) => ({
                    responseOption: ro.toResponseOption(),
                    selected: ro.hasResponseOption,
                }))
                : [];
        const canSend =
            props.viewStatus.key == "shown"
                ? props.viewStatus.group.permissions.canSend
                : false;
        const canRespond =
            props.viewStatus.key == "shown" &&
            props.viewStatus.group.type == AgencyGroupTypeEnum.dispatch
                ? props.viewStatus.group.permissions.canRespond
                : false;
        return {
            updateStatus: {key: "ready"},
            canSend: canSend,
            canRespond: canRespond,
            responseOptions: responseOptions,
            overlay: "none",
        };
    }

    componentWillReceiveProps(nextProps: EditGroupOverlayProps) {
        if (
            this.props.viewStatus.key == "hidden" &&
            nextProps.viewStatus.key == "shown"
        ) {
            this.setState(this.getDefaultState(nextProps));
        }
    }

    private toggleResponseOption(option: RespondOptionSelectItem) {
        this.setState((prevState) => {
            prevState.responseOptions.forEach((o) => {
                if (o.responseOption.id == option.responseOption.id) {
                    o.selected = !o.selected;
                }
            });
            return prevState;
        });
    }

    private save() {
        if (this.props.viewStatus.key != "shown") {
            return;
        }
        const {group, client, agencyId} = this.props.viewStatus;
        const {canSend, canRespond, responseOptions} = this.state;
        const newResponseOptionIds = responseOptions
            .filter((ro) => ro.selected)
            .map((ro) => ro.responseOption.id);
        this.setState({updateStatus: {key: "loading"}});
        this.props.api.updateClientGroupMembership(
            agencyId,
            group.id,
            client.id,
            canSend,
            canRespond,
            newResponseOptionIds,
            (result) => {
                if (result.success == true) {
                    this.props.onEditGroup();
                } else {
                    this.props.local.logWarn(
                        `Failed to save send group membership: ${
                            result.debugMessage || result.message
                        }`,
                    );
                    this.setState({
                        updateStatus: {key: "error", message: result.message},
                    });
                }
            },
        );
    }

    private canSave() {
        const {responseOptions} = this.state;
        const {viewStatus} = this.props;
        if (
            viewStatus.key == "shown" &&
            viewStatus.group.type == AgencyGroupTypeEnum.messaging
        ) {
            return true;
        }

        const atLeastOneNegative =
            responseOptions.filter(
                (o) =>
                    o.responseOption.type == ResponseOptionTypeEnum.negative &&
                    o.selected == true,
            ).length > 0;
        const atLeastOnePositive =
            responseOptions.filter(
                (o) =>
                    o.responseOption.type == ResponseOptionTypeEnum.positive &&
                    o.selected == true,
            ).length > 0;

        return atLeastOneNegative && atLeastOnePositive;
    }

    private isDirty(): boolean {
        const {viewStatus} = this.props;
        if (viewStatus.key != "shown") {
            return false;
        }
        if (viewStatus.group.type == AgencyGroupTypeEnum.dispatch) {
            const responseOptionsMatch = viewStatus.group.responseOptions.every(
                (responseOption) => {
                    const match = this.state.responseOptions.filter(
                        (ro) => ro.responseOption.id == responseOption.id,
                    )[0];
                    return (
                        match != null && match.selected == responseOption.hasResponseOption
                    );
                },
            );
            const permissionsMatch =
                viewStatus.group.permissions.canRespond == this.state.canRespond &&
                viewStatus.group.permissions.canSend == this.state.canSend;
            return !responseOptionsMatch || !permissionsMatch;
        } else {
            return viewStatus.group.permissions.canSend != this.state.canSend;
        }
    }

    render() {
        let content = null;
        if (this.props.viewStatus.key == "shown") {
            const {group} = this.props.viewStatus;
            const {canSend, canRespond, responseOptions, updateStatus} = this.state;
            content = (
                <div
                    style={{height: "100%", display: "flex", flexDirection: "column"}}
                >
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "flex-start",
                            marginBottom: "1.25em",
                        }}
                    >
                        <Header as="h3" style={{margin: 0}}>
                            {group.name}
                        </Header>
                        <Icon
                            link
                            color="red"
                            name="trash"
                            size="large"
                            onClick={() => this.setState({overlay: "removeGroup"})}
                        />
                    </div>
                    <div
                        style={{flex: 1, padding: "1em", borderTop: "1px #efefef solid"}}
                    >
                        <Form>
                            {group.type == AgencyGroupTypeEnum.dispatch ? (
                                <SelectResponseOptions
                                    {...this.props}
                                    style={{marginBottom: "10px", marginTop: "20px"}}
                                    responseOptions={responseOptions}
                                    onSelect={(option) => this.toggleResponseOption(option)}
                                />
                            ) : null}
                            <SelectPermissions
                                {...this.props}
                                style={{marginBottom: 0}}
                                canSend={canSend}
                                canRespond={
                                    group.type == AgencyGroupTypeEnum.dispatch
                                        ? canRespond
                                        : undefined
                                }
                                onPermissionClick={(type, newValue) => {
                                    if (type == "send") {
                                        this.setState({canSend: newValue});
                                    } else {
                                        this.setState({canRespond: newValue});
                                    }
                                }}
                            />
                        </Form>
                        {updateStatus.key == "error" ? (
                            <Message negative content={updateStatus.message}/>
                        ) : null}
                    </div>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            alignItems: "center",
                        }}
                    >
                        <Button
                            content={this.props.t("general.cancel")}
                            disabled={updateStatus.key == "loading"}
                            onClick={this.props.onDismiss}
                            style={{marginRight: "10px", width: "85px"}}
                        />
                        <Button
                            positive
                            content={this.props.t("general.save")}
                            loading={updateStatus.key == "loading"}
                            disabled={!this.isDirty() || !this.canSave()}
                            onClick={() => this.save()}
                            style={{width: "85px"}}
                        />
                    </div>
                </div>
            );
        }
        const removeGroupViewStatus: ConfirmRemoveGroupModalViewStatus =
            this.state.overlay == "removeGroup" &&
            this.props.viewStatus.key == "shown"
                ? {
                    key: "shown",
                    group: this.props.viewStatus.group,
                    client: this.props.viewStatus.client,
                    agencyId: this.props.viewStatus.agencyId,
                }
                : {key: "hidden"};
        return (
            <SheetOverlay
                open={this.props.viewStatus.key == "shown"}
                accentColor={
                    this.props.viewStatus.key == "shown"
                        ? this.props.viewStatus.group.color
                        : undefined
                }
                onDismiss={this.props.onDismiss}
            >
                {content}
                <ConfirmRemoveGroupModal
                    {...this.props}
                    viewStatus={removeGroupViewStatus}
                    onConfirmRemove={this.props.onEditGroup}
                    onClose={() => this.setState({overlay: "none"})}
                />
            </SheetOverlay>
        );
    }
}

export default withContext(EditGroupOverlay, "api", "local", "i18n");
