import {InitOptions as Options} from "i18next";
import XHR from 'i18next-xhr-backend';
import queryString from "query-string";
import * as React from 'react';
import {RouteComponentProps} from "react-router";
import {Redirect, Route, Switch} from "react-router-dom";
import {SemanticICONS} from "semantic-ui-react";
import {AgencyErrorModal} from "./agencyErrorModal";
import {ContactSupportModal} from "./contactSupportModal";
import {Nav, NavBadgeOption} from "./sideNav";
import {SwitchAgencyModal, SwitchAgencyVisibility} from "./switchAgenciesModal";
import {badgeManager} from '../index';
import {AuthAgency} from '@bryxinc/lunch/models/auth';
import {AgencyPage} from "../pages/agency/agencyPage";
import {GroupsPage} from "../pages/groups/groupsPage";
import {JobsPage} from "../pages/jobs/jobsPage";
import {LocationServicesPage} from "../pages/locationServices/locationServicesPage";
import {MembersPage} from "../pages/members/membersPage";
import {StationAlertingPage} from "../pages/stationAlerting/stationAlertingPage";
import {StationBoardsPage} from "../pages/stationBoards/stationBoardsPage";
import {TopBar} from "./topBar";
import {BadgeManagerObserver, BadgeStatus} from "../utils/badgeManager";
import {ManageAuth as Auth, Agency, ApiResult} from '@bryxinc/lunch/models';
import BryxApi from '@bryxinc/lunch/utils/ManagementApi';
import * as colors from '@bryxinc/style/color';
import {SharedSupportUtils} from '@bryxinc/lunch/utils/functions';
import {withContext, WithTranslation, WithLocal, WithApi} from '@bryxinc/lunch/context';
import {SiteSurveyPage} from "../pages/siteSurvey/siteSurveyPage";
import {DispatchPage} from "../pages/dispatch/dispatchPage";


type Reason = 'forced' | 'manual' | 'noAuth';
type Status = { signedIn: true } | { signedIn: false, reason: Reason };

interface MainProps extends RouteComponentProps<{}, any, any>, WithTranslation, WithLocal, WithApi<BryxApi> {
}

interface MainState {
    isLoading: boolean;
    status: Status;
    switchAgencyModalStatus: SwitchAgencyVisibility;
    agencies: AuthAgency[];
    selectedAgencyId: string | null;
    invalidAgencyId: string | null;
    pendingJoinRequestsBadge: BadgeStatus;
}

interface NavItemOption {
    to: string;
    assetKey: string;
    i18nKey: string;
    badge?: NavBadgeOption;
}

interface NavListSection {
    i18nKey: string;
    items: NavItemOption[];
}


export class Main extends React.Component<MainProps, MainState> implements BadgeManagerObserver {
    private static readonly minimumSplashTime = 2.0 * 1000;

    constructor(props: MainProps, context: any) {
        super(props, context);

        this.state = {
            isLoading: props.local.isSignedIn(),
            status: this.getStatus(props),
            switchAgencyModalStatus: props.local.currentAgencyId == null ? 'forced' : 'hidden',
            agencies: [],
            selectedAgencyId: null, // Will be reset by session
            invalidAgencyId: null,
            pendingJoinRequestsBadge: badgeManager.pendingJoinRequestStatus, // ,
        }; // */
    }

    private getStatus(props: Readonly<MainProps>): Status {
      const signedIn: boolean = props.local.isSignedIn();
      if (signedIn) {
        return {signedIn: true};
      } else {
        if (props.location && props.location.pathname === '/') {
          return { signedIn: false, reason: 'noAuth' };
        } else {
          return { signedIn: false, reason: 'forced' };
        }
      }
    }

  componentDidMount() {
        this.props.api.setOnUnauthenticated(() => {
            console.log('onUnauthenticated function set by Main component placeholder.');
            this.props.local.clear();
            this.props.local.logInfo('Forced sign out occurred');

            this.setState({
              isLoading: false,
              status: { signedIn: false, reason: 'forced' },
            });
        });
        this.loadSession();

        this.props.i18n
            .use(XHR)
            .init({
                lng: this.props.local.get<string>("locale") || "en",
                backend: {
                    loadPath: "/resources/locales/{{lng}}.json",
                },
                fallbackLng: "en",
                interpolation: {escapeValue: false},
            } as Options, () => {
                this.loadSession();
            });

        badgeManager.registerObserver(this);
        AuthAgency.notifyReplace = (agency: any) => {
            this.setState(prevState => {
                return {
                    agencies: prevState.agencies.map(a => a.id == agency.id ? agency : a),
                };
            });
        };
    }

    componentWillUnmount() {
        badgeManager.unregisterObserver(this);
    }

    componentDidUpdate(prevProps: Readonly<MainProps>, prevState: Readonly<MainState>, snapshot?: any) {
      if (prevState.status.signedIn) {
        const newStatus = this.getStatus(this.props);
        if (!newStatus.signedIn) {
          this.setState({ status: newStatus });
        }
      }
    }

  badgeManagerDidChangeBadgeStatus(): void {
        this.setState({pendingJoinRequestsBadge: badgeManager.pendingJoinRequestStatus});
    }

    private loadSession() {
        if (this.props.local.isSignedIn()) {
            const preSessionTime = Date.now();
            this.props.api.session<Auth>((result: any) => {
                const postSessionTime = Date.now();
                if (result.success == true) {
                    this.props.local.logInfo("User successfully updated session");
                    // Only need to handle this case because of ^^^
                    if (this.props.local.showEula != true) {
                        setTimeout(() => {
                            const storedAgencyId = this.props.local.currentAgencyId;
                            const validStoredAgency = storedAgencyId != null ? result.value.agencies.filter((a: Agency) => a.id == storedAgencyId)[0] : null;
                            const sortedAgencies = result.value.agencies.slice().sort((a: Agency, b: Agency) => a.name.localeCompare(b.name));

                            const selectedAgency = validStoredAgency != null ? validStoredAgency.id : sortedAgencies[0].id;

                            if (this.requestedAgencyIdFromQuery() == null) {
                                badgeManager.startupJoinRequestBadger(selectedAgency);
                            }

                            this.setState({
                                isLoading: false,
                                status: { signedIn: true },
                                agencies: sortedAgencies,
                                switchAgencyModalStatus: (result.value.agencies.length > 1 && validStoredAgency == null) ? "forced" : "hidden",
                                selectedAgencyId: selectedAgency,
                            });
                        }, Math.max(Main.minimumSplashTime - (postSessionTime - preSessionTime), 0));
                    } else {
                        this.props.history.push("/eula", {
                            from: this.props.location,
                        });
                    }
                } else {
                    this.props.local.logWarn(`Failed to update session: ${result.debugMessage}`);
                }
            });
        }
    }

    private setSelectedAgencyId(id: string) {
        this.props.local.currentAgencyId = id;
        badgeManager.startupJoinRequestBadger(id);
        this.setState({
            selectedAgencyId: id,
        });
    }

    private requestedAgencyIdFromQuery(): string | null {
        if (!(this.props.location && this.props.location.search)) {
            return null;
        }
        const params: { agency?: string } | null = queryString.parse(this.props.location.search);
        if (params == null) {
            return null;
        }
        return params['agency'] ? params['agency'] : null;
    }

    componentWillReceiveProps() {
        const requestedId = this.requestedAgencyIdFromQuery();
        if (requestedId != null && this.state.agencies.length > 0) {
            if (this.isAgencyValidById(requestedId)) {
                this.setState({
                    switchAgencyModalStatus: "hidden",
                });
                this.setSelectedAgencyId(requestedId);
            } else {
                this.setState({
                    invalidAgencyId: requestedId,
                });
                this.setSelectedAgencyId(this.state.agencies[0].id);
            }
        }
    }

    private getSelectedAgency(): AuthAgency {
        return this.state.agencies.filter((d: AuthAgency) => d.id == this.state.selectedAgencyId)[0];
    }

    private isAgencyValidById(id: string): boolean {
        return this.state.agencies.some((d) => d.id == id);
    }

    private signoutComplete(result: ApiResult<null>): void {
        if (result.success == true) {
            this.props.local.clear();
            this.props.local.logInfo("User successfully signed out");
        } else {
            this.props.local.logWarn(`Signout failed: ${result.debugMessage || result.message}`);
        }

        this.setState({
          status: { signedIn: false, reason: 'manual' },
        });
    }

    private openAgencySwitcher(): void {
        this.setState({
            switchAgencyModalStatus: "manual",
        });
    }

    private dismissAgencyError() {
        this.setState({
            invalidAgencyId: null,
        });
    }

    render() {
        if (this.state.isLoading) {
            return (
                <div style={{backgroundColor: colors.red, height: "100%", width: "100%"}}>
                    <img id="loadingIcon" src="/resources/assets/logo_white.png" className="centerVertically"/>
                </div>
            );
        }

        if (!this.state.status.signedIn) {
            return (
                <Redirect to={{
                    pathname: "/login",
                    state: {
                        reason: this.state.status.reason,
                        from: this.state.status.reason != "manual" ? this.props.location : null,
                    },
                }}/>
            );
        }

        const requestedId = this.requestedAgencyIdFromQuery();
        if (requestedId != null && this.props.location != null) {
            return <Redirect to={this.props.location.pathname}/>;
        }

        function onAgencySelected(d: AuthAgency | null) {
            if (d != null) {
                this.setSelectedAgencyId(d.id);
            }
            this.setState({
                switchAgencyModalStatus: "hidden",
            });
        }

        const {pendingJoinRequestsBadge} = this.state;
        const navList: NavListSection[] = [
            {
                i18nKey: "sideNav.main",
                items: [
                    {to: "/agency", assetKey: "building", i18nKey: "agency"},
                    {
                        to: "/members",
                        assetKey: "users",
                        i18nKey: "members",
                        badge: pendingJoinRequestsBadge.key == "ready" && pendingJoinRequestsBadge.count > 0 ?
                            {type: "count", count: pendingJoinRequestsBadge.count}
                            : undefined
                    },
                    {to: "/groups", assetKey: "sitemap", i18nKey: "groups"},
                    {to: "/location-services", assetKey: "point", i18nKey: "locationServices"},
                    {to: "/jobs", assetKey: "alarm", i18nKey: "jobs"},
                    {to: "/site-surveys", assetKey: "map", i18nKey: "siteSurveys"},
                ]
            },
            {
                i18nKey: "sideNav.stationAlerting",
                items: [
                    {to: "/station-boards", assetKey: "tv", i18nKey: "stationBoards"},
                    {to: "/station-alerting", assetKey: "server", i18nKey: "stationAlerting"},
                    {to: "/dispatch", assetKey: "volume control phone", i18nKey: "dispatch"},
                ]
            }
        ]

        const selectedAgency = this.getSelectedAgency();

        const navItems = navList.map((l, i) => {
            return (
                <React.Fragment>
                    <Nav.SectionHeader content={this.props.t(l.i18nKey).toUpperCase()}
                                       style={{marginTop: (i == 0 ? "60px" : "30px")}}/>
                    <Nav.ItemList>
                        {l.items.map(o => {
                            return (
                                <Nav.Item key={o.to}
                                          to={o.to}
                                          icon={{type: "icon", name: o.assetKey as SemanticICONS, badge: o.badge}}
                                          content={this.props.t(`sideNav.${o.i18nKey}`)}/>
                            );
                        })}
                    </Nav.ItemList>
                </React.Fragment>
            );
        })

        return (
            <div id="topContent">
                <SwitchAgencyModal {...this.props}
                                   showState={this.state.switchAgencyModalStatus}
                                   selectedAgency={selectedAgency}
                                   agencyOptions={this.state.agencies}
                                   onAgencySelected={onAgencySelected.bind(this)}/>
                <ContactSupportModal {...this.props}/>
                <AgencyErrorModal {...this.props}
                                  invalidAgency={this.state.invalidAgencyId}
                                  onClose={this.dismissAgencyError.bind(this)}
                                  openContactSupportModal={() => SharedSupportUtils.onOpenSupport()}/>
                <Nav>
                    <Nav.Header>
                        <img src="/resources/assets/logo_white.png"
                             style={{marginRight: "5px"}}/> {this.props.t("branding.siteName")}
                    </Nav.Header>
                    <Nav.Body>
                        {navItems}
                    </Nav.Body>
                </Nav>
                <div id="contentWrapper">
                    <TopBar {...this.props}
                            currentAgency={selectedAgency}
                            signoutComplete={this.signoutComplete.bind(this)}
                            onSwitchAgency={this.openAgencySwitcher.bind(this)}
                            onContactSupport={() => SharedSupportUtils.onOpenSupport()}/>
                    <div id="pageWrapper">
                        <Switch>
                            {/* TODO: [Future] Add this back: <Route exact path="/dashboard" render={(props) => (<DashboardPage selectedAgency={selectedAgency} {...props}/>)}/>*/}
                            <Route path="/agency" render={(props) => (
                                <AgencyPage {...this.props} selectedAgency={selectedAgency} {...props}/>)}/>
                            <Route path="/members"
                                   render={(props) => (<MembersPage {...this.props} selectedAgency={selectedAgency}
                                                                    pendingJoinRequestsBadge={this.state.pendingJoinRequestsBadge}
                                                                    {...props}/>)}/>
                            <Route path="/groups" render={(props) => <GroupsPage {...this.props}
                                                                                 selectedAgency={selectedAgency} {...props}/>}/>
                            <Route path="/station-boards" render={(props) => <StationBoardsPage {...this.props}
                                                                                                selectedAgency={selectedAgency} {...props}/>}/>
                            <Route path="/station-alerting" render={(props) => <StationAlertingPage {...this.props}
                                                                                                    selectedAgency={selectedAgency} {...props}/>}/>
                            <Route path="/location-services" render={(props) => <LocationServicesPage {...this.props}
                                                                                                      selectedAgency={selectedAgency} {...props}/>}/>
                            <Route path="/jobs" render={(props) => <JobsPage {...this.props}
                                                                             selectedAgency={selectedAgency} {...props}/>}/>
                            <Route path="/site-surveys" render={(props) => <SiteSurveyPage {...this.props}
                                                                                           selectedAgency={selectedAgency} {...props}/>}/>
                            <Route path="/dispatch" render={(props) => <DispatchPage {...this.props}
                                                                                                    selectedAgency={selectedAgency} {...props}/>}/>
                            <Redirect to="/agency"/>
                        </Switch>
                    </div>
                </div>
            </div>
        );
    }
}

export default withContext(Main, 'api', 'local', 'i18n');
