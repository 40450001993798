import * as i18n from "i18next";
import * as React from "react";
import {Button, Modal} from "semantic-ui-react";
import {AgencyGroup} from "@bryxinc/lunch/models";
import {CopyField} from "../../components/copyField";

import BryxApi from "@bryxinc/lunch/utils/ManagementApi";
import {RouteComponentProps} from "react-router";
import {
    withContext,
    WithTranslation,
    WithLocal,
    WithApi,
} from "@bryxinc/lunch/context";

export type GetJoinLinkModalViewStatus =
    | { key: "hidden" }
    | { key: "shown"; group: AgencyGroup };

interface GetJoinLinkModalProps
    extends RouteComponentProps<any>,
        WithTranslation,
        WithLocal,
        WithApi<BryxApi> {
    agencyId: string;

    onDismiss(): void;

    viewStatus: GetJoinLinkModalViewStatus;
}

export class GetJoinLinkModal extends React.Component<GetJoinLinkModalProps,
    {}> {
    constructor(props: GetJoinLinkModalProps, context: any) {
        super(props, context);
    }

    render() {
        const {onDismiss, viewStatus} = this.props;

        let groupId = null;
        if (viewStatus.key == "shown") {
            groupId = viewStatus.group.id;
        }

        return (
            <Modal
                size="small"
                open={viewStatus.key == "shown"}
                onClose={() => onDismiss()}
            >
                <Modal.Header>
                    {viewStatus.key == "shown"
                        ? this.props.t("groups.specificGroupActions.getJoinLink")
                        : null}
                </Modal.Header>
                <Modal.Content style={{backgroundColor: "#fbfbfb"}}>
                    <div>
                        <CopyField
                            style={{marginBottom: "15px", display: "flex"}}
                            title="Link"
                            value={this.props.api.baseUrl + "/join/" + groupId}
                        />
                        <p>{this.props.t("groups.getJoinLink.details")}</p>
                    </div>
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        content={this.props.t("general.done")}
                        onClick={() => onDismiss()}
                    />
                </Modal.Actions>
            </Modal>
        );
    }
}

export default withContext(GetJoinLinkModal, "api", "local", "i18n");
