import * as React from "react";
import {Button, Form, Message, Modal} from "semantic-ui-react";

import BryxApi from "@bryxinc/lunch/utils/ManagementApi";
import {withContext, WithLocal, WithApi} from "@bryxinc/lunch/context";

interface LinkZoneModalProps extends WithLocal, WithApi<BryxApi> {
    viewStatus:
        | { key: "hidden" }
        | { key: "visible"; zoneId: string; scuId: string; agencyId: string };
    onDismiss: () => void;
}

interface LinkZoneModalState {
    code: string[];
    loading: boolean;
    error: string | null;
}

export class LinkZoneModal extends React.Component<LinkZoneModalProps,
    LinkZoneModalState> {
    constructor(props: LinkZoneModalProps, context: any) {
        super(props, context);
        this.state = {
            code: ["", "", "", ""],
            loading: false,
            error: null,
        };
    }

    componentWillReceiveProps(nextProps: LinkZoneModalProps) {
        if (
            this.props.viewStatus.key == "hidden" &&
            nextProps.viewStatus.key == "visible"
        ) {
            this.setState({code: ["", "", "", ""], error: null, loading: false});
        }
    }

    private onChange(index: number, char: string) {
        if (char == "") {
            return;
        }

        const {code} = this.state;

        code[index] = char.toUpperCase();

        this.setState({code: code});

        const nextIndex = index + 1;
        const next = document.getElementById(`code-${nextIndex}`);

        if (next !== null) {
            next.focus();
        }
    }

    private onBackspace(index: number) {
        const {code} = this.state;
        const current = code[index];

        if (current != "") {
            code[index] = "";
            this.setState({code: code});
            return;
        }

        const nextIndex = index - 1;
        const next = document.getElementById(`code-${nextIndex}`);

        code[nextIndex] = "";
        this.setState({code: code});

        if (next !== null) {
            next.focus();
        }
    }

    private move(index: number, left: boolean) {
        let nextIndex;
        if (left) {
            nextIndex = index - 1;
        } else {
            nextIndex = index + 1;
        }

        const next = document.getElementById(`code-${nextIndex}`);
        if (next !== null) {
            next.focus();
            (next as any).setSelectionRange(2, 2);
        }
    }

    private submit() {
        if (!this.canSubmit()) {
            return;
        }

        if (this.props.viewStatus.key == "hidden") {
            return;
        }

        const code = this.state.code.join("");
        const {agencyId, zoneId, scuId} = this.props.viewStatus;

        this.setState({loading: true});

        // convert to pairBoardToZone
        this.props.api.pairToZone(agencyId, scuId, zoneId, code, result => {
            if (result.success) {
                this.props.onDismiss();
            } else {
                this.setState({error: (result as any).message, loading: false});
            }
        });
    }

    private canSubmit(): boolean {
        const code = this.state.code.join("");

        return (
            code.length == 4 &&
            this.props.viewStatus.key == "visible" &&
            !this.state.loading
        );
    }

    render() {
        const {viewStatus, onDismiss} = this.props;
        const {code, error} = this.state;

        // const focusIndex = code.indexOf("");

        const fields = code.map((_, i) => {
            return (
                <Form.Field width={2} key={i}>
                    <input
                        type="text"
                        id={`code-${i}`}
                        style={{textAlign: "center"}}
                        value={code[i]}
                        maxLength={1}
                        autoFocus={i == 0}
                        onKeyDown={(e) => {
                            if (i !== 0 && e.keyCode == 8) {
                                this.onBackspace(i);
                            }

                            if (e.keyCode == 37 || e.keyCode == 39) {
                                this.move(i, e.keyCode == 37);
                            }

                            if (e.keyCode == 13) {
                                this.submit();
                            }
                        }}
                        onChange={(e) => {
                            this.onChange(i, e.target.value);
                        }}
                    />
                </Form.Field>
            );
        });

        return (
            <Modal
                open={viewStatus.key == "visible"}
                size="small"
                onClose={onDismiss}
            >
                <Modal.Header>Link a Station Board</Modal.Header>
                {error ? <Message negative content={error}/> : null}
                <Modal.Content>
                    <Form>
                        <Form.Group inline>{fields}</Form.Group>
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={onDismiss}>Cancel</Button>
                    <Button
                        primary
                        onClick={() => this.submit()}
                        disabled={!this.canSubmit()}
                        loading={this.state.loading}
                    >
                        Link
                    </Button>
                </Modal.Actions>
            </Modal>
        );
    }
}

export default withContext(LinkZoneModal, "api", "local");
