import * as React from "react";
import {Button, Table} from "semantic-ui-react";
import {PaginatedTable} from "../../components/paginatedTable";
import {ZeroStateView} from "../../components/zeroStateView";
import {
    ApiResult,
    PageResult,
    SortingConfig,
    MinimalStationBoard,
    StationBoardSortingOptions,
} from "@bryxinc/lunch/models";

import {CreateEditStationBoardModal} from "./createEditStationBoardModal";
import {StationBoardsProps} from "./stationBoardsPage";
import {ViewStationBoardSheet} from "./viewStationBoardSheet";
import {GeneralTabAction} from "../agency/generalTab";
import {withContext} from "@bryxinc/lunch/context";

export class StationBoardsTab extends React.Component<StationBoardsProps, {}> {
    private paginatedTable: PaginatedTable | null = null;

    private loadItems(
        limit: number,
        activePage: number,
        searchString: string | null,
        sortConfig: SortingConfig<StationBoardSortingOptions>,
        callback: (result: ApiResult<PageResult<MinimalStationBoard>>) => void,
    ) {
        this.props.api.getStationBoards(
            this.props.selectedAgency.id,
            limit,
            activePage,
            sortConfig,
            searchString,
            callback,
        );
    }

    private onClickAdd() {
        this.props.history.push("/station-boards/add");
    }

    render() {
        const boardId = this.props.match.params.boardId;
        return (
            <div className="underHorizNavContent" style={{display: "flex"}}>
                <PaginatedTable
                    {...this.props}
                    uniqueKey="stationBoards"
                    className="underHorizNavContent tableUnderNavContent"
                    sortable
                    selectable
                    rightItem={
                        <Button
                            color="green"
                            type="button"
                            onClick={this.onClickAdd.bind(this)}
                            content={this.props.t("general.create")}
                        />
                    }
                    ref={(r) => (this.paginatedTable = r)}
                    loader={{
                        loadId: this.props.selectedAgency.id,
                        loadItems: this.loadItems.bind(this),
                    }}
                    headerDataItems={[
                        {
                            i18nKey: "stationBoards.name",
                            headerKey: "name",
                            width: 14,
                        },
                        {
                            i18nKey: "stationBoards.sessions",
                            headerKey: "sessionCount",
                            width: 2,
                        },
                    ]}
                    zeroStateView={
                        <div className="flexCenteredContainer">
                            <ZeroStateView
                                header={this.props.t("stationBoards.zeroStateHeader")}
                                subheader={
                                    <div>
                                        <div style={{marginBottom: "10px"}}>
                                            {this.props.t("stationBoards.zeroStateSubheader")}
                                        </div>
                                        <Button
                                            color="green"
                                            type="button"
                                            fluid
                                            onClick={this.onClickAdd.bind(this)}
                                            content={this.props.t("general.create")}
                                        />
                                    </div>
                                }
                            />
                        </div>
                    }
                    renderItem={(item: any) => {
                        const board = item as MinimalStationBoard;

                        return (
                            <Table.Row
                                key={board.id}
                                style={{cursor: "pointer"}}
                                onClick={() =>
                                    this.props.history.push(`/station-boards/${board.id}`)
                                }
                            >
                                <Table.Cell>{board.name}</Table.Cell>
                                <Table.Cell>{board.sessionCount}</Table.Cell>
                            </Table.Row>
                        );
                    }}
                    defaultSorting={{
                        column: "name",
                        direction: "asc",
                    }}
                />
                <CreateEditStationBoardModal
                    {...this.props}
                    viewStatus={boardId == "add" ? {key: "create"} : {key: "hidden"}}
                    selectedAgency={this.props.selectedAgency}
                    onAddStation={() =>
                        this.props.history.push("/agency", {
                            action: GeneralTabAction.addStationForBoardCreation,
                        })
                    }
                    onClose={() => this.props.history.replace("/station-boards")}
                    onComplete={(newStation) => {
                        if (this.paginatedTable != null) {
                            this.paginatedTable.reload();
                        }
                        this.props.history.push(`/station-boards/${newStation.id}`);
                    }}
                />
                <ViewStationBoardSheet
                    {...this.props}
                    selectedAgency={this.props.selectedAgency}
                    viewStatus={
                        boardId != null && boardId != "add"
                            ? {
                                key: "shown",
                                stationBoardId: boardId,
                            }
                            : {key: "hidden"}
                    }
                    onUpdate={() => {
                        if (this.paginatedTable != null) {
                            this.paginatedTable.reload();
                        }
                    }}
                    onDismiss={(requiresReload) => {
                        this.props.history.replace("/station-boards");
                        if (requiresReload && this.paginatedTable != null) {
                            this.paginatedTable.reload();
                        }
                    }}
                />
            </div>
        );
    }
}

export default withContext(StationBoardsTab, "api", "local", "i18n");
