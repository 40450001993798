import * as React from 'react';
import {Button, Form, Message, Modal} from 'semantic-ui-react';
import BryxApi from '@bryxinc/lunch/utils/ManagementApi';
import {nullIfBlank, SupportType, SharedSupportUtils, SupportModalSkin} from '@bryxinc/lunch/utils/functions';


import {withContext, WithTranslation, WithLocal, WithApi} from '@bryxinc/lunch/context';
import {RouteComponentProps} from "react-router";


interface ContactSupportModalProps extends RouteComponentProps, WithTranslation, WithLocal, WithApi<BryxApi> {
}

interface ContactSupportModalState {
    open: boolean;
    skin: SupportModalSkin;
    status: { key: "ready" } | { key: "loading" } | { key: "error", description: string };
    from: string | null;
    subject: string | null;
    type: SupportType;
    image: File | null;
    body: string | null;
}

export class ContactSupportModal extends React.Component<ContactSupportModalProps, ContactSupportModalState> {
    private static readonly ticketTypes: SupportType[] = ["feedback", "question", "bug", "featureRequest"];

    constructor(props: ContactSupportModalProps, context: any) {
        super(props, context);

        this.state = this.getInitialState();
    }

    componentDidMount() {
        SharedSupportUtils.onOpenSupport = options => {
            this.setState({
                open: true,
                skin: options && options.skin != null ? options.skin : SupportModalSkin.support,
                from: options && options.from || this.localStorageEmail(),
                subject: options && options.subject || null,
                type: options && options.type || "feedback",
                image: options && options.image || null,
                body: options && options.body || null,
            });
        };
    }

    componentWillUnmount() {
        SharedSupportUtils.onOpenSupport = () => {
            return;
        };
    }

    private localStorageEmail(): string | null {
        return this.props.local.email;
    }

    private getInitialState(): ContactSupportModalState {
        return {
            open: false,
            skin: SupportModalSkin.support,
            status: {
                key: "ready",
            },
            from: this.localStorageEmail(),
            subject: null,
            type: "feedback",
            image: null,
            body: null,
        };
    }

    private onSubmitSupportTicket() {
        this.setState({
            status: {key: "loading"},
        }, () => {
            if (this.state.from == null || this.state.body == null) {
                this.setState({
                    status: {key: "error", description: this.props.t("general.genericError")},
                });
                return;
            }

            const subject = this.state.skin == SupportModalSkin.support ? this.state.subject : `Sales: ${this.state.subject}`;

            this.props.api.sendSupportTicket(
                this.state.from,
                subject,
                this.state.type,
                this.state.body,
                this.state.image,
                this.props.local,
                (result: any) => {
                    if (result.success == true) {
                        this.props.local.logInfo("User successfully sent support ticket");
                        this.setState({
                            status: {key: "ready"},
                            from: null,
                            body: null,
                        }, () => this.resetAndClose());
                    } else {
                        this.props.local.logWarn(`User failed to send support ticket: ${result.debugMessage}`);
                        this.setState({
                            status: {key: "error", description: result.message},
                        });
                    }
                },
            );
        });
    }

    private resetAndClose() {
        this.setState(this.getInitialState());
    }

    render() {
        const ticketTypes = ContactSupportModal.ticketTypes.map((option) => ({
            key: option,
            text: this.props.t(`contactSupport.supportTypes.${option}`),
            value: option,
        }));

        const errorMessage = this.state.status.key == "error" ? (
            <Message negative content={this.state.status.description}/>
        ) : null;

        return (
            <Modal open={this.state.open}
                   size="small"
                   onClose={this.resetAndClose.bind(this)}>
                <Modal.Header>
                    {this.state.skin == SupportModalSkin.support ? this.props.t("contactSupport.headerSupport") : this.props.t("contactSupport.headerSales")}
                </Modal.Header>
                <Modal.Content>
                    <Form>
                        <Form.Input label={this.props.t("contactSupport.fromLabel")}
                                    placeholder={this.props.t("contactSupport.fromPlaceholder")}
                                    autoComplete={false}
                                    autoCorrect={false}
                                    autoCapitalize={false}
                                    spellCheck={false}
                                    value={this.state.from || ""}
                                    onChange={(e, d) => this.setState({from: nullIfBlank(d.value)})}/>
                        <Form.Input label={this.props.t("contactSupport.subjectLabel")}
                                    placeholder={this.props.t("contactSupport.subjectPlaceholder")}
                                    value={this.state.subject || ""}
                                    onChange={(e, d) => this.setState({subject: nullIfBlank(d.value)})}/>
                        {this.state.skin == SupportModalSkin.support ? (
                            <Form.Select label={this.props.t("contactSupport.ticketTypeLabel")}
                                         options={ticketTypes}
                                         value={this.state.type}
                                         onChange={(e, d) => this.setState({type: d.value as SupportType})}/>
                        ) : null}
                        {this.state.skin == SupportModalSkin.support ? (
                            <Form.Input label={this.props.t("contactSupport.imageLabel")}
                                        type="file"
                                        control="input"
                                        accept=".png,.jpg"
                                        onChange={e => {
                                            const target = e.target as HTMLInputElement;
                                            this.setState({image: target.files != null ? target.files[0] : null});
                                        }}/>
                        ) : null}
                        <Form.TextArea label={this.props.t("contactSupport.bodyLabel")}
                                       value={this.state.body || ""}
                                       placeholder={this.props.t("contactSupport.bodyPlaceholder")}
                                       onChange={(e, d) => this.setState({body: nullIfBlank(d.value as string | null)})}/>
                    </Form>
                    {errorMessage}
                </Modal.Content>
                <Modal.Actions>
                    <Button content={this.props.t("general.cancel")}
                            onClick={this.resetAndClose.bind(this)}/>
                    <Button primary
                            loading={this.state.status.key == "loading"}
                            disabled={this.state.from == null || this.state.body == null || this.state.status.key == "loading"}
                            content={this.props.t("general.send")}
                            onClick={this.onSubmitSupportTicket.bind(this)}/>
                </Modal.Actions>
            </Modal>
        );
    }
}

export default withContext(ContactSupportModal, 'api', 'local', 'i18n');
