import * as React from "react";
import { SiteSurveyListProps } from "./shared";
import { FullSiteSurvey } from "@bryxinc/lunch/models";
import { List } from "semantic-ui-react";
import { useConstCallback } from "powerhooks";
import { useHistory } from "react-router";
import * as L from "leaflet";
import { Geometry } from "geojson";

export const SiteSurveyList = React.memo(
  ({ results, center }: SiteSurveyListProps) => {
    // Distance calculation is probably slow, but I don't think there's much we can do about
    // caching it for speed without a bunch of work
    const centerLeaflet = center && L.geoJSON(center).getBounds().getCenter();
    return (
      <div className="siteSurveyList">
        <List divided relaxed>
          {results.items
            .map((result: FullSiteSurvey) => ({
              result,
              distance: centerLeaflet
                ? L.geoJSON(result.location)
                  .getBounds()
                  .getCenter()
                  .distanceTo(centerLeaflet)
                : 0,
            }))
            .sort(
              (
                a: { result: FullSiteSurvey; distance: number },
                b: { result: FullSiteSurvey; distance: number }
              ) => a.distance - b.distance
            )
            .map(({ result }: { result: FullSiteSurvey; distance: number }) => (
              <SiteSurveyItem key={result.id} result={result} />
            ))}
        </List>
      </div>
    );
  }
);

const SiteSurveyItem = React.memo(({ result }: { result: FullSiteSurvey }) => {
  const history = useHistory();
  const onClick = useConstCallback(() => {
    history.push(`/site-surveys/edit/${result.id}`);
  });

  return (
    <List.Item onClick={onClick}>
      <List.Icon name="pin" size="large" verticalAlign="middle" />
      <List.Content>
        <List.Header as="a">
          {result.address?.full || stringifyGeometry(result.location)}
        </List.Header>
        <List.Description as="a">
          {result.critical?.text &&
            `${result.critical.key}: ${result.critical.text}`}
        </List.Description>
      </List.Content>
    </List.Item>
  );
});

function stringifyGeometry(location: Geometry): string {
  const center = L.geoJSON(location).getBounds().getCenter();
  return `${center.lat}, ${center.lng}`;
}
