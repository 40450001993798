import * as React from 'react';
import {Button, Modal} from "semantic-ui-react";

import BryxApi from '@bryxinc/lunch/utils/ManagementApi';
import {withContext, WithTranslation, WithLocal, WithApi} from '@bryxinc/lunch/context';
import {RouteComponentProps} from "react-router";

interface AgencyErrorProps extends RouteComponentProps, WithTranslation, WithLocal, WithApi<BryxApi> {
    invalidAgency: string | null;
    onClose: () => void;
    openContactSupportModal: () => void;
}

export class AgencyErrorModal extends React.Component<AgencyErrorProps, any> {
    render() {
        return (
            <Modal open={this.props.invalidAgency != null}
                   onClose={this.props.onClose}>
                <Modal.Header>{this.props.t("agencyErrorModal.invalidAgencyPermissions")}</Modal.Header>
                <Modal.Content>
                    {this.props.t("agencyErrorModal.invalidPermissions", {replace: {agencyName: this.props.invalidAgency}})}
                    <p>{this.props.t("agencyErrorModal.ifErrorPleaseContact")}</p>
                </Modal.Content>
                <Modal.Actions>
                    <Button primary
                            content={this.props.t("agencyErrorModal.contactSupport")}
                            onClick={this.props.openContactSupportModal}/>
                    <Button negative onClick={this.props.onClose} content={this.props.t("general.close")}/>
                </Modal.Actions>
            </Modal>
        );
    }
}

export default withContext(AgencyErrorModal, 'api', 'local', 'i18n');
