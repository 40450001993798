import * as React from "react";
import { config } from "../../index";
import { useRef, ChangeEvent } from "react";
import { SiteSurveyValueType } from "@bryxinc/lunch/models";
import {
  InputOnChangeData,
  Button,
  Icon,
  Form,
  Input,
  Segment,
  Dropdown,
  Divider,
} from "semantic-ui-react";
import { useConstCallback } from "powerhooks";
import { useTranslation, useApi } from "@bryxinc/lunch/context";
import { ManagementApi } from "@bryxinc/lunch/utils";

export type DataRowBase = {
  localId?: string;
  id: string | null;
  key: string;
  value: string;
  updated: boolean;
  deleted: boolean;
  type: SiteSurveyValueType;
};

type DataRowTextAtom = {
  type: SiteSurveyValueType.text;
};
export type DataRowText = DataRowBase & DataRowTextAtom;

type DataRowFileAtom = {
  fileId: string | File | null;
  type: SiteSurveyValueType.file;
};
export type DataRowFile = DataRowBase & DataRowFileAtom;

type DataRowLinkAtom = {
  url: string;
  type: SiteSurveyValueType.link;
};
export type DataRowLink = DataRowBase & DataRowLinkAtom;

type DataRowAtoms = {
  [SiteSurveyValueType.text]: DataRowTextAtom;
  [SiteSurveyValueType.file]: DataRowFileAtom;
  [SiteSurveyValueType.link]: DataRowLinkAtom;
};

export type DataRow = DataRowLink | DataRowFile | DataRowText;

let localId = 0;
export function getLocalId() {
  return ++localId;
}

export function DataRowView({
  agencyId,
  rows,
  onChange,
  defaultKey,
  maxRows,
  textPlaceholder,
}: {
  agencyId: string;
  rows: DataRow[];
  onChange: (rows: DataRow[]) => unknown;
  defaultKey?: string;
  maxRows: number;
  textPlaceholder: string;
}) {
  const { t } = useTranslation();
  const onChangeRow = useConstCallback(
    (newRow: DataRow, shouldDelete: boolean) => {
      const newSet = [];
      // If it's not in DB, no sense tagging it for deletion
      if (!shouldDelete || newRow.id) {
        newSet.push(newRow);
      }
      newRow.updated = true;
      for (let rowIndex = 0; rowIndex < rows.length; ++rowIndex) {
        const row = rows[rowIndex];
        if (
          (row.id && row.id == newRow.id) ||
          (row.localId && row.localId == newRow.localId)
        ) {
          onChange([
            ...rows.slice(0, rowIndex),
            ...newSet,
            ...rows.slice(rowIndex + 1),
          ]);
          break;
        }
      }
    }
  );
  const content = rows
    .filter((row) => !row.deleted)
    .map((row, index, rows) => (
      <>
        <Row
          type={row.type}
          key={row.localId || row.id}
          agencyId={agencyId}
          row={row}
          onChange={onChangeRow}
          hideKey={!!defaultKey}
          textPlaceholder={textPlaceholder}
        />
        {index != rows.length - 1 && (
          <Divider key={(row.localId || row.id) + "-div"} />
        )}
      </>
    ));

  const createTypes = Object.keys(RowTypes).map((rowType: DataRow["type"]) => (
    <Dropdown.Item
      key={rowType}
      text={t("siteSurvey.siteSurveyModal.rowTypes." + rowType)}
      onClick={useConstCallback((_event, _props) => {
        const blanks: {
          [K in SiteSurveyValueType]: DataRowAtoms[K];
        } = {
          [SiteSurveyValueType.text]: { type: SiteSurveyValueType.text },
          [SiteSurveyValueType.file]: {
            type: SiteSurveyValueType.file,
            fileId: null,
          },
          [SiteSurveyValueType.link]: {
            type: SiteSurveyValueType.link,
            url: "",
          },
        };
        onChange([
          ...rows,
          {
            id: null,
            localId: String(++localId),
            key: defaultKey || "",
            value: "",
            updated: true,
            deleted: false,
            ...blanks[rowType],
          },
        ]);
      })}
    />
  ));

  return (
    <>
      <Segment>
        {content.length ? (
          content
        ) : (
          <div style={{ textAlign: "center" }}>
            {t("siteSurvey.dataRows.noRows")}
          </div>
        )}
      </Segment>
      <Form.Group>
        <Form.Field>
          <Dropdown
            text={t("general.add")}
            disabled={content.length >= maxRows}
            button
          >
            <Dropdown.Menu>{createTypes}</Dropdown.Menu>
          </Dropdown>
        </Form.Field>
        {!defaultKey && (
          <Form.Field>
            <Dropdown
              text={t("siteSurvey.dataRows.addTemplate")}
              button
              scrolling
            >
              <Dropdown.Menu>
                <TemplateDropper rows={rows} onChange={onChange} />
              </Dropdown.Menu>
            </Dropdown>
          </Form.Field>
        )}
      </Form.Group>
    </>
  );
}

const templates: Omit<DataRowText, "localId">[] = [
  {
    id: null,
    key: "First Hose Run",
    value: "",
    type: SiteSurveyValueType.text,
    updated: true,
    deleted: false,
  },
  {
    id: null,
    key: "Second Hose Run",
    value: "",
    type: SiteSurveyValueType.text,
    updated: true,
    deleted: false,
  },
  {
    id: null,
    key: "First Hydrant Location",
    value: "",
    type: SiteSurveyValueType.text,
    updated: true,
    deleted: false,
  },
  {
    id: null,
    key: "Second Hydrant Location",
    value: "",
    type: SiteSurveyValueType.text,
    updated: true,
    deleted: false,
  },
  {
    id: null,
    key: "Hydrant Size",
    value: "",
    type: SiteSurveyValueType.text,
    updated: true,
    deleted: false,
  },
  {
    id: null,
    key: "Fire Jurisdiction",
    value: "",
    type: SiteSurveyValueType.text,
    updated: true,
    deleted: false,
  },
  {
    id: null,
    key: "EMS Jurisdiction",
    value: "",
    type: SiteSurveyValueType.text,
    updated: true,
    deleted: false,
  },
  {
    id: null,
    key: "Police Jurisdiction",
    value: "",
    type: SiteSurveyValueType.text,
    updated: true,
    deleted: false,
  },
  {
    id: null,
    key: "Owner's Last Name",
    value: "",
    type: SiteSurveyValueType.text,
    updated: true,
    deleted: false,
  },
  {
    id: null,
    key: "Owner's Phone",
    value: "",
    type: SiteSurveyValueType.text,
    updated: true,
    deleted: false,
  },
  {
    id: null,
    key: "Company",
    value: "",
    type: SiteSurveyValueType.text,
    updated: true,
    deleted: false,
  },
  {
    id: null,
    key: "Automatic Alarm Installed",
    value: "",
    type: SiteSurveyValueType.text,
    updated: true,
    deleted: false,
  },
  {
    id: null,
    key: "Permit Number",
    value: "",
    type: SiteSurveyValueType.text,
    updated: true,
    deleted: false,
  },
  {
    id: null,
    key: "Alarm Company",
    value: "",
    type: SiteSurveyValueType.text,
    updated: true,
    deleted: false,
  },
  {
    id: null,
    key: "Panel Location",
    value: "",
    type: SiteSurveyValueType.text,
    updated: true,
    deleted: false,
  },
  {
    id: null,
    key: "Contact #1",
    value: "",
    type: SiteSurveyValueType.text,
    updated: true,
    deleted: false,
  },
  {
    id: null,
    key: "Contact #1 Phone",
    value: "",
    type: SiteSurveyValueType.text,
    updated: true,
    deleted: false,
  },
  {
    id: null,
    key: "Contact #2",
    value: "",
    type: SiteSurveyValueType.text,
    updated: true,
    deleted: false,
  },
  {
    id: null,
    key: "Contact #2 Phone",
    value: "",
    type: SiteSurveyValueType.text,
    updated: true,
    deleted: false,
  },
  {
    id: null,
    key: "Construction Type",
    value: "",
    type: SiteSurveyValueType.text,
    updated: true,
    deleted: false,
  },
  {
    id: null,
    key: "Number of Stories",
    value: "",
    type: SiteSurveyValueType.text,
    updated: true,
    deleted: false,
  },
  {
    id: null,
    key: "Roof Construction",
    value: "",
    type: SiteSurveyValueType.text,
    updated: true,
    deleted: false,
  },
  {
    id: null,
    key: "Electricity Shutoff",
    value: "",
    type: SiteSurveyValueType.text,
    updated: true,
    deleted: false,
  },
  {
    id: null,
    key: "Gas Shutoff",
    value: "",
    type: SiteSurveyValueType.text,
    updated: true,
    deleted: false,
  },
  {
    id: null,
    key: "Water Shutoff",
    value: "",
    type: SiteSurveyValueType.text,
    updated: true,
    deleted: false,
  },
  {
    id: null,
    key: "Sprinkler System",
    value: "",
    type: SiteSurveyValueType.text,
    updated: true,
    deleted: false,
  },
  {
    id: null,
    key: "Sprinkler Connection Location",
    value: "",
    type: SiteSurveyValueType.text,
    updated: true,
    deleted: false,
  },
];

function TemplateDropper({
  onChange,
  rows,
}: {
  onChange: (rows: DataRow[]) => unknown;
  rows: DataRow[];
}) {
  return templates.map((template, index) => (
    <Dropdown.Item
      key={index}
      text={template.key}
      onClick={useConstCallback((_event, _props) => {
        const newRow = { ...template, localId: String(getLocalId()) };
        onChange([...rows, newRow]);
      })}
    />
  ));
}

type RowProps = {
  agencyId: string;
  type: DataRow["type"];
  row: DataRow;
  onChange: (row: DataRow, shouldDelete: boolean) => unknown;
  canDelete?: boolean;
  required?: boolean;
  hideKey: boolean;
  textPlaceholder: string;
};
export function Row({
  agencyId,
  type,
  row,
  onChange,
  canDelete = true,
  required = true,
  hideKey,
  textPlaceholder,
}: RowProps) {
  const { t } = useTranslation();
  const setKey = useConstCallback(
    (
      _event: ChangeEvent<HTMLInputElement>,
      { value: key }: InputOnChangeData
    ) => {
      onChange(
        {
          ...row,
          key,
        },
        false
      );
    }
  );
  const setValue = useConstCallback(
    (_event: ChangeEvent<HTMLInputElement>, { value }: InputOnChangeData) => {
      onChange(
        {
          ...row,
          value,
        },
        false
      );
    }
  );
  const onRemove = useConstCallback(() => {
    onChange({ ...row, deleted: true }, true);
  });
  const RowComponent = RowTypes[type];
  return (
    <Form.Group widths="equal">
      {!hideKey && (
        <Form.Input
          fluid
          required={required}
          id="site-survey-key"
          label={t("siteSurvey.siteSurveyModal.label")}
          value={row.key}
          onChange={setKey}
          placeholder={
            type == "text"
              ? t("siteSurvey.siteSurveyModal.doorCode")
              : t("siteSurvey.siteSurveyModal.floorPlan")
          }
        />
      )}
      <Form.Input
        fluid
        required={required}
        id="site-survey-value"
        label={
          hideKey
            ? type == "text"
              ? t("siteSurvey.siteSurveyModal.text")
              : t("siteSurvey.siteSurveyModal.label")
            : t("siteSurvey.siteSurveyModal.value")
        }
        value={row.value}
        onChange={setValue}
        placeholder={
          hideKey &&
          (type == "text"
            ? textPlaceholder
            : t("siteSurvey.siteSurveyModal.floorPlan"))
        }
      />
      <RowComponent
        agencyId={agencyId}
        row={row}
        onChange={onChange}
        required={required}
      />
      {canDelete && (
        <Form.Field width={1} className="siteSurveyRowRemoveField">
          <label>{"\u{a0}"}</label>
          <div style={{ display: "flex" }}>
            <Button
              id="site-survey-row-remove"
              icon
              onClick={onRemove}
              fluid
              color="red"
              style={{ alignSelf: "flex-end" }}
            >
              <Icon name="remove" />
            </Button>
          </div>
        </Form.Field>
      )}
    </Form.Group>
  );
}

type TypeRowProps = {
  agencyId: string;
  row: DataRow;
  onChange: (row: DataRow, shouldDelete: boolean) => unknown;
  required: boolean;
};
const RowTypes: Record<SiteSurveyValueType, React.FC<TypeRowProps>> = {
  [SiteSurveyValueType.text]: TextRow,
  [SiteSurveyValueType.link]: LinkRow,
  [SiteSurveyValueType.file]: FileRow,
};

function TextRow(
  _props: TypeRowProps & { row: TypeRowProps["row"] & DataRowText }
) {
  return null;
}

function LinkRow({
  onChange,
  row,
}: TypeRowProps & { row: TypeRowProps["row"] & DataRowLink }) {
  const setUrl = useConstCallback(
    (_event: ChangeEvent<HTMLInputElement>, { value: url }) => {
      onChange(
        {
          ...row,
          url,
        },
        false
      );
    }
  );
  const { t } = useTranslation();

  return (
    <Form.Input
      required
      label={t("siteSurvey.siteSurveyModal.rowTypes.link")}
      value={row.url}
      onChange={setUrl}
    />
  );
}

function FileRow({
  agencyId,
  onChange,
  row,
}: TypeRowProps & { row: TypeRowProps["row"] & DataRowFile }) {
  const { api } = useApi<ManagementApi>();
  const onUpload = useConstCallback(
    (event: ChangeEvent<HTMLInputElement>, _: InputOnChangeData) => {
      const target = event.target as HTMLInputElement;
      const file = target.files?.[0];
      if (file) {
        onChange(
          {
            ...row,
            fileId: file,
          },
          false
        );
        console.log(api.uploadAgencyFile);
        api.uploadAgencyFile(agencyId, file, (result) => {
          if (result.success) {
            onChange(
              {
                ...row,
                fileId: result.value,
              },
              false
            );
          } else {
            onChange(
              {
                ...row,
                fileId: null,
              },
              false
            );
          }
        });
      }
    }
  );

  const children = buildFileContent({ row, onUpload, api, onChange });
  const { t } = useTranslation();

  return (
    <Form.Field required style={{ position: "relative" }}>
      <label htmlFor="file">
        {t("siteSurvey.siteSurveyModal.rowTypes.file")}
      </label>
      {children}
    </Form.Field>
  );
}
function buildFileContent({
  row,
  api,
  onUpload,
  onChange,
}: {
  row: TypeRowProps["row"] & DataRowFile;
  api: ManagementApi;
  onUpload: (
    event: ChangeEvent<HTMLInputElement>,
    _: InputOnChangeData
  ) => unknown;
  onChange: TypeRowProps["onChange"];
}): JSX.Element {
  const uploadButton = useRef<HTMLInputElement>(null);
  const doUpload = useConstCallback(() => {
    uploadButton.current!.click();
  });
  const removeAttachment = useConstCallback(() => {
    onChange(
      {
        ...row,
        fileId: null,
      },
      false
    );
  });
  if (typeof row.fileId === "string") {
    // Saved
    return (
      <div id="file" style={{ display: "flex" }}>
        <div
          style={{
            flex: "1 1 auto",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <a href={api.buildAttachmentUrl(row.fileId)} target="_blank">
            {row.fileId}
          </a>
        </div>
        <Button
          circular
          icon
          style={{ marginTop: 2 }}
          onClick={removeAttachment}
        >
          <Icon name="remove" />
        </Button>
      </div>
    );
  } else {
    return (
      <>
        <Input
          id="file"
          // Yucky. Have to do this so the "This field is required" tooltip shows up
          style={{
            opacity: 0,
            position: "absolute",
            bottom: 0,
            right: 0,
            zIndex: -1,
          }}
          fluid
          type="file"
          loading={row.fileId instanceof File}
          onChange={row.fileId instanceof File ? undefined : onUpload}
          required
          name="file"
        >
          <input ref={uploadButton} />
        </Input>
        <Button fluid onClick={doUpload} style={{ marginTop: 5 }}>
          Upload
        </Button>
      </>
    );
  }
}
