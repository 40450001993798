import {InitOptions as Options} from 'i18next';
import XHR from 'i18next-xhr-backend';
import * as React from "react";
import {Redirect, RouteComponentProps, RedirectProps} from "react-router";
import {Button, Loader, Modal} from 'semantic-ui-react';
import {ZeroStateView} from "../components/zeroStateView";
import BryxApi from '@bryxinc/lunch/utils/ManagementApi';


import * as colors from '@bryxinc/style/color';

import {withContext, WithTranslation, WithLocal, WithApi} from '@bryxinc/lunch/context';

interface EulaPageState {
    status: { key: "ready", eula: string } |
        { key: "error", message: string } |
        { key: "loading" } |
        { key: "accepted" } |
        { key: "denied" } |
        { key: "sending" };
    i18nLoaded: boolean;
}

interface EulaPageProps extends RedirectProps, RouteComponentProps<{}, any, any>, WithTranslation, WithLocal, WithApi<BryxApi> {
}

export class EulaPage extends React.Component<EulaPageProps, EulaPageState> {
    constructor(props: EulaPageProps, context: any) {
        super(props, context);

        this.state = {
            status: {key: "loading"},
            i18nLoaded: false,
        };
    }

    componentDidMount() {
        if (this.props.local.showEula == true) {
            this.props.i18n
                .use(XHR)
                .init({
                    lng: this.props.local.get<string>("locale") || "en",
                    backend: {
                        loadPath: "/resources/locales/{{lng}}.json",
                    },
                    fallbackLng: "en",
                    interpolation: {escapeValue: false},
                } as Options, () => {
                    this.setState({
                        i18nLoaded: true,
                    });
                });
            this.props.api.getEula(result => {
                if (result.success == true) {
                    this.setState({status: {key: "ready", eula: result.value.eula}});
                } else {
                    this.props.local.logError(`Failed to load EULA: ${result.debugMessage}`);
                    this.setState({status: {key: "error", message: result.message}});
                }
            });
        }
    }

    private acceptEula() {
        this.setState({status: {key: "sending"}});

        this.props.api.acceptEula(result => {
            if (result.success == true) {
                this.setState({status: {key: "accepted"}});
            } else {
                this.props.local.logWarn(`User failed to accept EULA: ${result.debugMessage}`);
                this.setState({status: {key: "error", message: result.message}});
            }
        });
    }

    private denyEula() {
        this.setState({status: {key: "denied"}});
        this.props.local.clear();
    }

    render() {
        if (this.props.local.showEula != true) {
            return <Redirect to="/"/>;
        }

        let content = null;

        if (this.state.status.key == "loading" || this.state.i18nLoaded == false) {
            content = <Loader/>;
        } else if (this.state.status.key == "error") {
            content = (
                <div className="flexCenteredContainer" style={{width: "100%", height: "100%"}}>
                    <ZeroStateView header={this.props.t("eula.errorHeader")}
                                   subheader={this.props.t("eula.error")}
                                   maxWidth={"50%"}/>
                </div>
            );
        } else if (this.state.status.key == "ready") {
            content = this.state.status.eula;
        } else if (this.state.status.key == "accepted") {
            if (this.props.location.state && this.props.location.state.from) {
                return <Redirect to={this.props.location.state.from}/>;
            } else {
                return <Redirect to="/"/>;
            }
        } else if (this.state.status.key == "denied") {
            return <Redirect to="/login"/>;
        }

        return (
            <div style={{height: "100%", width: "100%", backgroundColor: colors.red}}>
                <Modal open dimmer={false} style={{display: "flex", flexDirection: "column"}}>
                    <Modal.Header>
                        {this.props.t("eula.header")}
                    </Modal.Header>
                    <Modal.Content style={{
                        flex: 1,
                        display: "flex",
                        flexDirection: "column",
                        whiteSpace: "pre-wrap",
                        height: "500px",
                        padding: 0
                    }}>
                        <div style={{flex: 1, overflowY: "auto", padding: "15px 20px"}}>
                            {content}
                        </div>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button positive
                                loading={this.state.status.key == "sending"}
                                onClick={() => this.acceptEula()}
                                content={this.props.t("eula.accept")}/>
                        <Button negative
                                onClick={() => this.denyEula()}
                                disabled={this.state.status.key == "sending"}
                                content={this.props.t("eula.deny")}/>
                    </Modal.Actions>
                </Modal>
            </div>
        );
    }
}

export default withContext(EulaPage, 'api', 'local', 'i18n');
